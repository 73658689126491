export const AppUrls = {
	LOGIN_PAGE: 'login',
	DASHBOARD: 'dashboard',
	LEAD_ROUTE: 'lead',
	ADMIN: 'admin',
	LEAD_DETAILS: 'detail',
	APPLICATION_PREVIEW: 'preview',
	SCORECARD: 'scoreCard',
	BUREAU: 'bureau',
	DOC_VALIDATION: 'doc-validation',
	GST_AUTOMATE: 'gst-module',
	FINANCIAL_MODULE: 'financial-module',
	UnderWritingDoc: 'underwritingDoc',
	Bureau_Report: 'bureau-report',
	Ledger: 'ledger-module',
	Enqiry_Bureau_Report: 'enquiry-bureau-report',
	Summary_Bureau_Report: 'summary-bureau-report',
	Accounts_Bureau_Report: 'accounts-bureau-report',
	Borrower_Bureau_Report: 'borrower-bureau-report',
	Transunion_Cibil_Report: 'transunion-cibil-report',
	Credit_Profile_Summary: 'credit-profile-summary',
	Credit_Facility_Details: 'credit-facility-details',
	Enquiry_Details: 'enquiry-details',
	ConsumerInfo: 'consumer-info',
	INVOICE_VERIFICATION: 'invoice-verification',
	LEAD_LISTING: 'lead-listing',
	BUYER_LISTING: 'buyer-listing',
	POST_SANCTION: 'post-sanction',
	BankStatement: 'bank-statement',
	SECURITY: 'security',
	CAM: 'cam',

};
