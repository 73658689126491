<h2 mat-dialog-title> {{data?.name}} Files</h2>
<mat-dialog-content>

    <div *ngFor="let file of fileData; let i = index">

        <div class="fileboxwrap d-flex al-center justify-between">
            <div class="left">
                <p (click)="downloadDocument(file.documentId)">{{ file.name }}</p>
            </div>

            <div class="right d-flex al-center">
                <button (click)="removeFile(file)" *ngIf="roleService.hasPermission(allPermissions.UD_DELETE_FILE_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN, allRoles.SUPER_ADMIN, allRoles.COMPANY_ADMIN_ACCESS])"><i class="ri-delete-bin-fill"></i> </button>
                <button class="edit" (click)="reUploadFile(file)" *ngIf="roleService.hasPermission(allPermissions.UD_REUPLOAD_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN, allRoles.SUPER_ADMIN, allRoles.COMPANY_ADMIN_ACCESS])"> Reupload</button>
            </div>
        </div>
    </div>

</mat-dialog-content>

<button mat-dialog-close class="cancel"><i class="ri-close-line"></i></button>