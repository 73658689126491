<section class="shieldFinanceMainWrap">
	<div class="shieldAsideNavBar"></div>
	<main class="losMainArea">
		<ul>
			<li class="active">Domestic LOS</li>
		</ul>
		<mat-tab-group
			class="outline-tab"
			animationDuration="0ms"
			mat-align-tabs="start"
			(selectedTabChange)="onTabChange($event)"
		>
			<mat-tab *ngFor="let tab of totalTabsList">
				<ng-template mat-tab-label>
					{{ tab.displayName }}
					<span class="noTag">{{ tab.count }}</span>
				</ng-template>

				<div
					class="d-flex justify-content-between align-items-center p-10"
				>
					<div
						class="srchBoxContent justify-content-between align-items-center"
						style="flex: 1"
					>
						<i class="ri-search-line"></i>
						<input
							type="text"
							placeholder="Search"
							[(ngModel)]="searchTerm"
							(input)="onSearch()"
							(blur)="blurSearchList()"
							(keydown.enter)="addSelectedItem(searchTerm)"
						/>
					</div>

					<div class="d-flex">
						<button
							type="button"
							class="btn-outline-primary save-cta reset-cta"
							(click)="resetFilter()"
						>
							Reset
							<mat-icon class="resetIcn">autorenew</mat-icon>
						</button>
					</div>
				</div>

				<!-- Display the search results -->
				<ul *ngIf="filteredData.length > 0" class="search-results">
					<li
						*ngFor="let item of filteredData"
						(click)="onSelectItem(item)"
					>
						Domestic- {{ item?.displaySequence }} |
						{{ item?.supplierName }}
					</li>
				</ul>
				<ng-container style="display: none">
					<button
						id="chnagedetection"
						style="display: none; width: 0px"
					></button>
				</ng-container>

				<div class="mat-table-overflow custOrderHistoryListingWrap">
					<table
						mat-table
						class="mat-table-theme horizontal-overflow-width"
						[dataSource]="dataSource"
					>
						<ng-container matColumnDef="leadDetails">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20"> Lead Details </span>
							</th>
							<td mat-cell *matCellDef="let element">
								<div class="flex flex-row">
									<div>
										<span
											(click)="
												routeToDetailsPreviewPage(
													element.id
												)
											"
											matTooltip="Edit Lead"
										>
											<p class="blueTxt link">
												Domestic-{{
													element.displaySequence
												}}
											</p>
										</span>
										<p>
											Created :{{
												element.createdAt
													| date : 'dd-MMM-yyyy'
											}}
										</p>
										<p>
											Modified :
											{{
												element.updatedAt
													| date : 'dd-MMM-yyyy'
											}}
										</p>
									</div>
								</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="companyDetails">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20"> Company Details </span>
							</th>
							<td mat-cell *matCellDef="let element">
								<p class="boldTxt">
									{{ element?.supplierName }}
								</p>
								<p>{{ element.supplierEmail }}</p>
								<p>{{ element?.supplierPhoneNumber }}</p>
							</td>
						</ng-container>

						<ng-container matColumnDef="companyProfile">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Company Profile
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuCP"
									></i>
									<mat-menu
										#matMenuCP="matMenu"
										class="matMenuCPWrap"
										(closed)="onMenuClose()"
									>
										<span class="headTxt"
											>Select Limit Range</span
										>
										<ol>
											<li
												*ngFor="
													let rangeObj of limitRange
												"
											>
												<button
													mat-menu-item
													(click)="
														selectLimitRange(
															rangeObj
														)
													"
												>
													{{ rangeObj.label }}
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<div [matMenuTriggerFor]="matMenuCP">
									<p
										*ngIf="
											['PID', 'VENDOR_FINANCE'].includes(
												element.productType
											)
										"
										class="noOfSuppliers"
									>
										No. of Suppliers
										<span class="boldTxt"
											>: {{ element.noOfSuppliers }}</span
										>
									</p>
									<p
										*ngIf="
											[
												'SID',
												'DEALER_FINANCE',
												'PO'
											].includes(element.productType)
										"
										class="noOfBuyers"
									>
										No. of Buyers
										<span class="boldTxt"
											>: {{ element.noOfBuyers }}</span
										>
									</p>
								</div>
								<mat-menu
									#matMenuCP="matMenu"
									class="matMenuCPWrap"
								>
									<span class="headTxt">Buyers</span>
									<ol>
										<li>ABFL Pvt Ltd</li>
										<li>Samrat Enterprises Pvt Ltd</li>
										<li>Mogli Lab India Pvt Ltd</li>
										<li>Credlix Pvt Ltd</li>
									</ol>
								</mat-menu>
								<p>
									Limit Applied For
									<span class="boldTxt"
										>:
										{{
											element.totalFundingRequirement
										}}</span
									>
								</p>
							</td>
						</ng-container>

						<ng-container matColumnDef="productType">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Product Type
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuCP"
									></i>
									<mat-menu
										#matMenuCP="matMenu"
										class="matMenuCPWrap"
										(closed)="onMenuClose()"
									>
										<span class="headTxt"
											>Product type</span
										>
										<ol>
											<li
												*ngFor="
													let status of productTypeList
												"
											>
												<button
													mat-menu-item
													(click)="
														selectProductType(
															status.id
														)
													"
												>
													{{ status.name }} ({{
														status.count
													}})
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<p class="boldTxt">
									{{
										getProductTypeName(element.productType)
									}}
								</p>
							</td>
						</ng-container>

<!-- 						<ng-container matColumnDef="approvedAmount">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Approved Amount
									<i
										*ngIf="!isApprovedAmountFilter"
										class="ri-arrow-up-down-line unFilteredIcon"
										(click)="applyApprovedAmountFilter()"
									></i>
									<i
										*ngIf="isApprovedAmountFilter"
										class="ri-arrow-up-down-line filteredIcon"
										(click)="applyApprovedAmountFilter()"
									></i>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<p class="boldTxt">{{ element.productType }}</p>
							</td>
						</ng-container> -->


						<ng-container matColumnDef="leadStatus">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Lead Status
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuCP"
									></i>
									<mat-menu
										#matMenuCP="matMenu"
										class="matMenuCPWrap"
										(closed)="onMenuClose()"
									>
										<span class="headTxt">Status</span>
										<ol>
											<li
												*ngFor="
													let status of leadStatusList
												"
											>
												<button
													mat-menu-item
													(click)="
														selectleadstatus(
															status.id
														)
													"
												>
													{{ status.name }}
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<span
									[ngClass]="
										shieldApi.getStatusClass(element.status)
									"
									>{{ element.status }}</span
								>
							</td>
						</ng-container>

            <ng-container matColumnDef="approvedAmount">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Approved Amount
                  <i *ngIf="!isApprovedAmountFilter" class="ri-arrow-up-down-line unFilteredIcon"
                    (click)="applyApprovedAmountFilter()"></i>
                  <i *ngIf="isApprovedAmountFilter" class="ri-arrow-up-down-line filteredIcon"
                    (click)="applyApprovedAmountFilter()"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{element.totalFundingRequirement ? (element.totalFundingRequirement + " Lac") : '-'}}</p>
              </td>
            </ng-container>


						<ng-container matColumnDef="deviated">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Deviated
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuDV"
									></i>
									<mat-menu
										#matMenuDV="matMenu"
										class="matMenuCPWrap"
										(closed)="onMenuClose()"
									>
										<span class="headTxt"
											>Deviated Status</span
										>
										<ol>
											<li
												*ngFor="
													let item of deviatedList
												"
											>
												<button
													mat-menu-item
													(click)="
														selectDeviation(
															item.status
														)
													"
												>
													{{ item.name }}
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.isDeviated ? 'Deviated' : 'Normal' }}
							</td>
						</ng-container>

						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Status
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuCP"
									></i>
									<mat-menu
										#matMenuCP="matMenu"
										class="matMenuCPWrap"
									>
										<span class="headTxt">Sub Status</span>
										<ol>
											<li
												*ngFor="
													let data of subStatusList
												"
											>
												<button
													mat-menu-item
													(click)="
														selectSubStatus(data.id)
													"
												>
													{{ data.name }}
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<p class="boldTxt">
									{{ getStatusTypeName(element.subStatus) }}
								</p>
								<!-- <p class="boldTxt">{{element.subStatus }}</p> -->
							</td>
						</ng-container>

						<ng-container matColumnDef="remark">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20"> Remark</span>
							</th>
							<td mat-cell *matCellDef="let element" class="remarkField">
								<p class="boldTxt" title="{{ getRemarks(element.remarks) }}">
									{{ getRemarks(element.remarks) }}
								</p>
							</td>
						</ng-container>

						<ng-container matColumnDef="assigneeDetails">
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20">
									Assignee Details
									<i
										class="ri-arrow-down-s-line"
										[matMenuTriggerFor]="matMenuCP"
									></i>
									<mat-menu
										#matMenuCP="matMenu"
										class="matMenuCPWrap"
									>
										<span class="headTxt"
											>Assignee Name</span
										>
										<ol>
											<li
												*ngFor="
													let activeuser of allActiveUserList
												"
											>
												<button
													mat-menu-item
													(click)="
														selectAssigneeName(
															activeuser.id
														)
													"
												>
													{{ activeuser.name }}
												</button>
											</li>
										</ol>
									</mat-menu>
								</span>
							</th>
							<td mat-cell *matCellDef="let element">
								<p>{{ element?.assignee?.name }}</p>

								<span
									*ngIf="element?.relationshipManager"
									class="rmTxt"
									>RM:
									{{ element?.relationshipManager }}</span
								>
								<p>
									Assigned On
									<span class="boldTxt"
										>:
										{{
											element?.assignedOn
												| date : 'dd-MMM-yyyy'
										}}</span
									>
								</p>
							</td>
						</ng-container>

						<ng-container matColumnDef="action1" stickyEnd>
							<th mat-header-cell *matHeaderCellDef>
								<ng-container class="actionTh">
									<span class="pr-20"> Action </span>
									<!-- <mat-icon class="resetIcn" (click)="resetFilter()">autorenew</mat-icon> -->
								</ng-container>
							</th>
							<td mat-cell *matCellDef="let element">
								<p class="actionIcon">
									<i
										class="blueTxt mr-10 ri-file-edit-line link"
										(click)="
											routeToDetailsPreviewPage(
												element.id
											)
										"
										matTooltip="Edit Lead"
									></i>
									<i
										class="blueTxt ri-user-add-line"
										(click)="changeAssignee(element)"
										*ngIf="
											roleService.hasPermission(
												allPermissions.CHANGE_ASSIGNEE
											) ||
											roleService.hasAnyRole([
												allRoles.CREDIT_TEAM_ADMIN,
												allRoles.SUPER_ADMIN
											])
										"
										matTooltip="Change Assignee"
									></i>
									<!-- <button
										type="button"
										class="btn-outline-primary save-cta ml-15 point-cursor"
										(click)="openAudit(element.id)"
									>
										Audit trail
									</button> -->

									<button type="button" class="btn-outline-primary trackChange save-cta ml-15 point-cursor" (click)="openAudit(element.id)">
										<mat-icon class="blueTxt" matTooltip="Audit Trail">track_changes</mat-icon> 
									  </button>
								</p>
							</td>
						</ng-container>

						<ng-container matColumnDef="action2" stickyEnd>
							<th mat-header-cell *matHeaderCellDef>
								<span class="pr-20"> Action </span>
							</th>
							<td mat-cell *matCellDef="let element">
								<button
									mat-button
									class="sendToLmsBtn"
									(click)="sendToLms(element.id)"
								>
									Send To LMS <mat-icon>skip_next</mat-icon>
								</button>
							</td>
						</ng-container>

						<tr
							mat-header-row
							*matHeaderRowDef="displayColumns; sticky: true"
						></tr>
						<tr
							mat-row
							*matRowDef="let row; columns: displayColumns"
						></tr>
					</table>
					<p
						*ngIf="
							!dataSource ||
							!dataSource.data ||
							dataSource.data.length === 0
						"
						class="no-record-found"
					>
						No record found
					</p>
					<mat-paginator
						[length]="totalItems"
						[pageSize]="pageSize"
						[pageSizeOptions]="[10, 20, 30]"
						(page)="pageEvent($event)"
					>
					</mat-paginator>
				</div>
			</mat-tab>
		</mat-tab-group>
	</main>
</section>
