<section class="finalCamWraper">
    <section class="finalCamBlock">
        <h1>
            <span>Basic</span>
            <button class="saveCta mr-15" (click)="onBasicSave('Save')"><i class="ri-save-2-fill"></i>Save</button>
            <button class="saveCta mr-15" (click)="onBasicSave('Submit')" [disabled]="hasValidationErrors()">
                <i class="ri-save-2-fill mr-5"></i>Submit form
            </button>
        </h1>
        <form>
            <div class="inPHFControlWrap">
                <div class="w32" *ngFor="let field of basicFormFields; let i = index">
                    <div class="input-group">
                        <mat-label>{{ field.label }}</mat-label>
                        <mat-form-field class="mat-input" appearance="outline">
                            <ng-container [ngSwitch]="field.type">
                                <input *ngSwitchCase="'input'" matInput [(ngModel)]="field.value"
                                    [placeholder]="field.placeholder" [disabled]="!isEditable"
                                    (input)="validateField(i)" name="field-{{ i }}" />
                                <mat-select *ngSwitchCase="'select'" [(ngModel)]="field.value" [disabled]="!isEditable"
                                    name="field-{{ i }}">
                                    <mat-option *ngFor="let option of field.options" [value]="option">{{ option
                                        }}</mat-option>
                                </mat-select>
                            </ng-container>
                        </mat-form-field>
                        <div *ngIf="errorMessages[i]" class="error-message">{{ errorMessages[i] }}</div>
                    </div>
                </div>
            </div>
        </form>
    </section>

    <section class="deviationListWrap">
        <h2>
            <span>Deviation</span>
            <button class="addMoreFieldsCta" (click)="addDeviationField()">
                <i class="ri-file-add-line"></i>Add More Field
            </button>
        </h2>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Deviation</th>
                    <th>Mitigant</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of deviationsList; let i = index">
                    <td><input type="text" placeholder="Enter Deviation" [(ngModel)]="item.deviation" /></td>
                    <td><input type="text" placeholder="Enter Mitigant" [(ngModel)]="item.mitigant" /></td>
                    <td>
                        <button class="removetableRowCta" (click)="removeDeviationField(i)">
                            <i class="ri-delete-bin-line mr-5"></i>Remove
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>


    <section class="finalCamBlock">
        <h1>Standard Conditions</h1>
        <ul class="bulletCamList">
            <li>• 5 UDC from the primary bank.</li>
            <li>• All partners should be part of the loan structure.</li>
            <li>• PG from the directors & shareholders.</li>
            <li>• Funding freeze >15 DPD.</li>
            <li>• DPN along with letter of continuity duly signed and stamped by authorized personnel.
            </li>
            <li>• Invoice to be funded post GRN confirmation from the borrower.</li>
            <li>• Funding to be done on vendor e- invoices only.</li>
            <li>• Any change in the working capital bank should be intimated to us.</li>
            <li>• Undertaking from the company that there is no director relationship with these
                vendors.</li>
            <li>• Vendor invoices should clearly mention the due dates.</li>
            <li>• Intimation to us on further increase in borrowings</li>
            <li>• E-invoice and E-way bill to be part of disbursement document set at the time of
                funding/disbursement request.</li>
            <li>• CA attested latest shareholding pattern</li>
        </ul>
    </section>
</section>