<section class="finalCamWraper">

    <section class="finalCamBlock">
        <h1>
            <span>Summary of Borrower</span>
            <button class="saveCta mr-15" (click)="onBorrowerSave()" [disabled]="hasValidationErrors()">
                <i class="ri-save-2-fill mr-5"></i>Save
            </button>
            <button class="saveCta mr-15" (click)="onBorrowerSave()" [disabled]="hasValidationErrors()">
                <i class="ri-save-2-fill mr-5"></i>Submit Summary of Borrower
            </button>
        </h1>
        <section class="borrowerTxtAreaWrap">
            <span class="lbl">Summary of Borrower</span>
            <textarea [(ngModel)]="SummaryOfBorrower"></textarea>
        </section>

        <div class="inPHFControlWrap mt-20">
            <div class="w32">
                <div class="input-group">
                    <mat-label>Person spoken to /Designation</mat-label>
                    <mat-form-field class="mat-input" appearance="outline">
                        <input matInput [(ngModel)]="personSpokenTo"
                            placeholder="Enter Person spoken to /Designation" />
                    </mat-form-field>
                </div>
            </div>
            <div class="w32">
                <div class="input-group">
                    <mat-label>Mode of Discussion</mat-label>
                    <mat-form-field class="mat-input" appearance="outline">
                        <input matInput placeholder="Enter Mode of Discussion" [(ngModel)]="modeOfDiscussion" />
                    </mat-form-field>
                </div>
            </div>
            <div class="w32">
                <div class="input-group">
                    <mat-label>Link</mat-label>
                    <mat-form-field class="mat-input" appearance="outline">
                        <input matInput placeholder="Enter Link" [(ngModel)]="link" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </section>
    <section class="finalCamBlock">
        <section class="borrowerTxtAreaWrap">
            <span class="lbl">Key Risk & Mitigant</span>
            <textarea [(ngModel)]="keyRiskAndMitigant"></textarea>
        </section>
    </section>

    <section class="deviationListWrap">
        <h2>
            <span>Key Customers, suppliers (FY24) till Aug‘24</span>
            <p>Top 5 Customers (Rs lacs)</p>
            <button class="addMoreFieldsCta" (click)="addDeviationField('KeyCustomers')">
                <i class="ri-file-add-line"></i>
                Add More Field
            </button>
        </h2>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Serial No.</th>
                    <th>Customer Name</th>
                    <th>Amount (Rs in lacs)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of keyCustomersSuppliers; let i = index">
                    <td>
                        <input type="text" placeholder="Serial No." [(ngModel)]="item.serialNo" />
                    </td>
                    <td>
                        <input type="text" placeholder="Customer Name" [(ngModel)]="item.customerName" />
                    </td>
                    <td>
                        <input type="text" placeholder="Amount (Rs in lacs)" [(ngModel)]="item.amount" />
                    </td>
                    <td>
                        <button (click)="removeDeviationField('KeyCustomers', i)">
                            <i class="ri-delete-bin-line"></i>
                            Remove
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <section class="borrowerTxtAreaWrap">
            <span class="lbl">Promoter’s Background:</span>
            <textarea [(ngModel)]="PromotersBackground"></textarea>
        </section>
    </section>

    <section class="deviationListWrap">
        <h2>
            <span>Particulars Details</span>
            <button class="addMoreFieldsCta" (click)="addDeviationField('ParticularsDetails')">
                <i class="ri-file-add-line"></i>
                Add More Field
            </button>
        </h2>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Particulars</th>
                    <th>Share%</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of particularsDetails; let i = index">
                    <td>
                        <input type="text" placeholder="Enter Particulars" [(ngModel)]="item.particulars" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Share" [(ngModel)]="item.share" />
                    </td>
                    <td>
                        <button (click)="removeDeviationField('KeyCustomers', i)">
                            <i class="ri-delete-bin-line"></i>
                            Remove
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <section class="borrowerTxtAreaWrap">
            <span class="lbl">Key Related Entities & their CIBIL record (if any)</span>
            <textarea [(ngModel)]="keyRelatedEntities"></textarea>
        </section>
    </section>
</section>