<h2 mat-dialog-title> {{fileData?.length}} Uploaded File</h2>
<mat-dialog-content>
  
    <div *ngFor="let file of fileData; let i = index">

      <div class="fileboxwrap d-flex al-center justify-between">
        <div class="left" (click)="openFileInNewTab(file.id)">
          <p>{{ file.name }}</p>
        </div>

        <div class="right d-flex al-center" *ngIf="(roleService.hasPermission(allPermissions.AD_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS]))">
          <button (click)="downloadFile(file.id)"><i class="ri-download-2-fill"></i> </button>
          <button (click)="removeFile(file.id)"><i class="ri-delete-bin-fill"></i> </button>
          <button (click)="changeFile(file.id)" class="edit"> Reupload</button>
        </div>
      </div>
    </div>
  
</mat-dialog-content>

<button mat-dialog-close class="cancel"><i class="ri-close-line"></i></button>

