import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-site-visit',
  templateUrl: './site-visit.component.html',
  styleUrls: ['./site-visit.component.scss'],
})
export class SiteVisitComponent implements OnInit {

  briefAboutFactory: string = '';
  productionProcess: Array<{ steps: string; productionProcessFollowed: string }> = [];

  siteVisitReportList = [
    { title: 'Date of visit', comments: '' },
    { title: 'Name of person who visited', comments: '' },
    { title: 'All Address (Factory/Office) that were visited', comments: '' },
    { title: 'Supplier name', comments: '' },
    { title: 'Total no. of factories / Warehouse', comments: '' },
    { title: 'Factory location address (for all)', comments: '' },
    { title: 'Ownership of factory (owned/ rented/ leased)', comments: '' },
    { title: 'Person whom you met (designation/ contact no/email id)', comments: '' },
    { title: 'Total manpower (on- role)', comments: '' }
  ];

  tableData = [
    { particular: 'Property Address captured', value: 'Yes' },
    { particular: 'Name of the company (Borrower/Supplier name) captured', value: 'Yes' },
    { particular: 'Is Credlix Official captured', value: 'Yes' },
    { particular: 'If Hypothecated then photo of hypothecation board taken', value: 'No' },
  ];


  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  ngOnInit(): void { }

  saveValues() {
    const selectedValues = this.tableData.map(row => row.value);
    console.log('Saved Values:', selectedValues);
    alert('Saved Values: ' + selectedValues.join(', '));
  }

  // Method to add a new row
  addDeviationField(value: any): void {
    if (value === 'ProductionProcess') {
      this.productionProcess.push({ steps: '', productionProcessFollowed: '' });
    }
  }

  // Method to remove a specific row
  removeDeviationField(type: string, index: number): void {
    if (type === 'ProductionProcess') {
      this.productionProcess.splice(index, 1);
    }
  }
}