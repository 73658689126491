import { Component, OnInit } from '@angular/core';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-security-convenent',
  templateUrl: './security-convenent.component.html',
  styleUrls: ['./security-convenent.component.scss'],
})
export class SecurityConvenentComponent implements OnInit {
  businessUserDetails: { name: string; isSelected: boolean; id: string }[] = [];
  conditionsList: { text: string; value: boolean }[] = [];
  laneTaskId: string;
  directiveIds: string[] = []; // Store directive IDs

  constructor(
    private shieldService: ShieldApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.laneTaskId = params['id'];
      this.fetchConditions();

    });
  }

  fetchConditions(): void {
    this.shieldService.fetchConditionsData(this.laneTaskId).subscribe({
      next: (response: any) => {
        const apiResponse = response?.data?.conditions;
        console.log("apiResponse: ", response?.data)
        if (apiResponse) {
          this.conditionsList = Object.entries(apiResponse).map(([text, value]) => ({
            text,
            value: Boolean(value), // Ensure it's a boolean
          }));
        }
        // Save directive IDs from the response
        this.directiveIds = response?.data?.directors || [];
        console.log("this.directiveIds ", this.directiveIds)
        this.fetchBusinessUserDetails();
      },
      error: (error) => {
        console.error('Error fetching conditions:', error);
      },
    });
  }

  fetchBusinessUserDetails(): void {
    this.shieldService.laneData.subscribe((data) => {
      if (!data?.applicationPreview?.detailsAndDocumentation?.businessUserDetails) {
        return;
      }
      console.log("id: ", data.applicationPreview.detailsAndDocumentation.businessUserDetails)
      console.log("this.directiveIds ", this.directiveIds)
      this.businessUserDetails = data.applicationPreview.detailsAndDocumentation.businessUserDetails.map(
        (user: any) => ({
          name: user?.name || 'Unknown',
          isSelected: this.directiveIds.includes(user?.id),
          id: user?.id,
        })
      );
      console.log("this.businessUserDetails: ", this.businessUserDetails)
    });
  }

  canSubmit(): boolean {
    // Check if at least one condition is checked
    const hasCheckedCondition = this.conditionsList.some((condition) => condition.value);

    // Check if at least one directive is selected
    const hasSelectedDirective = this.businessUserDetails.some((user) => user.isSelected);

    return hasCheckedCondition && hasSelectedDirective;
  }

  submitValues(): void {
    if (!this.canSubmit()) {
      console.error('Submit button should be disabled if validation fails.');
      return;
    }

    // Convert conditionsList into a map
    const formattedConditions = this.conditionsList.reduce(
      (acc, condition) => ({ ...acc, [condition.text]: condition.value }),
      {}
    );

    // Get selected users' IDs
    const selectedUserIds = this.businessUserDetails
      .filter((user) => user.isSelected)
      .map((user) => user.id);

    // Create payload
    const payload = {
      conditions: formattedConditions,
      directors: selectedUserIds,
      laneTaskId: this.laneTaskId,
    };

    console.log('Payload:', payload);

    // Submit payload to backend
    this.shieldService.SubmitConditionsData(payload).subscribe({
      next: (response) => {
        console.log('Submission successful:', response);
      },
      error: (error) => {
        console.error('Error submitting data:', error);
      },
    });
  }
}