import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { CsvExportService } from 'src/app/service/csv-export.service';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { SharedModule } from 'src/app/module/shared/shared.module';
import { ConfirmationDialogComponent } from '../../under-writting-doc/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleService } from 'src/app/service/role.service';
import { Permission } from 'src/app/enum/permission.enum';
import Roles from 'src/app/enum/roles.enum';

export interface Task {
	name: string;
	completed: boolean;
	color: ThemePalette;
	subtasks?: Task[];
}

@Component({
	selector: 'app-new-lead-details',
	templateUrl: './new-lead-details.component.html',
	styleUrls: ['./new-lead-details.component.scss'],
})
export class NewLeadDetailsComponent implements OnInit {
	@ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	gstCtrl = new FormControl();
	filteredGst: Observable<string[]>;
	gstSelected: string;
	gstList: string[] = [];
	leadSourceFlag: boolean = false;

	@ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;

	file: any;
	isProgressVisible = false;
	panelOpenState = true;
	states = this.shieldService.statesOfIndia;
	showSave: boolean = true;
	laneData: any;
	laneTaskId: any;
	name = 'Buyer';
	dataSource1: MatTableDataSource<any> = new MatTableDataSource([]);
	dataSource2: MatTableDataSource<any> = new MatTableDataSource([]);
	displayColumns1: Array<string> = [
		'BuyerName',
		'BuyerAddress',
		'BuyerRating',
		'RelationshipTurnover',
		'BuyerTurnOver',
		'LimitRequested',
		'TendorDays',
		'valueOfPo',
		'Action',
	];
	displayColumns2: Array<string> = [
		'SupplierName',
		'SupplierAddress',
		'SupplierRating',
		'SupplierRelationshipTurnover',
		'SupplierTurnOver',
		'LimitRequested',
		'TendorDays',
		'valueOfPo',
		'Action',
	];
	piCheck: boolean = false;
	sidCheck: boolean = false;
	newLeadForm: FormGroup;
	newLeadData: any = {};
	showMandatory: boolean = false;
	allPermissions: any = Permission;
	allRoles: any = Roles;
	constructor(
		private fb: FormBuilder,
		public toasterService: ToastrService,
		public csvService: CsvExportService,
		private activatedRoute: ActivatedRoute,
		public shieldService: ShieldApiService,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		public roleService: RoleService,
	) {
		this.getCall();
	}
	ngOnInit() {
		this.initialiseForm();
		this.newLeadForm
			.get('businessProduct')
			.valueChanges.subscribe((x) => this.businessChanged());
		this.newLeadForm.get('leadSource').valueChanges.subscribe((x) => {
			if (this.newLeadForm.get('leadSource').value)
				this.leadSourceFlag = true;
			else this.leadSourceFlag = false;
		});
		this.activatedRoute.params.subscribe((x: any) => {
			this.laneTaskId = x.id;
			this.shieldService.setLaneTaskId(x.id);
			this.fetchdata(x.id);
		});
	}

	task: Task = {
		name: 'Checl All',
		completed: false,
		color: 'primary',
		subtasks: [
			{ name: 'Check 1', completed: false, color: 'primary' },
			{ name: 'Check 2', completed: false, color: 'primary' },
			{ name: 'Check 3', completed: false, color: 'primary' },
		],
	};

	allComplete: boolean = false;

	someComplete(): boolean {
		return (
			this.task.subtasks.filter((t) => t.completed).length > 0 &&
			!this.allComplete
		);
	}

	setAll(completed: boolean) {
		this.allComplete = completed;
		this.task.subtasks.forEach((t) => (t.completed = completed));
	}

	updateAllComplete() {
		this.allComplete =
			this.task.subtasks != null &&
			this.task.subtasks.every((t) => t.completed);
	}

	cancel() {
		this.menuTrigger.closeMenu();
	}

	rmeoveSelectedFile() {
		this.file = null;
	}

	fetchdata(x: any) {
		if (x !== null && x !== 'undefined') {
			this.shieldService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.shieldService.setLaneData(resp.data);
					// this.editable(resp)
					this.newLeadData = resp.data.applicationPreview.leadDetails;
					this.newLeadForm
						.get('supplierName')
						.setValue(
							this.newLeadData && this.newLeadData.supplierName,
						);
					this.newLeadForm
						.get('supplierTurnover')
						.setValue(
							this.newLeadData &&
								this.newLeadData.supplierTurnover
								? this.newLeadData.supplierTurnover
								: '',
						);
					this.newLeadForm
						.get('mobileNo')
						.setValue(
							this.newLeadData && this.newLeadData.mobileNo
								? this.newLeadData.mobileNo
								: '',
						);
					this.newLeadForm
						.get('gstinNo')
						.setValue(
							this.newLeadData && this.newLeadData.gstinNo
								? this.newLeadData.gstinNo
								: '',
						);
					this.newLeadForm
						.get('typeOfCompany')
						.setValue(
							this.newLeadData && this.newLeadData.typeOfCompany
								? this.newLeadData.typeOfCompany
								: '',
						);
					this.newLeadForm
						.get('businessIncorporationYear')
						.setValue(
							this.newLeadData &&
								this.newLeadData.businessIncorporationYear
								? this.newLeadData.businessIncorporationYear
								: '',
						);
					this.newLeadForm
						.get('businessProduct')
						.setValue(
							this.newLeadData && this.newLeadData.businessProduct
								? this.newLeadData.businessProduct
								: '',
						);
					this.newLeadForm
						.get('natureOfBusiness')
						.setValue(
							this.newLeadData &&
								this.newLeadData.natureOfBusiness
								? this.newLeadData.natureOfBusiness
								: '',
						);
					this.newLeadForm
						.get('totalFundingRequirement')
						.setValue(
							this.newLeadData &&
								this.newLeadData.totalFundingRequirement
								? this.newLeadData.totalFundingRequirement
								: '',
						);
					this.newLeadForm
						.get('addressLine1')
						.setValue(
							this.newLeadData && this.newLeadData.addressLine1
								? this.newLeadData.addressLine1
								: '',
						);
					this.newLeadForm
						.get('addressLine2')
						.setValue(
							this.newLeadData && this.newLeadData.addressLine2
								? this.newLeadData.addressLine2
								: '',
						);
					this.newLeadForm
						.get('city')
						.setValue(
							this.newLeadData && this.newLeadData.city
								? this.newLeadData.city
								: '',
						);
					this.newLeadForm
						.get('state')
						.setValue(
							this.newLeadData && this.newLeadData.state
								? this.newLeadData.state
								: '',
						);
					this.newLeadForm
						.get('pincode')
						.setValue(
							this.newLeadData && this.newLeadData.pincode
								? this.newLeadData.pincode
								: '',
						);
					this.newLeadForm
						.get('probabilityToConvert')
						.setValue(
							this.newLeadData &&
								this.newLeadData.probabilityToConvert
								? this.newLeadData.probabilityToConvert
								: '',
						);
					this.newLeadForm
						.get('leadSource')
						.setValue(
							this.newLeadData && this.newLeadData.leadSource
								? this.newLeadData.leadSource
								: '',
						);
					this.newLeadForm
						.get('leadSouceType')
						.setValue(
							this.newLeadData && this.newLeadData.leadSouceType
								? this.newLeadData.leadSouceType
								: '',
						);
					this.newLeadForm
						.get('goLiveMonth')
						.setValue(
							this.newLeadData && this.newLeadData.goLiveMonth
								? this.newLeadData.goLiveMonth
								: '',
						);
					this.newLeadForm
						.get('relationshipManager')
						.setValue(
							this.newLeadData &&
								this.newLeadData.relationshipManager
								? this.newLeadData.relationshipManager
								: localStorage.getItem('currentUser'),
						);
					this.newLeadForm
						.get('salesReason')
						.setValue(
							this.newLeadData && this.newLeadData.salesReason
								? this.newLeadData.salesReason
								: '',
						);
					this.newLeadForm
						.get('companyPan')
						.setValue(
							this.newLeadData && this.newLeadData.companyPan
								? this.newLeadData.companyPan
								: '',
						);
					this.gstSelected =
						this.newLeadData && this.newLeadData.gstinNo
							? this.newLeadData.gstinNo
							: '';
					this.newLeadForm
						.get('gstinNo')
						.setValue(
							this.newLeadData && this.newLeadData.gstinNo
								? this.newLeadData.gstinNo
								: '',
						);
					// this.newLeadForm.get('companyPan').disable();
					// this.newLeadForm.get('gstinNo').disable();
					if (
						this.newLeadData &&
						this.newLeadData.contact.length > 0
					) {
						while (this.formArr.length) {
							this.formArr.removeAt(0);
						}
						this.newLeadData.contact
							.map((item: any) =>
								this.fb.group({
									id: [item.id],
									name: [
										{ value: item.name, disabled: true },
										Validators.required,
									],
									email: [
										{ value: item.email, disabled: true },
										Validators.required,
									],
									phone: [
										{ value: item.phone, disabled: true },
										[
											Validators.required,
											Validators.pattern(/^[0-9]{10}$/),
										],
									],
									designation: [
										{
											value: item.designation,
											disabled: true,
										},
										[Validators.required],
									],
									isPrimary: [
										{
											value: item.isPrimary,
											disabled: true,
										},
										Validators.required,
									],
								}),
							)
							.forEach((item: any) => this.formArr.push(item));
					} else {
						this.formArr.push(this.createContactForm());
					}
				},
				error: (err: any) =>
					this.formArr.push(this.createContactForm()),
			});
		}
	}
	//not to be used as user can edit anything at any time
	// editable(resp: any) {
	// 	this.newLeadForm.get('companyPan').disable();
	// 	this.newLeadForm.get('gstinNo').disable();
	// 	this.newLeadForm.get('typeOfCompany').disable();
	// 	this.newLeadForm.get('businessProduct').disable();
	// 	this.newLeadForm.get('supplierName').disable();
	// 	this.newLeadForm.get('businessIncorporationYear').disable();
	// 	this.shieldService.hasAccess.subscribe((access) => {
	// 		this.shieldService.laneListIds.subscribe((x) => {
	// 			if (x && Object.keys(x).length > 0) {
	// 				let obj = x.filter((element) => element.laneName == 'new-leads')
	// 				if (resp.data.laneId == obj[0].laneId && access) {
	// 					this.showSave = true
	// 					this.fieldEnable = true
	// 					this.newLeadForm.enable()
	// 				} else {
	// 					this.showSave = false
	// 					this.fieldEnable = false
	// 					this.newLeadForm.disable()
	// 				}
	// 			}
	// 		})
	// 	})
	// }

	initialiseForm() {
		this.newLeadForm = this.fb.group({
			supplierName: [
				{ value: null, disabled: true },
				Validators.required,
			],
			supplierTurnover: [
				null,
				[Validators.required, Validators.pattern(/^[0-9]\d*$/)],
			],
			typeOfCompany: [
				{ value: null, disabled: true },
				Validators.required,
			],
			businessIncorporationYear: [
				{ value: null, disabled: true },
				Validators.required,
			],
			businessProduct: [
				{ value: null, disabled: true },
				Validators.required,
			],
			natureOfBusiness: [null, Validators.required],
			totalFundingRequirement: [
				null,
				[Validators.required, Validators.pattern(/^[0-9]\d*$/)],
			],
			addressLine1: [null, Validators.required],
			companyPan: [
				{ value: null, disabled: true },
				[
					Validators.required,
					Validators.minLength(10),
					Validators.maxLength(10),
					Validators.pattern(/^[A-Z]{5}\d{4}[A-z]{1}$/),
				],
			],
			addressLine2: [null],
			city: [null, Validators.required],
			state: [null, Validators.required],
			pincode: [
				null,
				[Validators.required, Validators.pattern(/^[0-9]{6}$/)],
			],
			mobileNo: [null],
			probabilityToConvert: [null],
			leadSource: [null, Validators.required],
			leadSouceType: [null],
			goLiveMonth: [null],
			relationshipManager: [null],
			salesReason: [null],
			contact: this.fb.array([]),
			gstinNo: [{ value: null, disabled: true }, Validators.required],
		});
	}
	fetchDetailsFromPan() {
		//before putting data in the list clear the pre filled data by the api
		this.newLeadForm.get('typeOfCompany').reset();
		this.newLeadForm.get('businessIncorporationYear').reset();
		this.newLeadForm.get('addressLine1').reset();
		this.newLeadForm.get('addressLine2').reset();
		this.newLeadForm.get('city').reset();
		this.newLeadForm.get('state').reset();
		this.newLeadForm.get('pincode').reset();
		this.newLeadForm.get('gstinNo').reset();
		this.gstSelected = null;
		let panNo = this.newLeadForm.get('companyPan').value;
		if (panNo) {
			this.shieldService.fetchDataByPan(panNo).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.gstList = resp.data;
						this.toasterService.success(
							'GST Fetched Successfully!',
						);
					} else {
						this.toasterService.error(
							'Data Fetching Failed, Please Fill Details!',
						);
					}
				},
				error: (err: any) =>
					this.toasterService.error(
						'Data Fetching Failed, Please Fill Details!',
					),
			});
		} else {
			this.toasterService.error(
				'Please enter Pan No Before Fetching Data!',
			);
		}
	}

	fetchDeatilsByGst(gstNo: string) {
		this.shieldService.fetchByGst(gstNo).subscribe({
			next: (resp: any) => {
				if (resp.success) {
					this.newLeadForm
						.get('supplierName')
						.setValue(resp.data && resp.data.supplierName);
					this.newLeadForm
						.get('addressLine1')
						.setValue(
							resp.data && resp.data.address
								? resp.data.address
								: '',
						);
					this.newLeadForm
						.get('city')
						.setValue(
							resp.data && resp.data.city ? resp.data.city : '',
						);
					this.newLeadForm
						.get('businessIncorporationYear')
						.setValue(
							resp.data && resp.data.incorporationDate
								? resp.data.incorporationDate
								: '',
						);
					this.newLeadForm
						.get('pincode')
						.setValue(
							resp.data && resp.data.pincode
								? resp.data.pincode
								: '',
						);
					this.newLeadForm
						.get('state')
						.setValue(
							resp.data && resp.data.state ? resp.data.state : '',
						);
					this.newLeadForm
						.get('typeOfCompany')
						.setValue(
							resp.data && resp.data.typeOfCompany
								? resp.data.typeOfCompany
								: '',
						);
					if (resp && resp.data && resp.data.contact) {
						let obj = this.fb.group({
							id: [null],
							name: [resp.data.contact.name, Validators.required],
							email: [
								resp.data.contact.email,
								Validators.required,
							],
							phone: [
								resp.data.contact.phone,
								[
									Validators.required,
									Validators.pattern(/^[0-9]{10}$/),
								],
							],
							designation: [
								resp.data.contact.designation,
								Validators.required,
							],
							isPrimary: [
								resp.data.contact.isPrimary,
								Validators.required,
							],
						});
						if (this.formArr.at(0).get('name').value) {
							this.formArr.push(obj);
						} else {
							this.formArr.removeAt(0);
							this.formArr.push(obj);
						}
					}
				} else {
					this.toasterService.error(
						'Data Fetching Failed, Please Fill Details!',
					);
				}
			},
			error: (err: any) =>
				this.toasterService.error(
					'Data Fetching Failed, Please Fill Details!',
				),
		});
	}

	createContactForm() {
		return this.fb.group({
			id: [null],
			name: [null, Validators.required],
			email: [null, Validators.required],
			phone: [
				null,
				[Validators.required, Validators.pattern(/^[0-9]{10}$/)],
			],
			designation: [null, [Validators.required]],
			isPrimary: [false, Validators.required],
		});
	}

	createBuyerForm() {
		if (this.newLeadForm.get('businessProduct').value != 'SID') {
			return this.fb.group({
				id: [null],
				buyerName: [
					null,
					[
						Validators.required,
						Validators.pattern('^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$'),
					],
				],
				buyerAddress: [null],
				buyerRating: [null],
				valueOfPo: [null],
				buyerTurnover: [null],
				relationshipTurnover: [null],
				limitRequested: [null],
				tenorDaysRequested: [null],
			});
		} else {
			return this.fb.group({
				id: [null],
				buyerName: [
					null,
					[
						Validators.required,
						Validators.pattern('^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$'),
					],
				],
				buyerAddress: [null],
				buyerRating: [null, Validators.required],
				valueOfPo: [null],
				buyerTurnover: [null],
				relationshipTurnover: [null],
				limitRequested: [null],
				tenorDaysRequested: [null],
			});
		}
	}
	createSupplierForm() {
		return this.fb.group({
			id: [null],
			supplierName: [
				null,
				[
					Validators.required,
					Validators.pattern('^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$'),
				],
			],
			supplierAddress: [null],
			supplierRating: [null],
			valueOfPo: [null],
			supplierTurnover: [null],
			relationshipTurnover: [null],
			limitRequested: [null],
			tenorDaysRequested: [null],
		});
	}

	restrictPhoneLength(event: any) {
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[0-9]$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = allowedPattern.test(inputChar);
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault();
		}
	}
	restrictPanInfo(event: any) {
		const input = event.target as HTMLInputElement;
		const value = input.value;

		// Regex to match valid input (only alphabets and numbers allowed)
		const validPattern = /^[A-Z0-9]+$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = validPattern.test(inputChar);

		// If the input value doesn't match the pattern, remove the last character
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault();
		}
	}
	restrictPincodeLength(event: any) {
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[0-9]$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = allowedPattern.test(inputChar);
		if (!isValidChar || input.value.length >= 6) {
			event.preventDefault();
		}
	}
	restrictTenorDays(event: any) {
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[0-9]$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = allowedPattern.test(inputChar);
		if (!isValidChar || input.value.length >= 2) {
			event.preventDefault();
		}
	}

	resetrictOnlyNumbers(event: any) {
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[0-9]$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = allowedPattern.test(inputChar);
		if (!isValidChar) {
			event.preventDefault();
		}
	}
	restrictGst(event: any) {
		if (event.target.value.length > 14) {
			return false;
		}
	}
	restrictToLetters(event: any): void {
		const input = event.target as HTMLInputElement;
		const value = input.value;

		// Regex to match valid input (only alphabets and single spaces allowed)
		const validPattern = /^[A-Za-z]( ?[A-Za-z] ?)*$/;

		// If the input value doesn't match the pattern, remove the last character
		if (!validPattern.test(value)) {
			input.value = value.slice(0, -1);
		}
	}
	restrictToLetterName(event: any) {
		const input = event.target as HTMLInputElement;
		const value = input.value;

		// Regex to match valid input (only alphabets,numbers and single spaces allowed)
		const validPattern = /^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$/;

		// If the input value doesn't match the pattern, remove the last character
		if (!validPattern.test(value)) {
			input.value = value.slice(0, -1);
		}
	}
	removeLastSpaceFromRM(event: any) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.newLeadForm.get('relationshipManager').setValue(value.trim());
	}
	removelastSpaceFromSales(event: any) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.newLeadForm.get('salesReason').setValue(value.trim());
	}
	removeLastSpaceFromBuyer(event: any, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.formArrBuyer.at(index).get('buyerName').setValue(value.trim());
	}
	removeLastSpaceFromContact(event: any, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.formArr.at(index).get('name').setValue(value.trim());
	}
	removeLastSpaceFromContactDesignation(event: any, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.formArr.at(index).get('designation').setValue(value.trim());
	}
	removeLastSpaceFromSupplier(event: any, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;
		this.formArrSupplier
			.at(index)
			.get('supplierName')
			.setValue(value.trim());
	}
	restrictyearLength(event: any): void {
		if (event.target.value.length > 4) {
			event.target.value = event.target.value.slice(0, 4);
		}
	}

	get formArr() {
		return this.newLeadForm.get('contact') as FormArray;
	}
	get formArrBuyer() {
		return this.newLeadForm.get('buyer') as FormArray;
	}
	get formArrSupplier() {
		return this.newLeadForm.get('supplier') as FormArray;
	}
	submitForm() {
		if (this.formArr.length == 1) {
			this.formArr.at(0).get('isPrimary').setValue(true);
		}
		if (this.formArr.length > 0) {
			let count = 0;
			for (let i = 0; i < this.formArr.length; i++) {
				if (this.formArr.at(i).get('isPrimary').value) {
					count = count + 1;
				}
			}
			if (count == 0) {
				this.toasterService.error('Please Set One Primary Contact!');
				return;
			}
		}

		const formValidation = [
			{
				field: 'gstinNo',
				message: 'GSTIN not present',
			},
			{
				field: 'companyPan',
				message: 'Company PAN not present',
			},
		];

		for (const { field, message } of formValidation) {
			const control = this.newLeadForm.get(field);
			if (!control?.value) {
				this.toasterService.error(message);
				return;
			}
		}
		if (this.newLeadForm.valid) {
			this.shieldService
				.saveNewLead(this.newLeadForm.getRawValue(), this.laneTaskId)
				.subscribe({
					next: (resp: any) => {
						if (resp.success) {
							this.toasterService.success(resp.message);
						} else {
							this.toasterService.error(resp.message);
						}
					},
					error: (err: any) => this.toasterService.error(err.message),
					complete: () => this.fetchdata(this.laneTaskId),
				});
		} else {
			console.log(this.newLeadForm.controls);
			this.focusFirstInvalidField(this.newLeadForm);
			this.toasterService.error('Please fill all required fields');
		}
	}

	private focusFirstInvalidField(form: any): void {
		for (const field in form.controls) {
			if (field != 'contact' && form.controls[field].invalid) {
				const invalidControl = form.controls[field];
				const invalidFieldElement = document.getElementById(field);
				console.log('field name', field);
				if (invalidFieldElement) {
					invalidControl.markAsTouched();
					invalidFieldElement.focus();
				}
				return;
			} else if (field == 'contact' && form.controls[field].invalid) {
				for (let contactField in form.controls['contact'].controls[0]
					.controls) {
					let errorFormcontrol =
						form.controls['contact'].controls[0].controls[
							contactField
						];
					if (errorFormcontrol.invalid) {
						const invalidControl = form.controls[field];
						const invalidFieldElement =
							document.getElementById(contactField);
						console.log(
							'field name',
							form.controls['contact'].controls[0].controls[
								contactField
							],
						);
						if (invalidFieldElement) {
							invalidControl.markAsTouched();
							invalidFieldElement.focus();
						}
						return;
					}
				}
			}
		}
	}

	openConfirmationDialog(content: string): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			disableClose: true,
			width: '400px',
			height: '220px',
			data: {
				title: 'Please Confirm',
				content: content,
				additional: '',
				class: 'green',
			},
		});
		return dialogRef.afterClosed();
	}

	addNewRow(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to add another contact?',
		).subscribe((result) => {
			if (result) {
				const itemArr = this.formArr.at(index) as FormGroup;
				if (itemArr.valid) {
					this.formArr.push(this.createContactForm());
				} else {
					this.toasterService.error(
						'Please Add Details Before Adding New Field',
					);
				}
			}
		});
	}

	deleteRow(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to delete this contact?',
		).subscribe((result) => {
			if (result) {
				this.formArr.removeAt(index);
			}
		});
	}

	addNewRowBuyer(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to add another buyer?',
		).subscribe((result) => {
			if (result) {
				let itemArr = this.formArrBuyer.at(index) as FormGroup;
				if (itemArr.valid) {
					this.formArrBuyer.push(this.createBuyerForm());
					this.dataSource1.data = this.formArrBuyer.controls;
				} else {
					this.toasterService.error(
						'Please Add Details Before Adding New Field',
					);
				}
			}
		});
	}
	deleteRowBuyer(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to delete this buyer?',
		).subscribe((result) => {
			if (result) {
				this.formArrBuyer.removeAt(index);
				this.dataSource1.data = this.formArrBuyer.controls;
			}
		});
	}
	addNewRowSupplier(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to add another supplier?',
		).subscribe((result) => {
			if (result) {
				let itemArr = this.formArrSupplier.at(index) as FormGroup;
				if (itemArr.valid) {
					this.formArrSupplier.push(this.createSupplierForm());
					this.dataSource2.data = this.formArrSupplier.controls;
				} else {
					this.toasterService.error(
						'Please Add Details Before Adding New Field',
					);
				}
			}
		});
	}
	deleteRowSupplier(index: number) {
		this.openConfirmationDialog(
			'Are you sure you want to delete this supplier?',
		).subscribe((result) => {
			if (result) {
				this.formArrSupplier.removeAt(index);
				this.dataSource2.data = this.formArrSupplier.controls;
			}
		});
	}
	primarySet(index: number) {
		for (let i = 0; i < this.formArr.length; i++) {
			if (i != index) {
				this.formArr.controls[i].get('isPrimary').setValue(false);
			} else {
				this.formArr.controls[i].get('isPrimary').setValue(true);
			}
		}
	}
	businessChanged() {
		let val = this.newLeadForm.get('businessProduct').value;
		if (val == 'SID' || val == 'DEALER_FINANCE') {
			this.sidCheck = true;
			this.piCheck = false;
			this.name = 'Buyer';
			this.shieldService.setBuyerValue('Buyer');
			this.newLeadForm.addControl('buyer', this.fb.array([]));
			this.showMandatory = val == 'SID' ? true : false;
			while (this.formArrBuyer && this.formArrBuyer.length > 0) {
				this.formArrBuyer.removeAt(0);
			}
			if (
				this.newLeadData &&
				this.newLeadData.buyer &&
				this.newLeadData.buyer.length > 0
			) {
				let data1 =
					this.newLeadData && this.newLeadData.buyer
						? this.newLeadData.buyer
						: [];
				if (val != 'SID') {
					data1
						.map((item: any) =>
							this.fb.group({
								id: [item.id ? item.id : null],
								buyerName: [
									item.buyerName ? item.buyerName : null,
									[
										Validators.required,
										Validators.pattern(
											'^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$',
										),
									],
								],
								buyerAddress: [
									item.buyerAddress
										? item.buyerAddress
										: null,
								],
								buyerRating: [
									item.buyerRating ? item.buyerRating : null,
								],
								valueOfPo: [
									item.valueOfPo ? item.valueOfPo : null,
								],
								buyerTurnover: [
									item.buyerTurnover
										? item.buyerTurnover
										: null,
								],
								relationshipTurnover: [
									item.relationshipTurnover
										? item.relationshipTurnover
										: null,
								],
								limitRequested: [
									item.limitRequested
										? item.limitRequested
										: null,
								],
								tenorDaysRequested: [
									item.tenorDaysRequested
										? item.tenorDaysRequested
										: null,
								],
							}),
						)
						.forEach((item: any) => this.formArrBuyer.push(item));
					this.dataSource1.data =
						this.formArrBuyer && this.formArrBuyer.controls
							? this.formArrBuyer.controls
							: [];
				} else {
					data1
						.map((item: any) =>
							this.fb.group({
								id: [item.id ? item.id : null],
								buyerName: [
									item.buyerName ? item.buyerName : null,
									Validators.required,
								],
								buyerAddress: [
									item.buyerAddress
										? item.buyerAddress
										: null,
								],
								buyerRating: [
									item.buyerRating ? item.buyerRating : null,
									Validators.required,
								],
								valueOfPo: [
									item.valueOfPo ? item.valueOfPo : null,
								],
								buyerTurnover: [
									item.buyerTurnover
										? item.buyerTurnover
										: null,
								],
								relationshipTurnover: [
									item.relationshipTurnover
										? item.relationshipTurnover
										: null,
								],
								limitRequested: [
									item.limitRequested
										? item.limitRequested
										: null,
								],
								tenorDaysRequested: [
									item.tenorDaysRequested
										? item.tenorDaysRequested
										: null,
								],
							}),
						)
						.forEach((item: any) => this.formArrBuyer.push(item));
					this.dataSource1.data =
						this.formArrBuyer && this.formArrBuyer.controls
							? this.formArrBuyer.controls
							: [];
				}
			} else {
				this.formArrBuyer.push(this.createBuyerForm());
				this.dataSource1.data = this.formArrBuyer.controls;
			}
			if (this.newLeadForm.get('supplier')) {
				this.newLeadForm.removeControl('supplier');
			}
		} else if (val == 'PO' || val == 'PID' || val == 'VENDOR_FINANCE') {
			this.piCheck = true;
			this.sidCheck = false;
			this.name = 'Supplier';
			this.shieldService.setBuyerValue('Supplier');
			this.newLeadForm.addControl('supplier', this.fb.array([]));
			while (this.formArrSupplier && this.formArrSupplier.length > 0) {
				this.formArrSupplier.removeAt(0);
			}
			if (
				this.newLeadData &&
				this.newLeadData.supplier &&
				this.newLeadData.supplier.length > 0
			) {
				let data2 =
					this.newLeadData && this.newLeadData.supplier
						? this.newLeadData.supplier
						: [];
				data2
					.map((item: any) =>
						this.fb.group({
							id: [item.id ? item.id : null],
							supplierName: [
								item.supplierName ? item.supplierName : null,
								[
									Validators.required,
									Validators.pattern(
										'^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$',
									),
								],
							],
							supplierAddress: [
								item.supplierAddress
									? item.supplierAddress
									: null,
							],
							supplierRating: [
								item.supplierRating
									? item.supplierRating
									: null,
							],
							valueOfPo: [item.valueOfPo ? item.valueOfPo : null],
							supplierTurnover: [
								item.supplierTurnover
									? item.supplierTurnover
									: null,
							],
							relationshipTurnover: [
								item.relationshipTurnover
									? item.relationshipTurnover
									: null,
							],
							limitRequested: [
								item.limitRequested
									? item.limitRequested
									: null,
							],
							tenorDaysRequested: [
								item.tenorDaysRequested
									? item.tenorDaysRequested
									: null,
							],
						}),
					)
					.forEach((item: any) => this.formArrSupplier.push(item));
				this.dataSource2.data =
					this.formArrSupplier && this.formArrSupplier.controls
						? this.formArrSupplier.controls
						: [];
			} else {
				this.formArrSupplier.push(this.createSupplierForm());
				this.dataSource2.data = this.formArrSupplier.controls;
			}
			if (this.newLeadForm.get('buyer')) {
				this.newLeadForm.removeControl('buyer');
			}
		}
	}
	downloadCsvFile() {
		let val = this.newLeadForm.get('businessProduct').value;
		if (val == 'SID' || val == 'DEALER_FINANCE') {
			this.csvService.downloadCsv([], 'SID_Template', [
				'Buyer Name',
				'Buyer Address',
				'Buyer Rating',
				'Relationship Turn Over',
				'Buyer Turn Over',
				'Limit Requested',
				'Tenor Days',
				'Value Of PO For Next 6 Months',
			]);
		} else if (val == 'PID' || val == 'PO' || val == 'VENDOR_FINANCE') {
			this.csvService.downloadCsv([], 'PID_PO_Template', [
				'Supplier Name',
				'Supplier Address',
				'Supplier Rating',
				'Supplier Relationship Turn Over',
				'Supplier Turn Over',
				'Limit Requested',
				'Tenor Days',
				'Value Of PO For Next 6 Months',
			]);
		} else {
			this.toasterService.error(
				'Please Select Business Product Before Downloading Template',
			);
		}
	}
	getOnlyInteger(value: any) {
		if (value == null || value == undefined) {
			return 0;
		}
		const containsString = /[a-zA-Z]/.test(value);

		if (containsString) {
			const integer = value.match(/\d+/);
			if (integer) {
				return integer[0];
			} else {
				return 0;
			}
		} else {
			return value;
		}
	}

	fileUpload(event: any) {
		if (
			!(
				this.roleService.hasPermission(this.allPermissions.NLD_EDIT) ||
				this.roleService.hasAnyRole([
					this.allRoles.CREDIT_TEAM_ADMIN,
					this.allRoles.SUPER_ADMIN,
					this.allRoles.COMPANY_ADMIN_ACCESS,
				])
			)
		) {
			return;
		}
		this.isProgressVisible = true;
		if (!this.newLeadForm.get('businessProduct').value) {
			this.toasterService.error(
				'Please Select Business Product Before Uploading Buyer Data',
			);
			return;
		}
		if (event[0]) {
			this.file = event[0];
		} else {
			this.file = event.target.files[0];
		}
		const uploadData = new FormData();
		uploadData.append('file', this.file);
		this.csvService.getUploadMappingHeaders(uploadData).subscribe({
			next: (resp: any) => {
				let response = resp.data.data;
				if (
					this.newLeadForm.get('businessProduct').value == 'SID' ||
					this.newLeadForm.get('businessProduct').value ==
						'DEALER_FINANCE'
				) {
					if (
						response[0]['Supplier Name'] ||
						response[0]['Supplier Address'] ||
						response[0]['Supplier Rating']
					) {
						this.toasterService.error(
							'Please Upload Correct Data!',
						);
						return;
					}
					if (
						this.newLeadForm.get('businessProduct').value != 'SID'
					) {
						resp.data.data.forEach((element: any) => {
							const newField = this.fb.group({
								buyerName: [
									element['Buyer Name'] ||
										element['﻿Buyer Name'],
									[
										Validators.required,
										Validators.pattern(
											'^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$',
										),
									],
								],
								buyerAddress: [element['Buyer Address']],
								buyerRating: [element['Buyer Rating']],
								valueOfPo: [
									element['Value Of PO For Next 6 Months'],
								],
								buyerTurnover: [element['Buyer Turn Over']],
								relationshipTurnover: [
									element['Relationship Turn Over'],
								],
								limitRequested: [element['Limit Requested']],
								tenorDaysRequested: [
									this.getOnlyInteger(
										element['Tenor Days'] ||
											element['﻿Tenor Days'],
									),
								],
							});
							if (
								!this.formArrBuyer.at(0).get('buyerName').value
							) {
								this.formArrBuyer.removeAt(0);
							}
							this.formArrBuyer.push(newField);
						});
						this.dataSource1.data = this.formArrBuyer.controls;
					} else {
						resp.data.data.forEach((element: any) => {
							const newField = this.fb.group({
								buyerName: [
									element['Buyer Name'] ||
										element['﻿Buyer Name'],
									Validators.required,
								],
								buyerAddress: [element['Buyer Address']],
								buyerRating: [
									element['Buyer Rating'],
									Validators.required,
								],
								valueOfPo: [
									element['Value Of PO For Next 6 Months'],
								],
								buyerTurnover: [element['Buyer Turn Over']],
								relationshipTurnover: [
									element['Relationship Turn Over'],
								],
								limitRequested: [element['Limit Requested']],
								tenorDaysRequested: [
									this.getOnlyInteger(
										element['Tenor Days'] ||
											element['﻿Tenor Days'],
									),
								],
							});
							if (
								!this.formArrBuyer.at(0).get('buyerName').value
							) {
								this.formArrBuyer.removeAt(0);
							}
							this.formArrBuyer.push(newField);
						});
						this.dataSource1.data = this.formArrBuyer.controls;
					}
				} else {
					if (
						response[0]['Buyer Name'] ||
						response[0]['Buyer Address'] ||
						response[0]['Buyer Rating']
					) {
						this.toasterService.error(
							'Please Upload Correct Data!',
						);
						return;
					}
					resp.data.data.forEach((element: any) => {
						const newField = this.fb.group({
							supplierName: [
								element['Supplier Name'],
								[
									Validators.required,
									Validators.pattern(
										'^[A-Za-z0-9]( ?[A-Za-z0-9] ?)*$',
									),
								],
							],
							supplierAddress: [element['Supplier Address']],
							supplierRating: [element['Supplier Rating']],
							valueOfPo: [
								element['Value Of PO For Next 6 Months'],
							],
							supplierTurnover: [element['Supplier Turn Over']],
							relationshipTurnover: [
								element['Supplier Relationship Turn Over'],
							],
							limitRequested: [element['Limit Requested']],
							tenorDaysRequested: [
								this.getOnlyInteger(
									element['Tenor Days'] ||
										element['﻿Tenor Days'],
								),
							],
						});
						const formarray = this.newLeadForm.get(
							'supplier',
						) as FormArray;
						// const
						console.log(
							this.checkFieldAtIndex(
								0,
								'supplierName',
								this.formArrSupplier,
							).value,
						);
						if (
							!this.checkFieldAtIndex(
								0,
								'supplierName',
								this.formArrSupplier,
							).value
						) {
							this.formArrSupplier.removeAt(0);
						}
						this.formArrSupplier.push(newField);
					});
					this.dataSource2.data = this.formArrSupplier.controls;
				}
				this.toasterService.success('Data Mapped Successully!');
			},
			error: (err: any) => {
				this.toasterService.error('Something Went Wrong!');
				this.file = null;
			},
			complete: () => {
				this.file = null;
			},
		});
	}
	checkFieldAtIndex(
		index: number,
		fieldName: string,
		formArr: any,
	): FormControl {
		const formGroup = formArr.at(index) as FormGroup;
		return formGroup.get(fieldName) as FormControl;
	}

	// Chipts Menu Starts

	add(event: any): void {
		const input = event.input;
		const value = event.value;

		// Add a new chip
		if ((value || '').trim()) {
			this.gstSelected = value.trim();
			this.newLeadForm.get('gstinNo').setValue(this.gstSelected);
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.gstCtrl.setValue(null);
	}

	remove(val: any = null): void {
		this.gstSelected = null;
		this.newLeadForm.get('gstinNo').reset();
	}

	selected(event: any): void {
		this.gstSelected = event.option.viewValue;
		this.newLeadForm.get('gstinNo').setValue(this.gstSelected);
		this.fetchDeatilsByGst(this.gstSelected);
		this.gstCtrl.setValue(null);
		this.fruitInput.nativeElement.value = null;
	}

	getCall() {
		this.filteredGst = this.gstCtrl.valueChanges.pipe(
			startWith(''),
			map((category) =>
				category ? this._filterStates(category) : this.gstList.slice(),
			),
		);
	}
	_filterStates(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.gstList.filter(
			(category) => category.toLowerCase().indexOf(filterValue) === 0,
		);
	}
	setGstValue(event: any) {
		this.newLeadForm.get('gstinNo').setValue(event.target.value);
		this.fetchDeatilsByGst(event.target.value);
		this.add({ input: event.target, value: event.target.value });
	}
}
