<mat-tab-group animationDuration="0ms" class="bRChildTab" (selectedTabChange)="onTabChange($event)">
    <mat-tab *ngFor="let bankData of bankStatementList;index as i" [label]="'Bank ' + (i+1)">
        <div class="bureauReportWrap mat-table-overflow default-table-height">
            <table mat-table class="mat-table-theme" [dataSource]="[bankData]">
                <ng-container matColumnDef="PriceInfo">
                    <th mat-header-cell *matHeaderCellDef>
                        <span class="pr-20">Personal Details</span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <p class="pDetails">
                            <span class="lbl">Account Type: </span>
                            <span class="val"> {{element.accountType}}</span>
                        </p>
                        <p class="pDetails">
                            <span class="lbl">IFSC Code: </span>
                            <span class="val"> {{element.ifscCode}}</span>
                        </p>
                        <p class="pDetails">
                            <span class="lbl">Bank Name: </span>
                            <span class="val">{{element.bankAccountName}}</span>
                        </p>
                    </td>
                </ng-container>


                <ng-container matColumnDef="DeliveryStatus">
                    <th mat-header-cell *matHeaderCellDef>
                        <span class="pr-20">Document</span>
                    </th>
                    <td mat-cell class="docColumn" *matCellDef="let element">
                        <div class="docBlckRow">
                            <span *ngFor="let doc of element.bankStatements.files;" class="imgBlk" (click)="downloadFile(doc.id)">
                                <img *ngIf="!['.pdf','.png','.jpg','.xlsx','.csv'].includes(doc.extention)" src="/assets/img/document.png" />
                                <img *ngIf="doc.extention == '.pdf'" src="/assets/img/pdf.png" />
                                <img *ngIf="doc.extention == '.png'" src="/assets/img/png.png" />
                                <img *ngIf="doc.extention == '.jpg'" src="/assets/img/jpg.png" />
                                <img *ngIf="doc.extention == '.xlsx'" src="/assets/img/excel.png" />
                                <img *ngIf="doc.extention == '.csv'" src="/assets/img/csv.png" />
                                <span class="nameTxt truncate" matTooltip="{{doc.name}}">{{doc.name}}</span>
                                <i class="ri-download-fill"></i>
                            </span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns2; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns2"></tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>


<!-- <ng-container matColumnDef="ProductMSN">
    <th mat-header-cell *matHeaderCellDef>
        <span class="pr-20">Address and Contract Details </span>
    </th>
    <td mat-cell *matCellDef="let element">
        <p class="pDetails">
            <span class="lbl">Address: </span>
            <span class="val"> SO BHANWAR LAL SONI WARD NO WARD NO NARSINGHGARH, NARSI, Madhya
                Pradesh,465669 </span>
        </p>
        <p class="pDetails">
            <span class="lbl">Telephone No.: </span>
            <span class="val"> ----</span>
        </p>
        <p class="pDetails">
            <span class="lbl">Mobile No: </span>
            <span class="val"> 8878051670</span>
        </p>
        <p class="pDetails">
            <span class="lbl">Fax No:</span>
            <span class="val"> ICICI Bank</span>
        </p>
    </td>
</ng-container> -->