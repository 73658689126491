import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
	MatAutocompleteSelectedEvent,
	MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { elementAt, map, Observable, startWith } from 'rxjs';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
	selector: 'app-change-assignee-popup',
	templateUrl: './change-assignee-popup.component.html',
	styleUrls: ['./change-assignee-popup.component.scss'],
})
export class ChangeAssigneePopupComponent implements OnInit {
	fetchUser: any = [];
	filteredAssigneeusers: Observable<string[]>;
	myControl = new FormControl('');
	selectedAssignee: any;
	userList: any = [];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ChangeAssigneePopupComponent>,
		private shieldApi: ShieldApiService,
		public toasterService: ToastrService,
	) {}

	ngOnInit() {
		this.getUserList();
	}

	getUserList() {
		this.shieldApi.getKanbanUserList().subscribe((list: any) => {
			if (list.success) {
				this.fetchUser = list['data'];
				list['data'].map((ele: any) => {
					this.userList.push(ele.name);
				});
				console.log('User List Array',this.userList)
				this.openList();
			}
		});
	}
	selectedUser(event: MatAutocompleteSelectedEvent) {
		this.selectedAssignee = event.option.viewValue;
	}
	openList() {
		this.filteredAssigneeusers = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => this._filter(value || '')),
		)
	}
	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.userList.filter((option : any) =>
			option.toLowerCase().includes(filterValue),
		);
	}
	changeAssigne() {
		let selectedAssignee = this.fetchUser.filter((element: any) => {
			return element.name.includes(this.selectedAssignee);
		});
		let obj = {};
		if (selectedAssignee[0]) {
			obj = {
				laneTaskId: this.data.laneTaskId,
				userIds: [selectedAssignee[0].id],
				userType: 'ASSIGNEE',
			};
		} else {
			this.toasterService.error('Please Select User Before Assigning!');
			return;
		}
		this.shieldApi.addWatchersToLaneTask(obj).subscribe({
			next: (res: any) => {
				if (res.success) {
					this.toasterService.success(res.message);
					this.dialogRef.close(true);
				} else {
					this.toasterService.error(res.message);
				}
			},
			error: (error: any) => {
				console.log(error);
				this.toasterService.error('Something went wrong');
			},
		});
	}
	closeAssigneePopup() {
		this.dialogRef.close(true);
	}
}
