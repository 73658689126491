import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-gst-summary',
  templateUrl: './gst-summary.component.html',
  styleUrls: ['./gst-summary.component.scss'],
})
export class GstSummaryComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  gstSummary: string = '';
  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  ngOnInit(): void { }

  onSave(): void {
    const payload = {
      gstSummary: this.gstSummary,
    }
    console.log('Form gstSummary:', payload);
    // this.sendDataToApi(payload);
  }

  barChartOptions: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: null // Set to null for no title
    },
    xAxis: {
      categories: [
        'Apr 23', 'May 23', 'Jun 23', 'Jul 23', 'Aug 23', 'Sep 23',
        'Oct 23', 'Nov 23', 'Dec 23', 'Jan 24', 'Feb 24', 'Mar 24',
        'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24'
      ],
      title: {
        text: 'Months'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Turnover INR'
      }
    },
    series: [
      {
        name: 'Turnover',
        showInLegend: false,
        type: 'column',
        data: [120, 200, 150, 80, 70, 90, 110, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150],
        color: '#007bff' // Set the bar color
      }
    ],
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    credits: {
      enabled: false // Disable Highcharts watermark
    }
  };
}