export enum Permission {

  //New lead details
	NLD_VIEW = 'NLD_VIEW',
	NLD_EDIT = 'NLD_EDIT',

  //Application and document
	AD_VIEW = 'AD_VIEW',
	AD_EDIT = 'AD_EDIT',
	AD_DOWNLOAD_FILE = 'AD_DOWNLOAD_FILE',

  //Underwritting docs
	UD_VIEW = 'UD_VIEW',
	UD_EDIT = 'UD_EDIT',
	UD_WAVE_VIEW = 'UD_WAVE_VIEW',
	UD_WAVE_EDIT = 'UD_WAVE_EDIT',
	UD_DEFF_VIEW = 'UD_DEFF_VIEW',
	UD_DEFF_EDIT = 'UD_DEFF_EDIT',
	UD_APPROVE_REJECT_VIEW = 'UD_APPROVE_REJECT_VIEW',
	UD_APPROVE_REJECT_EDIT = 'UD_APPROVE_REJECT_EDIT',
	UD_REMARK_VIEW = 'UD_REMARK_VIEW',
	UD_REMARK_EDIT = 'UD_REMARK_EDIT',
	UD_REUPLOAD_VIEW = 'UD_REUPLOAD_VIEW',
	UD_REUPLOAD_EDIT = 'UD_REUPLOAD_EDIT',
	UD_DELETE_FILE_VIEW = 'UD_DELETE_FILE_VIEW',
	UD_DELETE_FILE_EDIT = 'UD_DELETE_FILE_EDIT',
	UD_DOWNLOAD_UPLOADED_FILE_VIEW = 'UD_DOWNLOAD_UPLOADED_FILE_VIEW',
	UD_DOWNLOAD_UPLOADED_FILE_EDIT = 'UD_DOWNLOAD_UPLOADED_FILE_EDIT',

  //Score card
	SC_SAVE_VIEW = 'SC_SAVE_VIEW',
	SC_SAVE_EDIT = 'SC_SAVE_EDIT',
	SC_BUYER_APPROVAL_REJECT_VIEW = 'SC_BUYER_APPROVAL_REJECT_VIEW',
	SC_BUYER_APPROVAL_REJECT_EDIT = 'SC_BUYER_APPROVAL_REJECT_EDIT',

  //Bureau Report
	BU_VIEW = 'BU_VIEW',
	BU_DOWNLOAD = 'BU_DOWNLOAD',

  //Financial Module
	FINACIAL_VIEW = 'FINACIAL_VIEW',
	FINACIAL_EDIT = 'FINACIAL_EDIT',
	FINANCIAL_DOWNLOAD = 'FINANCIAL_DOWNLOAD',

  //GST Module
	GST_VIEW = 'GST_VIEW',
	GST_EDIT = 'GST_EDIT',
	GST_REPORT_DOWNLOAD = 'GST_REPORT_DOWNLOAD',

  //Bank Statment Analysis
	BANK_STATEMENT_VIEW = 'BANK_STATEMENT_VIEW',
	BANK_STATEMENT_EDIT = 'BANK_STATEMENT_EDIT',

  //ledger Module
	LED_VIEW = 'LED_VIEW',
	LED_EDIT = 'LED_EDIT',
	LED_DOWNLOAD = 'LED_DOWNLOAD',
	LED_APPROVE_REJECT = 'LED_APPROVE_REJECT',

  //Security covenants
	SEC_VIEW = 'SEC_VIEW',
	SEC_EDIT = 'SEC_EDIT',
	SEC_ADDREMARK = 'SEC_ADDREMARK',

  //CAM
	CAM_VIEW = 'CAM_VIEW',
	CAM_EDIT = 'CAM_EDIT',
	CAM_APPROVE_REJECT = 'CAM_APPROVE_REJECT',

  //Review Decision (Removed by chirag)
	REVIEW_VIEW = 'REVIEW_VIEW',
	REVIEW_EDIT = 'REVIEW_EDIT',

  //Post sanction documents
	PS_VIEW = 'PS_VIEW',
	PS_EDIT = 'PS_EDIT',
	PS_WAVE_DEFF_VIEW = 'PS_WAVE_DEFF_VIEW',
	PS_WAVE_DEFF_EDIT = 'PS_WAVE_DEFF_EDIT',
	PS_APPROVE_REJECT_VIEW = 'PS_APPROVE_REJECT_VIEW',
	PS_APPROVE_REJECT_EDIT = 'PS_APPROVE_REJECT_EDIT',
	PS_REMARK_VIEW = 'PS_REMARK_VIEW',
	PS_REMARK_EDIT = 'PS_REMARK_EDIT',
	PS_REUPLOAD_VIEW = 'PS_REUPLOAD_VIEW',
	PS_REUPLOAD_EDIT = 'PS_REUPLOAD_EDIT',
	PS_DELETE_FILE_VIEW = 'PS_DELETE_FILE_VIEW',
	PS_DELETE_FILE_EDIT = 'PS_DELETE_FILE_EDIT',
	PS_DOWNLOAD_UPLOADED_FILE_VIEW = 'PS_DOWNLOAD_UPLOADED_FILE_VIEW',
	PS_DOWNLOAD_UPLOADED_FILE_EDIT = 'PS_DOWNLOAD_UPLOADED_FILE_EDIT',

  //Dashboard
	ALL_LEADS_VIEW = 'ALL_LEADS_VIEW',
	CHANGE_ASSIGNEE = 'CHANGE_ASSIGNEE',
	CHANGE_WATCHER = 'CHANGE_WATCHER',
	DETAIL_ADD_COMMENT = 'DETAIL_ADD_COMMENT',
	DETAIL_VIEW_COMMENT = 'DETAIL_VIEW_COMMENT',
	DEATIL_MOVE_LEAD = 'DEATIL_MOVE_LEAD',
	DETAIL_APPROVE_REJECT = 'DETAIL_APPROVE_REJECT',
	DETAIL_STATUS_CHANGE = 'DETAIL_STATUS_CHANGE',

  //Ramson Invoice
	VIEW_RAMSON_INVOICE = 'VIEW_RAMSON_INVOICE',
	UPLOAD_RAMSON_INVOICE = 'UPLOAD_RAMSON_INVOICE',

  //Admin permissions
	ADD_USER = 'ADD_USER',
	REMOVE_USER = 'REMOVE_USER',
	ASSIGN_ROLE = 'ASSIGN_ROLE',
	CREATE_MODULE = 'CREATE_MODULE',
	CREATE_ROLE = 'CREATE_ROLE',
}
