import { Injectable } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { environment } from 'src/environments/environment';
import { ApiUrlPath } from '../constant/api.url.const';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root',
})
export class CsvExportService {
	readonly BaseUrl = environment.UNDERWRITTING_API_BASE_URL;
	constructor(
		private http: HttpClient,
		public toasterService: ToastrService,
	) {}
	downloadFile(data: any, filename: string = 'data') {
		let csvData = this.ConvertToCSV(data);
		let blob = new Blob(['\ufeff' + csvData], {
			type: 'text/csv;charset=utf-8;',
		});
		let dwldLink = document.createElement('a');
		let url = URL.createObjectURL(blob);
		let isSafariBrowser =
			navigator.userAgent.indexOf('Safari') !== -1 &&
			navigator.userAgent.indexOf('Chrome') === -1;

		if (isSafariBrowser) {
			//if Safari open in new window to save file with random filename.
			dwldLink.setAttribute('target', '_blank');
		}
		dwldLink.setAttribute('href', url);
		dwldLink.setAttribute('download', filename + '.csv');
		dwldLink.style.visibility = 'hidden';
		document.body.appendChild(dwldLink);
		dwldLink.click();
		document.body.removeChild(dwldLink);
	}

	ConvertToCSV(objArray: any) {
		let array =
			typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		let str = '';
		let row = '';

		for (let index in objArray[0]) {
			// Convert camelCase to space-separated words
			let header = this.camelCaseToSpaces(index);
			row += header + ',';
		}
		row = row.slice(0, -1);
		//append Label row with line break
		str += row + '\r\n';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (let index in array[i]) {
				if (line !== '') line += ',';
				line += array[i][index];
			}
			str += line + '\r\n';
		}
		return str;
	}

	// Helper function to convert camelCase text to space-separated
	camelCaseToSpaces(str: string): string {
		return (
			str
				// Insert a space before all caps
				.replace(/([A-Z])/g, ' $1')
				// Uppercase the first character
				.replace(/^./, function (match) {
					return match.toUpperCase();
				})
		);
	}
	downloadCsv(dataArr: any, fileName: string, headerColumn: any) {
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: false,
			title: '',
			useBom: true,
			noDownload: false,
			headers: headerColumn,
		};
		new ngxCsv(dataArr, fileName, options);
	}
	getUploadMappingHeaders(obj: any) {
		// let formatURL= "https://apiqa.credlixlab.com/get-headers-with-records";
		let url = this.BaseUrl + ApiUrlPath.uploadMappingHeaders;
		return this.http.post(url, obj);
	}

	result: any;
	openDocumentNewTab(res: any) {
		if (res) {
			const contentType = res.headers.get('content-type');
			const contentDispositionHeader = res.headers.get(
				'content-disposition',
			);
			const fileExtension = this.getFileExtension(
				contentDispositionHeader,
			);
			const fileBlob = new Blob([res.body], { type: contentType });
			const fileUrl = URL.createObjectURL(fileBlob);
			if (fileExtension === 'pdf') {
				// For PDF files, open in a new browser tab
				window.open(fileUrl, '_blank');
			} else if (
				fileExtension === 'jpeg' ||
				fileExtension === 'png' ||
				fileExtension === 'jpg'
			) {
				// Show JPEG or PNG image in a new tab
				const imgWindow = window.open('', '_blank');
				imgWindow.document.write(
					'<html><body><img src="' + fileUrl + '" /></body></html>',
				);
				imgWindow.document.close();
			} else if (
				fileExtension === 'txt' ||
				fileExtension === '"text/csv'
			) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const fileContent = reader.result;
					const txtWindow = window.open('', '_blank');
					txtWindow.document.write(
						'<html><body><pre>' +
							fileContent +
							'</pre></body></html>',
					);
					txtWindow.document.close();
				};
				reader.readAsText(fileBlob);
			} else {
				this.result = contentDispositionHeader
					.split(';')[1]
					.trim()
					.split('=')[1];
				this.result = this.result.replace(/"/g, '');
				saveAs(res.body, this.result);
				this.toasterService.success(
					'Success',
					'Downloaded Successfully',
				);
				return this.result.replace(/"/g, '');
			}
		} else {
			let msg =
				res['message'] ||
				'Some Error occured while fetching documents!';
			this.toasterService.error(msg);
		}
	}
	getFileExtension(contentDispositionHeader: string): string {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(contentDispositionHeader);
		if (matches != null && matches[1]) {
			const filename = matches[1].replace(/['"]/g, '');
			const extension = filename.split('.').pop();
			return extension.toLowerCase();
		}
		return '';
	}
}
