<mat-tab-group animationDuration="0ms">

    <mat-tab label="Inprincipal">
        <app-inprincipal></app-inprincipal>
    </mat-tab>

    <mat-tab label="Final CAM">
        <section class="finalCamWraper">
            <mat-tab-group animationDuration="0ms">
                <mat-tab label="Basic Information">
                    <app-basic-information></app-basic-information>
                </mat-tab>

                <mat-tab label="Borrower Information">
                    <app-borrower-information></app-borrower-information>
                </mat-tab>

                <mat-tab label="GST Summary">
                    <app-gst-summary></app-gst-summary>
                </mat-tab>

                <mat-tab label="Financial Summary">
                    <app-financial-summary></app-financial-summary>
                </mat-tab>

                <mat-tab label="Site Visit">
                    <app-site-visit></app-site-visit>
                </mat-tab>
            </mat-tab-group>
        </section>
    </mat-tab>
</mat-tab-group>