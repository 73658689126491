<div class="comment-dialog">
    <i class="ri-close-line closeistoryButton" (click)="closeCommentTab()"></i>
    <div select="[rightPane]">
      <div class="commentsSection" *ngIf="(roleService.hasPermission(allPermissions.DETAIL_ADD_COMMENT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN]))">
        <div class="box-border">
          <div class="commentsSection" fxLayout="row">
            <label for="w3review">Add Comment</label>
            <textarea id="commentTextArea" name="commentTextArea" rows="6" cols="55" [(ngModel)]="addTaskComment">
          </textarea>
          </div>
          <div class="cta-box">
            <button class="btnSave" (click)="saveTaskComment()"> <i class="ri-chat-1-line"></i> Comment </button>
            <button class="btnCancel" (click)="cancelComment()"> Discard</button>
          </div>
        </div>
      </div>
      <!-- comment-section -->
      <div class="comment-section" *ngFor="let commentData of totalComments">
        <div class="inner-commnent-box">
          <div class="div-title" *ngIf="commentData.commentor">
            <span class="customer-short-name">{{getInitial(commentData.commentor)}}</span>
            <p class="table-sub-details name-full" style="text-transform: capitalize;">
              {{commentData.commentor}}
            </p>
          </div>
          <span class="date-comm">{{commentData.createdAt | date}}</span>
        </div>
        <p class="comment-p">{{commentData.message}}</p>
      </div>
    </div>
  </div>
  