import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss'],
})
export class BasicInformationComponent implements OnInit {
  deviationsList: Array<{ deviation: string; mitigant: string }> = [];
  isEditable = true;
  errorMessages: { [key: number]: string } = {};
  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  basicFormFields = [
    { label: 'Name of the client', type: 'input', value: '', placeholder: '' },
    { label: 'Scheme', type: 'input', value: '', placeholder: '' },
    { label: 'Zone', type: 'input', value: '', placeholder: '' },
    { label: 'Office Address', type: 'input', value: '', placeholder: '' },
    { label: 'Nature of Business', type: 'input', value: '', placeholder: '' },
    { label: 'Date of Incorporation', type: 'input', value: '', placeholder: '' },
    { label: 'Date of the CAM', type: 'input', value: '', placeholder: '' },
    { label: 'Sector/industry/sub industry', type: 'input', value: '' },
    { label: 'PAN', type: 'input', value: '', placeholder: '', validation: { minLength: 10, maxLength: 10 } },
    { label: 'Constitution', type: 'select', placeholder: 'Select Constitution', value: '', options: ['Option 1', 'Option 2', 'Option 3'] },
    { label: 'MSME', type: 'input', value: '', placeholder: '' },
    { label: 'Listed/Unlisted', type: 'select', value: '', options: ['Listed', 'Unlisted'] },
    { label: 'Promoters part of structure/Contact Details/Email id??', type: 'input', value: '', placeholder: '' },
    { label: 'Existing Facility', type: 'input', value: '', placeholder: '' },
    { label: 'Proposed Facility', type: 'input', value: '', placeholder: '' },
    { label: 'Other Group Exposure', type: 'input', value: '', placeholder: '' },
    { label: 'Total Group Exposure /Limit', type: 'input', value: '', placeholder: '' },
    { label: 'Rate of interest (p.a.)', type: 'input', value: '', placeholder: '' },
    { label: 'Tenor', type: 'input', value: '', placeholder: '' },
    { label: 'Funding %', type: 'input', value: '', placeholder: '' },
    { label: 'Stale Invoice/Instrument validity', type: 'input', value: '', placeholder: '' },
    { label: 'Security if any', type: 'input', value: '', placeholder: '' },
    { label: 'Processing Fees', type: 'input', value: '', placeholder: '' },
    { label: 'Delayed payment charges', type: 'input', value: '', placeholder: '' },
    { label: 'Facility Validity', type: 'input', value: '', placeholder: '' },
    { label: 'Commercial CIBIL classification', type: 'input', value: '', placeholder: '' },
    { label: 'Cibil Score', type: 'input', value: '', placeholder: '' },
    { label: 'External Credit Rating of borrower', type: 'input', value: '', placeholder: '' },
    { label: 'Internal Rating (If SID)', type: 'input', value: '', placeholder: '' },
    { label: 'SMA Classification', type: 'input', value: '', placeholder: '' },
    { label: 'PEP', type: 'input', value: '', placeholder: '' },
    { label: 'Personal Guarantee', type: 'input', value: '', placeholder: '' },
    { label: 'PDC/UDC', type: 'input', value: '', placeholder: '' },
    { label: 'DPN', type: 'input', value: '', placeholder: 'Yes' },
    { label: 'FIU List/World check/Probe 42 findings/Corporsitory', type: 'input', value: '', placeholder: 'NA' },
    { label: 'Sourcing/Channel Partner', type: 'input', value: '', placeholder: '-' }
  ];

  ngOnInit(): void { }

  onBasicSave(type: any): void {
    if (this.hasValidationErrors()) {
      console.error('Cannot save: Validation errors exist.');
      return;
    }

    if (type === 'Save') {

    } else {

    }

    const payload = {
      formData: this.basicFormFields.map(({ label, value }) => ({ label, value })),
      deviations: this.deviationsList,
    };

    console.log('Form Payload:', payload);
    this.sendDataToApi(payload);
  }

  sendDataToApi(data: any): void {
    console.log('Sending data to API:', data);
  }

  hasValidationErrors(): boolean {
    return Object.keys(this.errorMessages).length > 0;
  }

  validateField(index: number): void {
    const field = this.basicFormFields[index];
    const validation = field.validation;
    const valueLength = field.value.length;

    if (validation) {
      if (validation.minLength && valueLength < validation.minLength) {
        this.errorMessages[index] = `Field must be at least ${validation.minLength} characters.`;
      } else if (validation.maxLength && valueLength > validation.maxLength) {
        this.errorMessages[index] = `Field must not exceed ${validation.maxLength} characters.`;
      } else {
        delete this.errorMessages[index]; // Validation passed
      }
    }
  }

  addDeviationField(): void {
    this.deviationsList.push({ deviation: '', mitigant: '' });
  }

  removeDeviationField(index: number): void {
    this.deviationsList.splice(index, 1);
  }
}