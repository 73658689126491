<div class="head-box">
    <div class="main-head d-flex align-items-center js-between">
      <div class="left-head w-50">
        <h2>Conditions List</h2>
      </div>
      <div class="right-head w-50 d-flex align-items-center justify-content-end">
        <button class="saveBtn point-cursor"  [disabled]="!canSubmit()" (click)="submitValues()">Submit</button>
      </div>
    </div>
  
    <section class="securityConventWrap">
      <div class="securityLftBlock">
        <div class="securityLftCont">
          <!-- <div class="search-bar">
            <i class="ri-search-line"></i>
            <input type="text" placeholder="Search" />
          </div> -->
          <div class="conditionListWrap">
            <ul class="conditions-list">
              <li *ngFor="let con of conditionsList" class="condition-item">
                <div class="tags">
                  <mat-checkbox [(ngModel)]="con.value"></mat-checkbox>
                  <span class="chkCondition">{{ con.text }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <div class="securityRhtBlock">
        <h4>Select Partners List</h4>
        <ul class="multiLvlChkWrap">
          <li *ngFor="let user of businessUserDetails" class="condition-item">
            <div class="tags">
              <mat-checkbox [(ngModel)]="user.isSelected"></mat-checkbox>
              <span class="chkCondition">{{ user.name }}</span>
            </div>
            <span class="condition-text">Managing Director</span>
          </li>
        </ul>
      </div>
    </section>
  </div>