<section class="finalCamWraper">

    <section class="finalCamBlock">
        <h1>SITE VISIT REPORT</h1>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of siteVisitReportList">
                    <td>{{ item.title }}</td>
                    <td>
                        <textarea [(ngModel)]="item.comments" placeholder="Enter comments"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">

        <section class="finalCamBlock">
            <section class="borrowerTxtAreaWrap">
                <h1>Brief about factory</h1>
                <textarea [(ngModel)]="briefAboutFactory"></textarea>
            </section>
        </section>

        <section class="deviationListWrap">
            <h2>
                <span>Production process in brief</span>
                <button class="addMoreFieldsCta" (click)="addDeviationField('ProductionProcess')">
                    <i class="ri-file-add-line"></i>
                    Add More Field
                </button>
            </h2>
            <table class="deviation-table">
                <thead>
                    <tr>
                        <th>Steps</th>
                        <th>Production Process followed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of productionProcess; let i = index">

                        <td>
                            <input type="text" placeholder="Enter Steps" [(ngModel)]="item.steps" />
                        </td>
                        <td>
                            <input type="text" placeholder="Enter Production Process followed"
                                [(ngModel)]="item.productionProcessFollowed" />
                        </td>
                        <td>
                            <button (click)="removeDeviationField('productionProcess', i)">
                                <i class="ri-delete-bin-line"></i>
                                Remove
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>

    </section>

    <section class="finalReamrkControl">
        <label>
            Final Remarks
        </label>
        <input placeholder="Satisfactory" />
    </section>


    <section class="finalCamBlock">
        <h1>Key checklist for site picture</h1>


        <table class="deviation-table mb-10">
            <thead>
                <tr>
                    <th>Particulars</th>
                    <th>Yes/No (if No pls provide detailed reasoning)</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of tableData; let i = index">
                    <td>{{ row.particular }}</td>
                    <td>
                        <select [(ngModel)]="tableData[i].value">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="save-button" (click)="saveValues()">Save</button>


        <section class="sitePicWrap mt-10">
            <div class="sitePicHead">
                <span>Site PIC</span>
                <i class="ri-download-fill"></i>
            </div>
            <ul class="sitePicItem">
                <li>
                    <img src="/assets/img/companyProfile.png" />
                    <span class="docNameTxt">ADD DOC.</span>
                    <i class="ri-download-fill"></i>
                </li>
                <li>
                    <img src="/assets/img/companyProfile.png" />
                    <span class="docNameTxt">DOC1</span>
                    <i class="ri-download-fill"></i>
                </li>
                <li>
                    <img src="/assets/img/companyProfile.png" />
                    <span class="docNameTxt">DOC1</span>
                    <i class="ri-download-fill"></i>
                </li>

            </ul>
        </section>
    </section>

</section>