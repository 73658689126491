import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-financial-summary',
  templateUrl: './financial-summary.component.html',
  styleUrls: ['./financial-summary.component.scss'],
})
export class FinancialSummaryComponent implements OnInit {

  financialSummary: string = '';
  debtSizing: string = '';

  particularsDetails: Array<{ particulars: string; share: string }> = [];
  analysisOfBusiness: Array<{ vendorName: string; purchases: string; dependency: string; banking: string; creditPeriod: string; }> = [];
  existingLoanDetails: Array<{ institutionName: string; sancDate: string; natureOfFacility: string; sanctionedLimits: string; outstanding: string; CommenMonthly: string; }> = [];

  particulars = [
    { title: 'Growth Rate', comments: '' },
    { title: 'Profitability', comments: '' },
    { title: 'Networth', comments: '' },
    { title: 'Debtors as on date', comments: '' },
    { title: 'Creditors as on date', comments: '' },
    { title: 'ISCR/DSCR', comments: '' },
    { title: 'Other Observations', comments: '' }
  ];

  briefSnapshotOfVendors = [
    { title: 'Borrower-vendor should have active relationship & txn velocity to be commensurate to the business scale and procurement by borrower.', comments: '' },
    { title: 'Past trading relationship should be through einvoicing only', comments: '' },
    { title: 'Vendor to be GST registered with atleast 36- month track record of timely GST filing', comments: '' },
    { title: 'Vendor should not be trader', comments: '' }
  ];

  bankingAnalysis = [
    { title: 'Type of A/c', comments: '' },
    { title: 'Bank Name', comments: '' },
    { title: 'Period of statement', comments: '' },
    { title: 'ABB', comments: '' },
    { title: 'OD Utilization in L3M', comments: '' },
    { title: 'Penal Charges if any', comments: '' },
    { title: 'Inward < 3%', comments: '' },
    { title: 'Outward < 5%', comments: '' },
    { title: 'Business Credits', comments: '' },
    { title: 'Monthly EMI', comments: '' },
    { title: 'Other Observations', comments: '' }
  ];

  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  ngOnInit(): void { }

  onSave(): void {
    const payload = {
      particulars: this.particulars,
      briefSnapshotOfVendors: this.briefSnapshotOfVendors,
      bankingAnalysis: this.bankingAnalysis,
      financialSummary:this.financialSummary,
      debtSizing:this.debtSizing,
      particularsDetails: this.particularsDetails,
      analysisOfBusiness: this.analysisOfBusiness,
      existingLoanDetails: this.existingLoanDetails,

    }
    console.log('Form financial:', payload);
    // this.sendDataToApi(payload);
  }

  addDeviationField(value: any): void {
    if (value === 'ParticularsDetails') {
      this.particularsDetails.push({ particulars: '', share: '' });
    } else if (value === 'AnalysisOfBusiness') {
      this.analysisOfBusiness.push({ vendorName: '', purchases: '', dependency: '', banking: '', creditPeriod: '', });
    } else if (value === 'ExistingLoanDetails') {
      this.existingLoanDetails.push({ institutionName: '', sancDate: '', natureOfFacility: '', sanctionedLimits: '', outstanding: '', CommenMonthly: '' });
    }
  }

  // Method to remove a specific row
  removeDeviationField(type: string, index: number): void {
    if (type === 'ParticularsDetails') {
      this.particularsDetails.splice(index, 1);
    } else if (type === 'AnalysisOfBusiness') {
      this.analysisOfBusiness.splice(index, 1);
    } else if (type === 'ExistingLoanDetails') {
      this.existingLoanDetails.splice(index, 1);
    }
  }



}