import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-borrower-information',
  templateUrl: './borrower-information.component.html',
  styleUrls: ['./borrower-information.component.scss'],
})
export class BorrowerInformationComponent implements OnInit {
  SummaryOfBorrower: string = '';
  personSpokenTo: string = '';
  modeOfDiscussion: string = '';
  link: string = '';
  keyRiskAndMitigant: string = '';
  PromotersBackground: string = '';
  keyRelatedEntities: string = '';

  errorMessages: { [key: number]: string } = {};

  keyCustomersSuppliers: Array<{ serialNo: string; customerName: string; amount: any; }> = [];
  particularsDetails: Array<{ particulars: string; share: string }> = [];

  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  ngOnInit(): void { }

  onBorrowerSave(): void {
    const payload = {
      SummaryOfBorrower: this.SummaryOfBorrower,
      personSpokenTo: this.personSpokenTo,
      modeOfDiscussion: this.modeOfDiscussion,
      link: this.link,
      PromotersBackground: this.PromotersBackground,
      keyRelatedEntities: this.keyRelatedEntities,
      keyCustomersSuppliers: this.keyCustomersSuppliers,
      particularsDetails: this.particularsDetails



    }
    console.log('Form Payload:', payload);
    // this.sendDataToApi(payload);
  }


  sendDataToApi(data: any): void {
    console.log('Sending data to API:', data);
  }

  hasValidationErrors(): boolean {
    return Object.keys(this.errorMessages).length > 0;
  }

  addDeviationField(value: any): void {
    if (value === 'KeyCustomers') {
      this.keyCustomersSuppliers.push({ serialNo: '', customerName: '', amount: '' });
    } else if (value === 'ParticularsDetails') {
      this.particularsDetails.push({ particulars: '', share: '' });
    }
  }

  removeDeviationField(type: string, index: number): void {
    if (type === 'KeyCustomers') {
      this.keyCustomersSuppliers.splice(index, 1);
    } else if (type === 'ParticularsDetails') {
      this.particularsDetails.splice(index, 1);
    }
  }
}