import { Component, OnInit } from '@angular/core';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ConsumerReportDataService } from './consumer-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

import { RoleService } from 'src/app/service/role.service';
import { Permission } from 'src/app/enum/permission.enum'
import Roles from 'src/app/enum/roles.enum'

@Component({
  selector: 'app-bureau-report',
  templateUrl: './bureau-report.component.html',
  styleUrls: ['./bureau-report.component.scss']
})
export class BureauReportComponent implements OnInit {
  constructor(private shieldService: ShieldApiService, private consumerReportService: ConsumerReportDataService, public roleService: RoleService,
    private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private tostrService: ToastrService
  ) { }
  businessUserDetails: any[] = [];
  laneTaskId: string;
  allPermissions:any=Permission;
  allRoles:any = Roles;

  get selectedLaneName() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    let laneId = decodeURIComponent(parts[parts.length - 1]);
    return laneId;
  }

  showTab: boolean = false; // Flag to control tab visibility
  validPaths: string[] = ['consumer-info', 'enquiry-bureau-report', 'summary-bureau-report', 'accounts-bureau-report'];

  ngOnInit() {
    this.route.params.subscribe((x) => {
      this.laneTaskId = x['id'] || this.selectedLaneName;
      this.shieldService.setLaneTaskId(this.laneTaskId);
      this.fetchdata(this.laneTaskId, false);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentUrl = this.router.url;
        const pathSegment = this.extractRelevantSegment(currentUrl);
        this.showTab = this.validPaths.includes(pathSegment);
        this.cdr.detectChanges();
      });


    this.getLanTaskData();
    if (this.businessUserDetails && this.businessUserDetails.length > 0) {
      this.onTabChange(0);
      let cibilScore = this.businessUserDetails[0].cibilScore;
      this.shieldService.bureauScore.next(cibilScore);
    }
    this.shieldService.updateData(this.selectedLaneName);
    this.shieldService.updateCommercialData(this.selectedLaneName);
  }

  extractRelevantSegment(url: string): string {
    const cleanUrl = url.split('?')[0];
    const segments = cleanUrl.split('/');
    const index = segments.findIndex(segment => segment === 'bureau-report');
    if (index !== -1 && segments.length > index + 1) {
      return segments[index + 1];
    }
    return '';
  }

  ngAfterViewInit() {
    const currentUrl = this.router.url;
    const pathSegment = this.extractRelevantSegment(currentUrl);
    this.showTab = this.validPaths.includes(pathSegment);
    this.cdr.detectChanges();
  }

  getLanTaskData() {
    this.shieldService.laneData.subscribe((x) => {
      if (!x || !x.applicationPreview?.detailsAndDocumentation?.businessUserDetails) {
        return;
      }
      this.businessUserDetails = x.applicationPreview.detailsAndDocumentation.businessUserDetails;
    });
  }

  selectedTabId: string | null = null;
  onTabChange(index: number): void {
    const selectedUser = this.businessUserDetails[index];
    if (selectedUser) {
      this.selectedTabId = selectedUser.id;
      this.consumerReportService.getConsumerReportData(this.selectedTabId).subscribe(
        (filteredData: any) => {
          if (filteredData) {
            if (filteredData?.consumerCreditData[0]?.scores[0]?.score) {
              const consumerCreditData = Number(filteredData.consumerCreditData[0].scores[0].score);
              this.businessUserDetails[index]['cibilScore'] = consumerCreditData;
              this.shieldService.bureauScore.next(consumerCreditData);
            }
          } else {
            console.error(`No data found for Tab ID ${this.selectedTabId}`);
          }
        }
      );;
    }
  }

  getDownloadDoc() {
    if (this.selectedLaneName) {
      this.fetchdata(this.selectedLaneName, true);
    }
  }

  fetchdata(x: any, downloadFile: boolean) {
    if (x !== null && x !== "undefined") {
      this.shieldService.fetchData(x).subscribe({
        next: (resp: any) => {
          this.shieldService.setLaneData(resp.data);
          // Find the matching businessUserDetails object
          const businessUserDetails =
            resp?.data?.applicationPreview?.detailsAndDocumentation?.businessUserDetails || [];
          console.log("businessUserDetails: ", businessUserDetails);

          const matchedUser = businessUserDetails.find(
            (user: any) => user.id === this.selectedTabId
          );
          console.log("matchedUser: ", matchedUser);
          if (matchedUser) {
            console.log("Matched User: ", matchedUser);
            const consumerCibilFile = matchedUser.consumerCibilFile;
            if (downloadFile) {
              if (consumerCibilFile) {
                console.log("Consumer CIBIL File: ", consumerCibilFile);
                this.downloadDocument(consumerCibilFile);
              } else {
                this.getConsumerNewDoc(this.selectedLaneName, this.selectedTabId);
                console.warn("No Consumer CIBIL File found for the selected user.");
              }
            }
          } else {
            console.warn("No matching user found with the selectedTabId.");
          }
        },
        error: (err: any) => {
          console.error("Error fetching data:", err);
        }
      });
    }
  }

  getConsumerNewDoc(selectedLaneName: any, selectedTabId: any) {
    console.log("getConsumerNewDoc file ID: ", selectedLaneName, selectedTabId);
    this.shieldService.downloadConsumerDocument(selectedLaneName, selectedTabId).subscribe((res: any) => {
      console.log("res: ", res);
      this.downloadNewSheetCommonMethod(res);
    });
  }

  downloadNewSheetCommonMethod(res: any) {
    var contentDispositionHeader = res.headers.get('content-disposition');
    console.log("contentDispositionHeader: ", contentDispositionHeader);
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    console.log("resultDownload: ", resultDownload);
    let fileName = resultDownload.replace(/"/g, '');
    this.tostrService.success("Downloaded Successfully !");
    saveAs(res.body, fileName);
    return fileName;
  }

  downloadDocument(id: string) {
    console.log("donload file ID: ", id);
    this.shieldService.downloadSingleUploadDocument(id).subscribe((res: any) => {
      console.log("res: ", res);
      this.downloadSheetCommonMethod(res);
    });
  };

  downloadSheetCommonMethod(res: any) {
    var contentDispositionHeader = res.headers.get('content-disposition')
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
    let fileName = resultDownload.replace(/"/g, '');
    this.tostrService.success("Downloaded Successfully !");
    saveAs(res.body, fileName);
    return fileName;
  }

}
