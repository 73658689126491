import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { Location } from '@angular/common';
import { WatcherPopupComponent } from './watcher-popup/watcher-popup.component';
import { CommentsTabComponent } from './comments-tab/comments-tab.component';
import { ChangeAssigneePopupComponent } from './change-assignee-popup/change-assignee-popup.component';
import { MatSelectChange } from '@angular/material/select';
import { ReportDataService } from './../lead-details-view/bureau-report/report-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ConfirmationDialogComponent } from './under-writting-doc/confirmation-dialog/confirmation-dialog.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { RejectDialogComponent } from './reject-dialog/reject-dialog.component';
import { Permission } from 'src/app/enum/permission.enum';
import Roles from 'src/app/enum/roles.enum';
import { RoleService } from 'src/app/service/role.service';

@Component({
	selector: 'app-lead-detail',
	templateUrl: './lead-detail.component.html',
	styleUrls: ['./lead-detail.component.scss'],
})
export class LeadDetailComponent implements OnInit {
	laneTaskId: any;
	laneData: any;
	count: number = 0;
	primaryContact: any;
	buyerLength: number = 0;
	buyerLimit: any;
	options: any;
	laneListData: any[] = [];
	laneId: any;
	showLaneChange: boolean = false;
	selectedLane: any;
	userInfo: any;
	currentLanePosition: number = 2;
	totalLanesList: {
		laneName: string;
		position: number;
		routePath: string;
	}[] = [];
	uploadTypeName: any;
	currentLaneName: any;
	LanesObject: any[] = [];
	laneList: any = [];
	editAccess: boolean = false;
	private isBuyerSubscription: Subscription = new Subscription();
	private laneDataSubscription: Subscription = new Subscription();
	private laneTaskIdSubscription: Subscription = new Subscription();
	private cibilScore: Subscription = new Subscription();
	data$ = new BehaviorSubject<any>(null);
	stageMovement: string;
	bureauReportSubPath: string;
	currentlaneName: string;
	directorCibilScore: number = 0;
	allPermissions: any = Permission;
	allRoles: any = Roles;
	customerName:string='';
	totalFundingRequirment: any;

	businessProduct: any;

	constructor(
		private route: ActivatedRoute,
		public shieldService: ShieldApiService,
		private router: Router,
		public dialog: MatDialog,
		public toasterService: ToastrService,
		private reportDataService: ReportDataService,
		public roleService: RoleService,
	) {}

	ngOnInit() {
		this.userInfo = JSON.parse(localStorage.getItem('USERINFO') as any);
		this.totalLanesList = this.shieldService.LeadLanes;
		this.isBuyerSubscription = this.shieldService.isBuyer.subscribe((element) => {
			if (element) {
				this.uploadTypeName = element;
			}
		});
		this.laneDataSubscription = this.shieldService.laneData.subscribe((x) => {
			if (x == null || Object.keys(x).length == 0) {
				return;
			}
			this.shieldService.setCurrentLaneId(x.laneId);
			if (x && x.applicationPreview) {
				this.customerName = x.applicationPreview.leadDetails.supplierName;
				this.laneData = x;
				this.stageMovement = x.subStatus;
				this.getPrimaryInfo(x);
				this.onceCallApi(this.count, x.boardId);
				this.totalFundingRequirment = this.laneData.applicationPreview.leadDetails.totalFundingRequirement;
			}
			//uncomment when full data comes and editable is required
			// if (this.userInfo.role.roleName == 'COMPANY_ADMIN') {
			// 	this.editAccess = true
			// 	this.shieldService.setHasAccess(true)
			// } else {
			// 	x.assignee.forEach((element:any) => {
			// 		if (element.user.id == this.userInfo.role.userId) {
			// 			this.editAccess = true
			// 			this.shieldService.setHasAccess(true)
			// 		}
			// 	})
			// }
		});
		this.laneTaskIdSubscription = this.shieldService.laneTaskId.subscribe((id) => {
			this.laneTaskId = id;
		});

		this.shieldService.laneData.subscribe((element) => {
			if (element) {
				this.businessProduct = element?.applicationPreview?.leadDetails?.businessProduct
			}
		});
		// this.getLeadsListingData();
		this.totalFundingRequirment = this.laneData
	}

	get CurrentLane(){
		return this.currentlaneName;
	}
	ngAfterViewInit() {
		setTimeout(() => {
			this.cibilScore = this.shieldService.bureauScore.subscribe((res: any) => {
				if (res && res > 0) {
					this.directorCibilScore = res;
				}
			});
		});
	}
	openHistory() {
		const dialogRef = this.dialog.open(AuditTrailComponent, {
			disableClose: false,
			width: '95%',
			height: '90%',
			data: {
				type: 'STAGE_HISTORY',
				laneTaskId: this.laneTaskId,
				stage: this.getSelectedPageName().url,
			},
		});
	}

	productSec: any;
	getLeadsListingData() {
		this.productSec = null;
		// this.shieldService.updateCommercialData(this.laneTaskId);
		this.reportDataService.getReportData().subscribe(
			(jsonData: any) => {
				console.log('jsonData: ', jsonData?.base?.responseReport?.productSec);
				this.productSec = jsonData?.base?.responseReport?.productSec;
			},
			(error) => {
				console.error('Error fetching report data: ', error);
			},
		);
	}

	ngOnDestroy(): void {
		if (this.laneTaskIdSubscription) {
			this.laneTaskIdSubscription.unsubscribe();
			this.shieldService.setLaneTaskId(null);
		}
		if (this.laneDataSubscription) {
			this.laneDataSubscription.unsubscribe();
			this.shieldService.setLaneData(null);
		}
		if (this.isBuyerSubscription) {
			this.isBuyerSubscription.unsubscribe();
			this.shieldService.setBuyerValue(null);
		}
		if (this.cibilScore) {
			this.directorCibilScore = 0;
			this.cibilScore.unsubscribe();
		}
	}

	onceCallApi(number: number, id: any) {
		//uncomment after discussion
		//get userlist for assigne
		// if (number == 0) {
		// 	this.shieldService.getKanbanUserList().subscribe((list:any) => {
		// 		this.options = list['result']
		// 	})
		// }
	}

	reloadPage(): void {
		const currentUrl = this.router.url;
		this.router.navigateByUrl(currentUrl, { skipLocationChange: true }).then(() => {
			// Reload the page by navigating to the same URL
			window.location.reload();
		});
	}

	goToBack(): void {
		this.router.navigate(['/dashboard/lead-listing']);
	}
	reRoute(type: string) {
		this.router.navigate([`dashboard/detail/${type}/${this.laneTaskId}`]);
	}
	isActiveRoute(routePath: string): boolean {
		return this.router.url.includes(routePath);
	}
	bureauRoute(route: string) {
		this.router.navigate([`dashboard/detail/bureau-report/${route}/${this.laneTaskId}`]);
	}
	getPrimaryInfo(x: any) {
		if (x) {
			let newLeads = x.applicationPreview.leadDetails && x.applicationPreview.leadDetails.contact ? x.applicationPreview.leadDetails.contact : [];
			newLeads.forEach((element: any) => {
				if (element.isPrimary) {
					this.primaryContact = element;
				}
			});
			if (x.applicationPreview.leadDetails && x.applicationPreview.leadDetails.buyer.length > 0) {
				let buyerSum = 0;
				let buyer = x.applicationPreview.leadDetails && x.applicationPreview.leadDetails.buyer ? x.applicationPreview.leadDetails.buyer : [];
				this.buyerLength = buyer.length;
				buyer.forEach((item: any) => {
					buyerSum = buyerSum + item.limitRequested;
				});
				this.buyerLimit = buyerSum;
			} else {
				let supplierSum = 0;
				let supplier = x.applicationPreview.leadDetails && x.applicationPreview.leadDetails.supplier ? x.applicationPreview.leadDetails.supplier : [];
				this.buyerLength = supplier.length;
				supplier.forEach((item: any) => {
					supplierSum = supplierSum + item.limitRequested;
				});
				this.buyerLimit = supplierSum;
			}
		}
	}
	watcherPopup() {
		const dialogRef = this.dialog.open(WatcherPopupComponent, {
			disableClose: false,
			panelClass: 'watcherPopupWrap',
			data: {
				laneTaskDetails: this.laneData,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	onSideTabToggle = () => {
		let component = null;
		component = ChangeAssigneePopupComponent;
		const dialogRef = this.dialog.open(component, {
			disableClose: false,
			width: '30%',
			height: '100%',
			position: { bottom: '0px', right: '0px' },
			data: {
				laneTaskId: this.laneData.id,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {
			this.reloadPage();
		});
	};

	comments() {
		let component = null;
		component = CommentsTabComponent;
		const dialogRef = this.dialog.open(component, {
			disableClose: false,
			width: '30%',
			height: '100%',
			position: { bottom: '0px', right: '0px' },
			data: {
				laneTaskId: this.laneData.id,
				userList: this.options,
			},
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	changeStageMovement(event: MatSelectChange) {
		let obj = {
			status: event.value,
			laneId: this.laneData.id,
		};
		this.shieldService.stageMovement(obj).subscribe((res: any) => {
			if (res['statusCode'] == 200) {
				this.toasterService.success('Stage Movement Successfully!');
				this.fetchdata(this.laneData.id);
			}
		});
	}

	leadAction(actionType: string) {
        this.openDialog(RejectDialogComponent, actionType)
            .subscribe((res: any) => {
                if (res) {
                    const laneMapping: any = {
                        'underwritingDoc': 'APPLICATION_PREVIEW',
                        'financial-module': 'FINANCIAL_MODULE',
                        'gst-module': 'GST_MODULE',
                        'scoreCard': 'SCORE_CARD',
                        'bureau-report': 'BUREAU_REPORT',
                        'ledger-module': 'LEDGER_MODULE',
						'post-sanction': 'POST_SANCTION',
                    };
 
                    // Determine laneTaskStage based on selectedLaneName
                    const laneTaskStage = laneMapping[this.selectedLaneName];
 
                    if (!laneTaskStage) {
                        this.toasterService.error(`You cannot ${actionType} on this lane`);
                        return false;
                    }
 
                    const obj = {
                        status: actionType === 'approve' ? 'APPROVED' : 'REJECTED',
                        laneTaskStage: laneTaskStage,
                        remark: res['remarks']
                    };
 
                    this.shieldService.updateLaneApprovals(obj, this.laneData.id).subscribe((res: any) => {
                        if (res.statusCode === 200) {
                            this.toasterService.success(actionType === 'approve' ? 'Approved Successfully!' : 'Rejected Successfully!');
                            this.fetchdata(this.laneData.id);
                        }
                    });
                }
            });
    }

	openDialog(component: any, status: string, height: string = '250px') {
		return this.dialog
			.open(component, {
				disableClose: true,
				width: '500px',
				height: height,
				data: status,
			})
			.afterClosed();
	}

	fetchdata(x: any) {
		if (x !== null && x !== 'undefined') {
			this.shieldService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.shieldService.setLaneData(resp.data);
				},
			});
		}
	}

	checkApproval(laneName: any) {
		if (this.laneData && this.laneData['laneTaskApprovals']) {
			const laneApprovalData = this.laneData['laneTaskApprovals'];
			const pathName = laneName.routePath;

			// Mapping of path names to corresponding approval data keys
			const approvalMapping: any = {
				preview: 'applicationPreview',
				underwritingDoc: 'applicationPreview',
				'financial-module': 'financialModule',
				'ledger-module': 'ledgerModule',
				'gst-module': 'gstModule',
				scoreCard: 'scoreCard',
				'bureau-report': 'bureauReport',
				'post-sanction': 'postSanction',
			};

			// Get the status for the current path
			const statusKey = approvalMapping[pathName];
			const status = statusKey ? laneApprovalData[statusKey]?.status : null;

			if (status === 'APPROVED') {
				return 'ri-checkbox-circle-fill';
			} else if (status === 'PENDING') {
				return 'ri-time-fill';
			} else {
				return 'ri-close-circle-fill';
			}
		}
	}
	get selectedLaneName() {
		const currentUrl = window.location.href;
		const parts = currentUrl.split('/');
		let laneName = decodeURIComponent(parts[parts.length - 2]);
		this.currentlaneName = laneName;
		if (this.shieldService.bureauReportChildList.includes(laneName)) {
			this.bureauReportSubPath = laneName;
			laneName = 'bureau-report';
		} else {
			this.bureauReportSubPath = null;
		}
		return laneName;
	}

	isLaneIsApproved() {
		let flag = false;
		if (this.laneData && this.currentlaneName) {
			let statusLaneList: any[] = [this.laneData.laneTaskApprovals]
			for(let i=0;i<statusLaneList.length;i++){
				if(this.currentlaneName == 'preview'){
					flag = statusLaneList[i]['applicationPreview'].status == 'APPROVED'
					}
					else if(this.currentlaneName == 'underwritingDoc'){
					flag = statusLaneList[i]['applicationPreview'].status == 'APPROVED'
					}
					else if(this.currentlaneName == 'scoreCard'){
					flag = statusLaneList[i]['scoreCard'].status == 'APPROVED'
					}
					else if(this.currentlaneName == 'gst-module'){
					flag = statusLaneList[i]['gstModule'].status == 'APPROVED'
					}
					else if(this.currentlaneName == 'financial-module'){
					flag = statusLaneList[i]['financialModule'].status == 'APPROVED'
					}
				else if (this.currentlaneName == 'bureau-report' || this.currentlaneName == 'consumer-info' || 
					this.currentlaneName == 'enquiry-bureau-report' || this.currentlaneName == 'summary-bureau-report' || 
					this.currentlaneName == 'accounts-bureau-report' || this.currentlaneName == 'borrower-bureau-report' || 
					this.currentlaneName == 'transunion-cibil-report' || this.currentlaneName == 'credit-profile-summary' || 
					this.currentlaneName == 'credit-profile-summary' || this.currentlaneName == 'credit-facility-details' || 
					this.currentlaneName == 'enquiry-details') {
					flag = statusLaneList[i]['bureauReport'].status == 'APPROVED'
					}
				    else if (this.currentlaneName == 'ledger-module') {
					flag = statusLaneList[i]['ledgerModule'].status == 'APPROVED'
				}
			    }
		    }
			return flag;
	}

	isLaneRejected(){
		let flag = false;
		if (this.laneData && this.currentlaneName) {
			let statusLaneList: any[] = [this.laneData.laneTaskApprovals]
			for (let i = 0; i < statusLaneList.length; i++) {
				if (this.currentlaneName == 'preview') {
					flag = statusLaneList[i]['applicationPreview'].status == 'APPROVED';
				} else if (this.currentlaneName == 'underwritingDoc') {
					flag = statusLaneList[i]['applicationPreview'].status == 'APPROVED';
				} else if (this.currentlaneName == 'scoreCard') {
					flag = statusLaneList[i]['scoreCard'].status == 'APPROVED';
				} else if (this.currentlaneName == 'gst-module') {
					flag = statusLaneList[i]['gstModule'].status == 'APPROVED';
				} else if (this.currentlaneName == 'financial-module') {
					flag = statusLaneList[i]['financialModule'].status == 'APPROVED';
				} else if (this.currentlaneName == 'bureau-report') {
					flag = statusLaneList[i]['bureauReport'].status == 'APPROVED';
				}
			}
		}
		return flag;
	}

	isApprovalDisable() {
		if (this.laneData && this.laneData['laneTaskApprovals']) {
			let laneApprovalData = this.laneData['laneTaskApprovals'];

			if (this.selectedLaneName === 'preview') {
				return laneApprovalData['applicationPreview']['status'] === 'APPROVED' ? true : laneApprovalData['applicationPreview']['status'] === 'PENDING' ? false : true;
			}
			if (this.selectedLaneName === 'underwritingDoc') {
				return laneApprovalData['applicationPreview']['status'] === 'APPROVED' ? true : laneApprovalData['applicationPreview']['status'] === 'PENDING' ? false : true;
			}
			if (this.selectedLaneName === 'financial-module') {
				return laneApprovalData['financialModule']['status'] === 'APPROVED' ? true : laneApprovalData['financialModule']['status'] === 'PENDING' ? false : true;
			}
			if (this.selectedLaneName === 'gst-module') {
				return laneApprovalData['gstModule']['status'] === 'APPROVED' ? true : laneApprovalData['gstModule']['status'] === 'PENDING' ? false : true;
			}
			if (this.selectedLaneName === 'scoreCard') {
				return laneApprovalData['scoreCard']['status'] === 'APPROVED' ? true : laneApprovalData['scoreCard']['status'] === 'PENDING' ? false : true;
			}
			if (this.selectedLane === 'postSanction') {
				return laneApprovalData['postSanction']['status'] === 'APPROVED' ? true : laneApprovalData['postSanction']['status'] === 'PENDING' ? false : true;
			}
			if(this.selectedLane === 'postSancion'){
				return laneApprovalData['postSancion']['status'] === 'APPROVED'
					? true
					: laneApprovalData['postSancion']['status'] === 'PENDING'
					? false
					: true;
			}
		}
		return false;
	}

	getSelectedPageName() {
		let result = { name: '', url: '' };
		switch (this.selectedLaneName) {
			case 'preview':
				result = {
					name: 'Application Preview',
					url: 'APPLICATION_PREVIEW',
				};
				break;
			case 'underwritingDoc':
				result = { name: 'Underwriting Doc', url: 'UNDERWRITTING_DOC' };
				break;
			case 'scoreCard':
				result = { name: 'Score Card', url: 'SCORE_CARD' };
				break;
			case 'gst-module':
				result = { name: 'GST Module', url: 'GST_MODULE' };
				break;
			case 'financial-module':
				result = { name: 'Financial Module', url: 'FINANCIAL_MODULE' };
				break;
			case 'bureau-report':
				result = { name: 'Bureau Report', url: 'BUREAU_REPORT' };
				break;
			case 'ledger-module':
				result = { name: 'Ledger Report', url: 'LEDGER_MODULE' };
				break;
			case 'post-sanction':
				result = { name: 'Post Sanction Document', url: 'post-sanction' };
				break;
			default:
				result = {
					name: this.shieldService.toTitleCase(this.selectedLaneName.replace(/-/g, ' ')),
					url: this.selectedLaneName.toUpperCase().replace(/\s+/g, '_'),
				};
				break;
		}
		return result;
	}

	getBureauSubPathName() {
		return this.shieldService.toTitleCase(this.bureauReportSubPath.replace(/-/g, ' '));
	}
	downloadGStReport() {
		const obj = {
			url: this.shieldService.gstUrl,
		};
		this.shieldService.getGstReportDownloadFile(obj).subscribe({
			next: (blob: Blob) => {
				const url = window.URL.createObjectURL(blob);
				saveAs(url, 'invoice-template.xls');
				window.URL.revokeObjectURL(url);
			},
			error: (error: HttpErrorResponse) => {
				console.error('Error downloading the file.', error.message);
			},
		});
	}

	isMoveToPendingVisible() {
		if (this.laneData?.laneTaskApprovals) {
			const laneApprovalData = this.laneData.laneTaskApprovals;

			const laneMapping: { [key: string]: string } = {
				preview: 'applicationPreview',
				underwritingDoc: 'applicationPreview',
				'financial-module': 'financialModule',
				'gst-module': 'gstModule',
				scoreCard: 'scoreCard',
				'post-sanction': 'postSanction',
			};

			const approvalKey = laneMapping[this.selectedLaneName];
			return approvalKey ? laneApprovalData[approvalKey]?.status === 'REJECTED' : false;
		}
		return false;
	}

	moveToPendingStage() {
		let data = {
			title: 'Please Confirm',
			content: 'Are you sure you want to move?',
			additional: '',
			class: 'green',
		};
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			disableClose: true,
			width: '400px',
			height: '220px',
			data: data,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				let obj = {
					laneId: this.laneTaskId,
				};
				this.shieldService.moveToPending(obj).subscribe((res: any) => {
					this.toasterService.success('Lead Move To Pending Successfully !');
					this.fetchdata(this.laneData.id);
				});
			}
		});
	}
}
