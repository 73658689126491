import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CsvExportService } from 'src/app/service/csv-export.service';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { Permission } from 'src/app/enum/permission.enum'
import Roles from 'src/app/enum/roles.enum'
import { RoleService } from 'src/app/service/role.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bank-statement-analysis',
  templateUrl: './bank-statement-analysis.component.html',
  styleUrls: ['./bank-statement-analysis.component.scss']
})
export class BankStatementAnalysisComponent implements OnInit {
  getlanedata: any;
  displayColumns2: Array<string> = ['PriceInfo', 'DeliveryStatus'];
  displayColumns3: Array<string> = ['PriceInfo', 'DeliveryStatus', 'eD'];
  private laneTaskIdSubscription: Subscription;
  laneTaskId: any;
  bankStatementList: Array<any> = [];
  allPermissions: any = Permission;
  allRoles: any = Roles;


  constructor(
    private shieldService: ShieldApiService, 
    private activatedRoute: ActivatedRoute, 
    private csvService: CsvExportService,
    public roleService: RoleService,
    private toasterService: ToastrService) {
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x['id'];
      this.shieldService.setLaneTaskId(x['id']);
    });
  }

  ngOnInit() {
    this.shieldService.fetchData(this.laneTaskId).subscribe((resp: any) => {
      this.bankStatementList = resp?.data?.applicationPreview?.detailsAndDocumentation?.documentationsDTO?.bankDetails;
      this.shieldService.setLaneData(resp.data);
    });
  }

  onTabChange(event: any) {
let index=event.index;
    let docFile = this.bankStatementList[index]
    this.shieldService.showAllFiles('BANK_STATEMENTS', this.laneTaskId ,docFile.id,).subscribe({
      next: (resp: any) => {
        this.bankStatementList[index].bankStatements.files = resp.data;
      }
    })
  }
  getDocName(document: any, index: any){
    this.shieldService.showAllFiles('BANK_STATEMENTS', this.laneTaskId, document.id).subscribe({
      next: (resp: any) => {
        return resp.data;
      }
    });
  }
  downloadFile(id: any){
    if (this.roleService.hasPermission(this.allPermissions.BANK_STATEMENT_EDIT) || this.roleService.hasAnyRole([this.allRoles.CREDIT_TEAM_ADMIN, this.allRoles.SUPER_ADMIN, this.allRoles.COMPANY_ADMIN_ACCESS])) {
      this.shieldService.downloadSingleUploadDocument(id).subscribe({
        next:(resp:any)=>{
          this.viewFileInNewTab(resp);
        }
      });
    }
    else{
      this.toasterService.error('You do not have permission to download this file.');
    }
  }
  viewFileInNewTab(resp: any) {
    this.csvService.openDocumentNewTab(resp);
  }
}
