import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-inprincipal',
  templateUrl: './inprincipal.component.html',
  styleUrls: ['./inprincipal.component.scss'],
})
export class InprincipalComponent implements OnInit {

  laneTaskId!: string;
  filteredConditions: Array<any> = [];
  selectedConditionIndex: number | null = null;
  selectedCondition: any = null;
  chatHistory: Array<any> = [];
  remarkText: string = '';
  overallComment: string = '';
  conditionsList: Array<any> = [];

  deviationsList: Array<{ deviation: string; mitigant: string }> = [];
  approvedVendorsList: Array<{ vendorName: string; address: string }> = [];
  keyCustomersSuppliers: Array<{ serialNo: string; customerName: string; amount: any; }> = [];

  constructor(private route: ActivatedRoute, private shieldService: ShieldApiService) { }

  formFields = [
    { label: 'Borrower', value: 'John Doe', type: 'text', new: false },
    { label: 'Proposed facility', value: 'Option 1', type: 'dropdown', options: ['Option 1', 'Option 2', 'Option 3'], new: false },
    { label: 'Facility Tenure', value: '12 months', type: 'text', new: false },
    { label: 'Type of Instrument', value: 'Option 2', type: 'dropdown', options: ['Option 1', 'Option 2', 'Option 3'], new: false },
    { label: 'Instrument Validity', value: 'Option 1', type: 'dropdown', options: ['Option 1', 'Option 2', 'Option 3'], new: false },
    { label: 'Tranche Tenure', value: 'Option 1', type: 'dropdown', options: ['Option 1', 'Option 2', 'Option 3'], new: false },
    { label: 'Label Text', value: 'Option 1', type: 'dropdown', options: ['Option 1', 'Option 2', 'Option 3'], new: false },
  ];


  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.laneTaskId = params['id'];
      this.fetchConditions();
    });
  }

  fetchConditions(): void {
    this.shieldService.fetchConditionsData(this.laneTaskId).subscribe({
      next: (response: any) => {
        const apiResponse = response?.data?.conditions;
        if (apiResponse) {
          this.conditionsList = Object.entries(apiResponse).map(([text, value]) => ({
            text,
            value: Boolean(value),
            // mandatory: value?.mandatory || false,
            // accepted: value?.accepted || false,
            // remark: value?.remark || '',
            // team: value?.team || ''
          }));
          this.filteredConditions = [...this.conditionsList];
          if (this.filteredConditions.length > 0) {
            this.selectedConditionIndex = 0; // Select the first condition
            this.selectCondition(this.selectedConditionIndex); // Fetch chat history for the first condition
          }
        }
      },
      error: (error) => {
        console.error('Error fetching conditions:', error);
      },
    });
  }

  onSave(): void {
    console.log('Captured Form Data:');

    // Use the formFields array as the source of truth
    const formData = this.formFields.map((field) => ({
      label: field.label,
      value: field.value,
    }));

    console.log('Captured Form Data:', formData);

    // Prepare the payload with form data and deviations
    const payload = {
      overallComment: this.overallComment,
      formData: formData,
      deviations: this.deviationsList, // Include deviationsList
      vendorData: this.approvedVendorsList,
    };

    console.log('Save Payload:', payload);

    // Send the payload to the API
    // this.sendDataToApi(payload);
  }

  submitRequest(): void {
    if (!this.remarkText.trim()) {
      alert('Please enter a remark before submitting.');
      return;
    }

    console.log("remarktext: ", this.remarkText)

    // this.shieldService.submitRequest(this.remarkText).subscribe({
    //   next: () => {
    //     alert('Request submitted successfully!');
    //     this.remarkText = ''; // Clear the input field
    //   },
    //   error: (error) => {
    //     console.error('Error submitting request:', error);
    //   },
    // });
  }

  // Method to add a new row
  addDeviationField(value: any): void {
    if (value === 'Deviation') {
      this.deviationsList.push({ deviation: '', mitigant: '' });
    } else if (value === 'KeyCustomers') {
      this.keyCustomersSuppliers.push({ serialNo: '', customerName: '', amount: '' });
    } else if (value === 'vendor') {
      this.approvedVendorsList.push({ vendorName: '', address: '' });
    }
  }

  // Method to remove a specific row
  removeDeviationField(type: string, index: number): void {
    if (type === 'deviation') {
      this.deviationsList.splice(index, 1);
    } else if (type === 'vendor') {
      this.approvedVendorsList.splice(index, 1);
    } else if (type === 'KeyCustomers') {
      this.keyCustomersSuppliers.splice(index, 1);
    }
  }

  selectCondition(index: number): void {
    this.selectedConditionIndex = index;
    this.selectedCondition = this.conditionsList[index];
    console.log("selected condition id: ", this.selectedConditionIndex, this.selectedCondition)

    // Fetch chat history for the selected condition
    // this.shieldService.getChatHistory(this.selectedCondition.text).subscribe({
    //   next: (response: any) => {
    //     this.chatHistory = response?.data || [];
    //   },
    //   error: (error) => {
    //     console.error('Error fetching chat history:', error);
    //   },
    // });
  }

  onSearch(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value;
    this.filteredConditions = this.conditionsList.filter((condition) =>
      condition.text.toLowerCase().includes(query.toLowerCase())
    );
    if (this.filteredConditions.length > 0) {
      this.selectCondition(0);
    } else {
      this.selectedCondition = null;
      this.chatHistory = [];
    }
  }

  // Add a new field dynamically
  addField(): void {
    this.formFields.push({
      label: 'New Field',  // Default label
      value: '',           // Default value
      type: 'text',        // Default type (text input)
      options: [],         // Default empty options for dropdowns
      new: true            // Mark the field as newly added
    });
  }

  // Remove a field dynamically
  removeField(index: number): void {
    this.formFields.splice(index, 1);
  }

}