<section class="finalCamWraper">

    <section class="inprincipalWrap">
        <div class="inPrincHead">
            <span class="inPTxt">Inprincipal</span>
            <div class="ctaBlock">
                <!-- <button class="commentCta mr-15" [matMenuTriggerFor]="menu"><i
                        class="ri-message-3-line"></i></button>
                <mat-menu #menu="matMenu" class="camChatHistoryWrap">
                    <section class="camChatHistoryInner">
                        <span class="headWrap">
                            <span class="titleTxt">Chat History</span>
                            <i class="ri-close-circle-fill"></i>
                        </span>
                        <section class="remarkChatBlockWrap">
                            <span class="teamTxtHead">Credit Team, 10/Oct 16:22</span>
                            <div class="descpTxtBlkWrap">
                                <h4>Remark</h4>
                                <p>An intimation request will be sent to primary banker/working
                                    capital banker for the proposed facility (for cases with borrower exposure upto
                                    Rs
                                    1.5cr)</p>
                            </div>
                        </section>
                        <section class="remarkChatBlockWrap activeRemark">
                            <span class="teamTxtHead">Sales Team, 10/Oct 16:22</span>
                            <div class="descpTxtBlkWrap">
                                <h4>Remark</h4>
                                <p>An intimation request will be sent to primary banker/working
                                    capital banker for the proposed facility (for cases with borrower exposure upto
                                    Rs
                                    1.5cr)</p>
                            </div>
                        </section>
                        <section class="remarkChatBlockWrap activeRemark">
                            <span class="teamTxtHead">Sales Team, 10/Oct 16:22</span>
                            <div class="descpTxtBlkWrap">
                                <h4>Remark</h4>
                                <p>An intimation request will be sent to primary banker/working
                                    capital banker for the proposed facility (for cases with borrower exposure upto
                                    Rs
                                    1.5cr)</p>
                            </div>
                        </section>
                        <div class="chatCtas mt-15 mb-10">
                            <button class="rReqCta mr-10">Raise Request</button>
                            <button class="acceptCta">Accept</button>
                        </div>

                        <div class="secuBottomBlock">
                            <div class="remark-input-container">
                                <i class="ri-attachment-2"></i>
                                <input type="text" class="remark-input"
                                    placeholder="Type your remark/comment here.." />
                                <button class="submit-button">Submit</button>
                            </div>
                        </div>



                    </section>
                </mat-menu> -->
                <button class="saveCta mr-15" (click)="onSave()"><i class="ri-save-2-fill"></i>Save</button>
                <button class="dUFCta mr-10">
                    <i class="ri-download-2-line"></i>
                    Download Uploaded File
                </button>
            </div>
        </div>
        <div class="inPrincFromWrap">
            <h3>
                <span>Basic</span>
                <button class="addMoreFieldsCta" (click)="addField()">
                    <i class="ri-file-add-line"></i>
                    Add More Field
                </button>
            </h3>

            <form id="editableForm">
                <div class="inPHFControlWrap">
                    <div class="w32" *ngFor="let field of formFields; let i = index">
                        <div class="input-group">
                            <mat-label contenteditable="true" [attr.data-index]="i">{{ field.label }}</mat-label>
                            <section class="dynamicGenrInputField">
                                <!-- Input Field -->
                                <mat-form-field class="mat-input" appearance="outline">
                                    <input *ngIf="field.type === 'text'" matInput placeholder="Enter value"
                                        [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}" />

                                    <!-- Dropdown Field -->
                                    <mat-select *ngIf="field.type === 'dropdown'" placeholder="Select value"
                                        [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let option of field.options" [value]="option">
                                            {{ option }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <button *ngIf="field.new" class="deleteFieldCta ml-5" (click)="removeField(i)"
                                    type="button">
                                    <i class="ri-delete-bin-line"></i>
                                </button>
                            </section>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <section class="securityConventWrap">
            <h3>Security</h3>
            <section class="securityConventInner">
                <div class="securityLftBlock">
                    <div class="securityLftCont">
                        <div class="secuHeader">Conditions</div>
                        <div class="search-bar">
                            <i class="ri-search-line"></i>
                            <input type="text" placeholder="Search" (input)="onSearch($event)" />
                        </div>
                        <div class="conditionListWrap">
                            <ul class="conditions-list">

                                <li class="condition-item" *ngFor="let condition of filteredConditions; let i = index"
                                    [class.active]="i === selectedConditionIndex" (click)="selectCondition(i)">
                                    <span class="tagsWrap">
                                        <span class="tags mr-5" *ngIf="condition.mandatory">Mandatory</span>
                                        <span class="tagsAccept" *ngIf="condition.accepted">Accepted</span>
                                    </span>
                                    <span class="condition-text">{{ i + 1 }}. {{ condition.text }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCondition" class="securityRhtBlock">
                    <div class="secuTopBlock">
                        <div class="descpTxtBlkWrap">
                            <h4>Descriptions</h4>
                            <p>{{ selectedCondition.text }}</p>
                        </div>
                        <span class="teamName">Credit Team, 10/Oct 16:22</span>
                        <div class="descpTxtBlkWrap remarkTxtBlk">
                            <h4>Remark</h4>
                            <p>{{ selectedCondition.remark }}</p>
                        </div>

                        <!-- Chat Section -->
                        <section class="chatRhtBlock">
                            <div *ngFor="let chat of chatHistory" class="innerChatBlk">
                                <span class="teamName">{{ chat.team }}, {{ chat.timestamp }}</span>
                                <div class="descpTxtBlkWrap remarkTxtBlk">
                                    <h4>Remark</h4>
                                    <p>{{ chat.text }}</p>
                                </div>
                            </div>
                        </section>

                        <div class="chatCtas">
                            <button class="rReqCta mr-10">Raise Request</button>
                            <button class="acceptCta">Accept</button>
                        </div>
                    </div>
                    <div class="secuBottomBlock">
                        <div class="remark-input-container">
                            <i class="ri-attachment-2"></i>
                            <input type="text" class="remark-input" placeholder="Type your remark/comment here.."
                                [(ngModel)]="remarkText" />
                            <button class="submit-button" (click)="submitRequest()">Submit Request</button>
                        </div>
                    </div>

                    <!--            <div class="comment-section">
                         <div class="comment credit-team">
                            <div class="comment-header">Credit Team, 10/Oct 16:22</div>
                            <div class="remark">
                                An intimation request will be sent to primary banker/working capital banker for the proposed
                                facility (for cases with borrower exposure up to Rs. 1.5cr)
                            </div>
                        </div>
    
    
                        <div class="comment sales-team">
                            <div class="comment-header">Sales Team, 10/Oct 16:22</div>
                            <div class="remark">
                                An intimation request will be sent to primary banker/working capital banker for the proposed
                                facility (for cases with borrower exposure up to Rs. 1.5cr)
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>
        </section>

        <section class="deviationListWrap">
            <h2>
                <span>Deviation</span>
                <button class="addMoreFieldsCta" (click)="addDeviationField('Deviation')">
                    <i class="ri-file-add-line"></i>
                    Add More Field
                </button>
            </h2>
            <table class="deviation-table deviationTableWrap">
                <thead>
                    <tr>
                        <th>Deviation</th>
                        <th>Mitigant</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of deviationsList; let i = index">
                        <td>
                            <input type="text" placeholder="Enter Deviation" [(ngModel)]="item.deviation" />
                        </td>
                        <td>
                            <input type="text" placeholder="Enter Mitigant" [(ngModel)]="item.mitigant" />
                        </td>
                        <td>
                            <!-- <button (click)="removeDeviationField('Deviation', i)">
                                <i class="ri-delete-bin-line"></i> -->

                            <button class="removetableRowCta" (click)="removeDeviationField('Deviation', i)">
                                <i class="ri-delete-bin-line mr-5"></i>
                                Remove
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>

        <section class="deviationListWrap">
            <h2>
                <span>Approved Vendors</span>
                <button class="addMoreFieldsCta" (click)="addDeviationField('vendor')">
                    <i class="ri-file-add-line"></i>
                    Add More Field
                </button>
            </h2>
            <table class="deviation-table ">
                <thead>
                    <tr>
                        <th>Vendors Name</th>
                        <th>Address</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of approvedVendorsList; let i = index">
                        <td>
                            <input type="text" placeholder="Enter Vendor Name" [(ngModel)]="item.vendorName" />
                        </td>
                        <td>
                            <input type="text" placeholder="Enter Address" [(ngModel)]="item.address" />
                        </td>
                        <td>
                            <button class="removetableRowCta" (click)="removeDeviationField('vendor', i)">
                                <i class="ri-delete-bin-line mr-5"></i>
                                Remove
                            </button>
                        </td>
                    </tr>

                    <!-- <tr>
                        <td>Bajaj Infra Pvt Ltd</td>
                        <td>C-81B, C Block, Sector 8, Noida, Uttar Pradesh 201301</td>
                    </tr> -->
                </tbody>
            </table>
        </section>


        <section class="deviationListWrap">
            <h2>Overall Comment</h2>
            <section class="commentSecTextAreaWrap">
                <label>Comment</label>
                <textarea [(ngModel)]="overallComment"
                    col="5">Acktron Security System Private Limited (ASSPL) is Delhi based company engaged in Security Industry. It It provides end to end solutions for security services for clients starting from basic consultancy, provision of equipment to complete project execution. Its main solutions are Security (CCTV, access control systems and surveillance systems) and Fire alarm systems. Apart from this, it is also involved in Building automation systems projects. 70% revenue is through repeat customers. The project tenure typically ranges between 6 months to 4 years in case large projects. The clients are Pan-India. For these projects, warranty period is 1 year post which AMC contract may be drawn up ranging between 1 year to 3 years. Some of the key clients of ASSPL are American Express, Delhi International Airport, GAIL India, E&Y and Maruti Suzuki among others. Typically, in any project, around 70% payment is received within 45-60 days of delivery, 15% on installation and the rest is usually kept on hold by the clients up till the end of warranty period. The usage of this PID facility will be to fund an order from Spectra Technovision (India) Private Limited for access control systems which will be ultimately used in a project owned by Bharti Realty.</textarea>
            </section>
        </section>
    </section>

</section>