import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr';
import { CsvExportService } from 'src/app/service/csv-export.service';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { Permission } from 'src/app/enum/permission.enum'
import Roles from 'src/app/enum/roles.enum'
import { RoleService } from 'src/app/service/role.service';

@Component({
  selector: 'app-file-dialouge',
  templateUrl: './file-dialouge.component.html',
  styleUrls: ['./file-dialouge.component.scss']
})
export class FileDialougeComponent implements OnInit {
  fileData:any[];
	allPermissions:any=Permission
	allRoles:any=Roles
  constructor(
    public dialogRef: MatDialogRef<FileDialougeComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,public shieldService: ShieldApiService,public toasterService: ToastrService,public roleService:RoleService,
    private csvService: CsvExportService
  ) {}
  ngOnInit(): void {
    this.fetchData();
  }
  fetchData(){    
    this.shieldService.showAllFiles(this.data.dataType,this.data.laneTaskId,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.fileData = resp.data
        // this.shieldService.hasAccess.subscribe(access=>{
        //   this.hasAccess = access
        // })
      }
    })
  }
  removeFile(id: number) {
    this.shieldService.deleteFile(this.data.dataType,id,this.data.laneTaskId,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.toasterService.success("File Deleted Sucessfully!");
      },
      complete:()=>this.fetchData()
    })
  }

  changeFile(id: number) {
    // Trigger file input click to change the file
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile,id);
    };
    fileInput.click();
  }
  reUpload(file:any,id:any){
    this.shieldService.reUploadFile(this.data.laneTaskId,this.data.dataType,file,id,this.data.id?this.data.id:null).subscribe({
      next:(resp:any)=>{
        this.toasterService.success("File Uploded SuccessFull!");
      },
      complete:()=>this.fetchData()
    })
  }
  downloadFile(id:any){
    this.shieldService.downloadSingleUploadDocument(id).subscribe({
      next:(resp:any)=>{
        this.downloadSheetCommonMethod(resp);
      }
    })
  }

  openFileInNewTab(id: any) {
    this.shieldService.downloadSingleUploadDocument(id).subscribe({
      next: (resp: any) => {
        this.viewFileInNewTab(resp);
      },
    })
  }

  viewFileInNewTab(resp: any) {
    this.csvService.openDocumentNewTab(resp);
  }

  resultDownload:any;
  downloadSheetCommonMethod(res:any) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }
}