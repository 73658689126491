<section class="finalCamWraper">

    <section class="finalCamBlock">

        <h1>
            <span>Financial Details</span>
            <button class="saveCta mr-15" (click)="onSave()">
                <i class="ri-save-2-fill mr-5"></i>Save
            </button>
            <button class="saveCta mr-15" (click)="onSave()">
                <i class="ri-save-2-fill mr-5"></i>Submit Summary of Borrower
            </button>
        </h1>

        <section class="borrowerTxtAreaWrap">
            <h1>Financial Summary</h1>
            <textarea [(ngModel)]="financialSummary" placeholder="Enter Financial Summary"></textarea>
        </section>

        <table class="financial-table mt-15">
            <thead>
                <tr>
                    <th rowspan="2">Figure in Rs. Crore</th>
                    <th colspan="2" class="subheader">Audited</th>
                </tr>
                <tr>
                    <th>FY 23</th>
                    <th>FY 24</th>
                </tr>
            </thead>
            <tbody>
                <tr class="section-header">
                    <td>Operating Income (OI)</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Growth in OI (%)</td>
                    <td>36</td>
                    <td>42</td>
                </tr>
                <tr>
                    <td>EBITDA</td>
                    <td>56</td>
                    <td>68</td>
                </tr>
                <tr>
                    <td>EBITDA (%)</td>
                    <td>10</td>
                    <td>12</td>
                </tr>
                <tr>
                    <td>Non-operating income + Exceptional Item, as applicable</td>
                    <td>30</td>
                    <td>31</td>
                </tr>
                <tr>
                    <td>Net PAT</td>
                    <td>27</td>
                    <td>32</td>
                </tr>
                <tr>
                    <td>Net Cash Accruals (NCA)</td>
                    <td>27</td>
                    <td>32</td>
                </tr>
                <tr>
                    <td>Dividends</td>
                    <td>27</td>
                    <td>32</td>
                </tr>
                <tr class="section-header">
                    <td>Profitability</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>OPBDIT/Interest (X)</td>
                    <td>6.55x</td>
                    <td>6.85x</td>
                </tr>
                <tr>
                    <td>NCA/TD (%)</td>
                    <td>3.2</td>
                    <td>3.7</td>
                </tr>
                <tr>
                    <td>Total Debt/EBITDA</td>
                    <td>4.3</td>
                    <td>4.7</td>
                </tr>
                <tr>
                    <td>Long-term Debt</td>
                    <td>3.4</td>
                    <td>4.4</td>
                </tr>
                <tr>
                    <td>Trade Payables</td>
                    <td>2.3</td>
                    <td>2.5</td>
                </tr>
                <tr>
                    <td>Short-term Debt</td>
                    <td>1.7</td>
                    <td>2.1</td>
                </tr>
                <tr class="section-header">
                    <td>Liquidity and Coverage</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Total Cash Cover</td>
                    <td>2.7</td>
                    <td>3.6</td>
                </tr>
                <tr>
                    <td>Gross Cash Accruals</td>
                    <td>2.7</td>
                    <td>3.6</td>
                </tr>
                <tr>
                    <td>Interest Coverage</td>
                    <td>2.7</td>
                    <td>3.6</td>
                </tr>
                <tr>
                    <td>TL/ATNW (%)</td>
                    <td>53.8%</td>
                    <td>61.3%</td>
                </tr>
                <tr class="section-header">
                    <td>Working Capital/Utilization</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Inventory Days</td>
                    <td>108</td>
                    <td>121</td>
                </tr>
                <tr>
                    <td>Receivable Days</td>
                    <td>67</td>
                    <td>85</td>
                </tr>
                <tr>
                    <td>Creditor Days</td>
                    <td>(67)</td>
                    <td>(90)</td>
                </tr>
                <tr>
                    <td>Net Working Capital</td>
                    <td>108</td>
                    <td>121</td>
                </tr>
                <tr>
                    <td>Current Ratio</td>
                    <td>1.02</td>
                    <td>1.07</td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <h1>GST Summary</h1>
        <ul class="bulletCamList">
            <li>• GSTR3B for 5MFY25 stands at INR 20.4 cr.</li>
            <li>• There has been a notable increase in the revenue as per GST filings. 5MFY25 revenue
                has
                increased by 61% from INR 12.6 cr in 5MFY24.</li>
            <li>• Similarly, Q1FY25 has increased by upwards of 100% from INR 5.5 cr in Q1FY24 to INR
                11.6 cr in
                Q1FY25.</li>
            <li>• The projections for FY25 is around INR 45 cr</li>
        </ul>
    </section>

    <section class="finalCamBlock">
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Particulars</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of particulars">
                    <td>{{ item.title }}</td>
                    <td>
                        <textarea [(ngModel)]="item.comments" placeholder="Enter comments"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <h1>Brief snapshot of Vendors</h1>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of briefSnapshotOfVendors">
                    <td>{{ item.title }}</td>
                    <td>
                        <textarea [(ngModel)]="item.comments" placeholder="Enter comments"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="deviationListWrap">
        <h2>
            <span>Analysis of business done with the top vendors as per ledger</span>
            <button class="addMoreFieldsCta" (click)="addDeviationField('AnalysisOfBusiness')">
                <i class="ri-file-add-line"></i>
                Add More Field
            </button>
        </h2>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Vendors Name</th>
                    <th>Purchases (Apr’24-Sep’24) (Rs in cr)</th>
                    <th>Dependency</th>
                    <th>Banking Apr’24- Sep'24 (Rs in cr)</th>
                    <th>Credit Period</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of analysisOfBusiness; let i = index">
                    <td>
                        <input type="text" placeholder="Enter Vendors Name" [(ngModel)]="item.vendorName" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Share" [(ngModel)]="item.purchases" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Dependency" [(ngModel)]="item.dependency" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Banking" [(ngModel)]="item.banking" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Credit Period" [(ngModel)]="item.creditPeriod" />
                    </td>
                    <td>
                        <button (click)="removeDeviationField('AnalysisOfBusiness', i)">
                            <i class="ri-delete-bin-line"></i>
                            Remove
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <h1>Banking Analysis</h1>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Parameters</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of bankingAnalysis">
                    <td>{{ item.title }}</td>
                    <td>
                        <textarea [(ngModel)]="item.comments" placeholder="Enter comments"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="deviationListWrap">
        <h2>
            <span>Existing Loan Details including WC</span>
            <button class="addMoreFieldsCta" (click)="addDeviationField('ExistingLoanDetails')">
                <i class="ri-file-add-line"></i>
                Add More Field
            </button>
        </h2>
        <table class="deviation-table">
            <thead>
                <tr>
                    <th>Sl.No </th>
                    <th>Institution Name</th>
                    <th>Sanc. Date</th>
                    <th>Nature of Facility (CC/TL/WCDL/NFB)</th>
                    <th>Sanctioned Limits (Rs)</th>
                    <th>Outstanding as on Latest Month End</th>
                    <th>Commen Monthly Repaymentts</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of existingLoanDetails; let i = index">
                    <td>
                        <p>{{i+1}}</p>
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Institution Name" [(ngModel)]="item.institutionName" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Share" [(ngModel)]="item.sancDate" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Dependency" [(ngModel)]="item.natureOfFacility" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Banking" [(ngModel)]="item.sanctionedLimits" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Credit Period" [(ngModel)]="item.outstanding" />
                    </td>
                    <td>
                        <input type="text" placeholder="Enter Credit Period" [(ngModel)]="item.CommenMonthly" />
                    </td>
                    <td>
                        <button (click)="removeDeviationField('ExistingLoanDetails', i)">
                            <i class="ri-delete-bin-line"></i>
                            Remove
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <section class="finalCamBlock">
        <section class="borrowerTxtAreaWrap">
            <h1>Debt Sizing</h1>
            <textarea [(ngModel)]="debtSizing"></textarea>
        </section>
    </section>

</section>