import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RoleService {

  accessRolesPermission:any;

	constructor() {}

  hasPermission(permission:string){
    return this.accessRolesPermission.permissions.includes(permission);
  }

  hasRole(role:string){
    return this.accessRolesPermission.roles.includes(role);
  }

  hasAnyRole(roleArray:any[]){
    let hasRole:boolean = false;

    for(let i=0;i<roleArray.length;i++){
      if(this.accessRolesPermission.roles.includes(roleArray[i])){
        hasRole = true;
        break;
      }
    }
    return hasRole;
  }
}
