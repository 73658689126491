import { Component, Input, OnInit } from '@angular/core';
// import { SendToUserComponent } from './send-to-user/send-to-user.component';
import * as Highcharts from "highcharts";
import { MatDialog } from '@angular/material/dialog';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ToastrService } from 'ngx-toastr';
import { SendToUserComponent } from './send-to-user/send-to-user.component';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/service/role.service'
import { Permission } from 'src/app/enum/permission.enum'
import Roles from 'src/app/enum/roles.enum'
@Component({
  selector: 'app-gst-automate',
  templateUrl: './gst-automate.component.html',
  styleUrls: ['./gst-automate.component.scss']
})
export class GstAutomateComponent implements OnInit {
  panelOpenState: any = [];
  allComplete: boolean = false;
  @Input() gstNumber: any;
  @Input() laneDetails: any
  gstList: any = [];
  chart1: any;
  chart2: any;
  chart3: any;
  chart4: any;
  // Entry Profile Table
  entryProfiedataSource: Array<any> = [];
  displayColumns2: Array<string> = ['AuthorisedRepresentative', 'GSTIN', 'GSTINStatus', 'State', 'BusinessActivities'];
  entityColumns: Array<string> = ['GSTIN', 'GSTINStatus', 'State', 'BusinessActivities', 'Address', 'AuthorisedRepresentative',];
  stateWiseColumns: Array<string> = ['stateCode', 'state', 'invoices', 'invoiceValue', 'tax'];

  panNumber: any = '';
  selectedGstArray: Set<number> = new Set();
  gstCopyBaseUrl: any = 'api/gst/report-verifier?gsts=';
  gstReport: any = [];
  // Annual Summary Table
  annualSummarydataSource: Array<any> = [];
  displayColumns3: Array<string> = ['AnnualSummary', 'GrossTurnover', 'NetTurnover', 'TotalInvoice', 'GrossPurchase', 'NetPurchase', 'ITCAvailable', 'TaxLiability', 'GrossPurchaseTwo', 'NetTurnoverTwo'];

  // Alerts Table
  alertsdataSource: Array<any> = [];
  displayColumns4: Array<string> = ['Alert', 'DateOfLastUpdation', 'Severity', 'Status'];

  // Compliance Table
  compliancedataSource: Array<any> = [];
  displayColumns5: Array<string> = ['PAN', 'Month', 'DateOfFiling', 'DueDate', 'DelayDays'];
  displayColumns6: Array<string> = ['PAN', 'Month', 'DateOfFiling', 'DueDate', 'DelayDays', 'period'];
  topUsers: Array<string> = ['financialYear', 'pan', 'name', 'alerts', 'taxableValue', 'invoiceValue', 'totalInvoice', 'share'];
  spans: any = [];
  customersSpans: any = [];
  suppliersSpans: any = [];
  isAllSelected: boolean = false;
  selectedTabIndex: number = 0;
  isSingleGstReport: boolean = false;
  isLoading: boolean = false;
  laneTaskId: any;
  allPermissions: any = Permission;
  allRoles: any = Roles;

  constructor(
    private shieldApi: ShieldApiService,
    private dialog: MatDialog,
    public toasterService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService
  ) {
  }

  ngOnInit() {
    // this.gstReport = this.consolidatedReportResponce.data;
    console.log(this.gstReport);
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x['id'];
      this.shieldApi.setLaneTaskId(x['id']);
    });
    this.fetchdata(this.laneTaskId);
  }

  fetchdata(x: any) {
    if (x !== null && x !== "undefined") {
      this.shieldApi.fetchData(x).subscribe({
        next: (resp: any) => {
          this.shieldApi.setLaneData(resp.data);
          this.laneDetails = resp.data.applicationPreview.leadDetails;
          let businessProduct = resp.data.applicationPreview.leadDetails.businessProduct;
          this.panNumber = resp.data.applicationPreview.leadDetails.companyPan;
          // this.panNumber = "AAECS0561R";
          if (this.panNumber) {
            this.getAllGstDetails(this.panNumber);
          }
          if(businessProduct == 'SID' || businessProduct == 'DEALER_FINANCE'){
          this.shieldApi.setBuyerValue('Buyer');
          }else{
            this.shieldApi.setBuyerValue('Supplier');
          }
        },
      });
    }
  }

  cacheSpan(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.compliance.length;) {
      let currentValue = accessor(this.gstReport.compliance[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.compliance.length; j++) {
        if (currentValue != accessor(this.gstReport.compliance[j])) {
          break;
        }
        count++;
      }
      if (!this.spans[i]) {
        this.spans[i] = {};
      }
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col: any, index: any) {
    return this.spans[index] && this.spans[index][col];
  }


  cacheSpanCustomer(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.topCustomers.length;) {
      let currentValue = accessor(this.gstReport.topCustomers[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.topCustomers.length; j++) {
        if (currentValue != accessor(this.gstReport.topCustomers[j])) {
          break;
        }
        count++;
      }
      if (!this.customersSpans[i]) {
        this.customersSpans[i] = {};
      }
      this.customersSpans[i][key] = count;
      i += count;
    }
  }

  getRowSpanCustomer(col: any, index: any) {
    return this.customersSpans[index] && this.customersSpans[index][col];
  }

  cacheSpanSupplier(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.topSuppliers.length;) {
      let currentValue = accessor(this.gstReport.topSuppliers[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.topSuppliers.length; j++) {
        if (currentValue != accessor(this.gstReport.topSuppliers[j])) {
          break;
        }
        count++;
      }
      if (!this.suppliersSpans[i]) {
        this.suppliersSpans[i] = {};
      }
      this.suppliersSpans[i][key] = count;
      i += count;
    }
  }

  selectAllGst(event: any) {
    if (event.checked) {
      this.isAllSelected = true
      this.gstList.forEach((gst: any) => {
        gst.isSelected = true;
        this.selectedGstNumber(event.checked, gst);
      });
    }
    else {
      this.isAllSelected = false;
      this.gstList.forEach((gst: any) => {
        gst.isSelected = false;
        this.selectedGstNumber(event.checked, gst);
      });
    }
  }

  selectedGstNumber(event: Event, currentGst: any) {
    if (event) {
      this.selectedGstArray.add(currentGst);
    }
    else {
      this.selectedGstArray.delete(currentGst);
      this.isAllSelected = false
    }
  }

  getRowSpanSupplier(col: any, index: any) {
    return this.suppliersSpans[index] && this.suppliersSpans[index][col];
  }

  // GSTR1 vs 3b Table
  gstrdataSource: Array<any> = [];
  displayColumns8: Array<string> = ['FinancialYear', 'ReturnedPeriod', '1InvoiceValue', '1TaxableValue', '3bInvoiceValue', '3bTaxableValue'];

  displayColumns7: string[] = ['Heading', 'Blank', '1AverageInvoice', '1AverageTaxable', 'AverageInvoice', 'AverageTaxable'];

  spansGSTRMap = new Map<string, any[]>(); // Map to store the spans for each column

  createCacheSpanGSTR() {
    if (this.gstReport && this.gstReport.gstr1Vs3B && Array.isArray(this.gstReport.gstr1Vs3B)) {
      for (let element of this.gstReport.gstr1Vs3B) {
        let financialYear = this.getFinancialYearDate(element.period);
        let value = this.map.get(financialYear) || [];
        value.push(element);
        this.map.set(financialYear, value);
      }
    }
    let keys = Array.from(this.map.keys());
    for (let key of keys) {
      const values = this.map.get(key);
      let spansGSTR = this.spansGSTRMap.get(key) || [];
      this.cacheSpanGSTR(key, key, values, spansGSTR);
      this.spansGSTRMap.set(key, spansGSTR);
    }
  }

  cacheSpanGSTR(key: any, value: any, values: any, spansGSTR: any) {
    for (let i = 0; i < values.length;) {
      let currentValue = value;
      let count = 1;
      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < values.length; j++) {
        if (currentValue != value) {
          break;
        }
        count++;
      }
      if (!spansGSTR[i]) {
        spansGSTR[i] = {};
      }
      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      spansGSTR[i][key] = count;
      i += count;
    }
  }

  getRowSpanGSTR(col: any, index: any) {
    return this.spansGSTRMap.get(col) && this.spansGSTRMap.get(col)[index] && this.spansGSTRMap.get(col)[index][col];
  }

  calculateTotal(year: any, column: string, nestedObj: string): number {
    const values = this.map.get(year) || [];
    const total = values.reduce((acc: any, curr: any) => acc + parseFloat(curr[nestedObj][column]), 0);
    return Math.round(total * 100) / 100; // Round to 2 decimal places
  }


  calculateAverage(year: any, column: string, nestedObj: string): number {
    const values = this.map.get(year) || [];
    const total = values.reduce((acc: any, curr: any) => acc + parseFloat(curr[nestedObj][column]), 0);
    return Math.round((total / values.length) * 100) / 100;
  }

  async getinitialdata() {
    if (!this.panNumber) {
      this.panNumber = this.getPanNumberFromGST();
      console.log("line number 236", this.panNumber);
      return Promise.resolve(true);
    }
  }

  getPanNumberFromGST() {
    let panNumber = this.extractPANNumberFromGst(this.gstNumber)
    return panNumber;
  }

  getAllGstDetails(panNumber: any) {
    let obj = {
      pan: panNumber
    }
    this.shieldApi.getallGstDetails(obj).subscribe((res: any) => {
      if (res && res['statusCode'] == 200) {
        this.gstList = res['data'].filter((g: any) => g.authStatus == 'Active');
        if (!this.shouldDisbaleTabs) {
          this.selectedTabIndex = 1;
        }
      }
    });
  }

  // Function to extract PAN Number from a given gst string and return it
  extractPANNumberFromGst(gst: string) {
    gst = gst.toUpperCase();
    const panPattern = /[A-Z]{5}\d{4}[A-Z]{1}/g;
    const match = gst.match(panPattern);
    return match ? match[0] : null;
  }

  getChart1Categories() {
    let categories: Array<any> = [];
    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.turnoverAndPurchase) {
      let netPurchasePeriods = this.gstReport.summaryCharts.turnoverAndPurchase.netPurchase.map((element: any) => element.period);
      let netTurnoverPeriods = this.gstReport.summaryCharts.turnoverAndPurchase.netTurnover.map((element: any) => element.period);

      netPurchasePeriods.forEach((period: any) => categories.push(period));
      netTurnoverPeriods.forEach((period: any) => categories.push(period));
    }
    categories = Array.from(new Set(categories));
    categories = categories.sort()
    categories = categories.map(element => this.formatPeriod(element));
    return categories;
  }

  getChart1Data() {
    const seriesData: Highcharts.SeriesOptionsType[] = []; // Ensure it's typed as Highcharts.SeriesOptionsType[]

    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.turnoverAndPurchase) {
      let categories = this.getChart1Categories();
      categories = Array.from(new Set(categories)); // Ensure unique categories

      const map1 = new Map();
      const map2 = new Map();

      // Map Purchase Data
      this.gstReport.summaryCharts.turnoverAndPurchase.netPurchase.forEach((element: any) => {
        map1.set(this.formatPeriod(element.period), element.value);
      });

      // Map Turnover Data
      this.gstReport.summaryCharts.turnoverAndPurchase.netTurnover.forEach((element: any) => {
        map2.set(this.formatPeriod(element.period), element.value);
      });

      // Get values based on categories
      const netPurchaseValues = categories.map(element => map1.get(element) || 0);
      const netTurnoverValues = categories.map(element => map2.get(element) || 0);

      // Push Purchase series with 'type'
      seriesData.push({
        type: 'line', // Add type here to avoid type mismatch
        name: 'Purchase',
        data: netPurchaseValues
      });

      // Push Turnover series with 'type'
      seriesData.push({
        type: 'line', // Add type here as well
        name: 'Turnover',
        data: netTurnoverValues
      });
    }

    return seriesData;
  }


  /**
   *
   * @param inputDate | Input date string in the format 'dd-mm-yyyy'
   * @returns | Formatted date string in the format 'dd MMM yyyy'
   */
  formatDate1(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into date, month and year
    const splitDate = inputDate.split('-');
    const date = splitDate[0];
    const month = parseInt(splitDate[1]);
    const year = splitDate[2];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${date} ${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  /**
  *
  * @param inputDate | Input date string in the format 'yyyy-mm-dd'
  * @returns | Formatted date string in the format 'dd MMM yyyy'
  */
  formatDate2(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into date, month and year
    const splitDate = inputDate.split('-');
    const date = splitDate[2];
    const month = parseInt(splitDate[1]);
    const year = splitDate[0];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${date} ${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  formatPeriod(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into month and year
    const month = parseInt(inputDate.slice(0, 2));
    const year = inputDate.slice(2);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  createChart1() {
    if (this.chart1) {
      this.chart1.destroy();
    }
    this.chart1 = Highcharts.chart('chart1', {
      title: {
        text: 'Turnover vs Purchases',
        align: 'left'
      },
      chart: {
        type: 'line' // Change chart type to 'line'
      },
      colors: ['#27C4EA', '#FFA500'],
      xAxis: {
        categories: this.getChart1Categories() || [] // Add categories for x-axis
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart1Data(),
      exporting: {
        enabled: false // This will disable the exporting options entirely
      }
    });
  }

  getChart2Categories() {
    const stateNames = new Set();
    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.stateWiseSales) {
      const data = this.gstReport.summaryCharts.stateWiseSales;
      const periods = Object.keys(data).sort();
      periods.forEach(period => {
        data[period].forEach((item: any) => stateNames.add(item.stateName));
      });
      return Array.from(stateNames).sort();
    }
    return [];
  }

  getChart2Data() {
    const seriesData: Array<any> = [];

    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.stateWiseSales) {
      const data = this.gstReport.summaryCharts.stateWiseSales;
      const periods = Object.keys(data).sort();
      const stateNames = this.getChart2Categories(); // Reuse the category function
      const map = new Map();
      periods.forEach(period => {
        const series = map.get(period) || [];
        const periodData = {
          name: this.getFinancialYearDate(period),
          data: [] as any[]
        };

        stateNames.forEach(stateName => {
          const stateData = data[period].find((item: any) => item.stateName === stateName);
          periodData.data.push(stateData ? stateData.value : 0);
        });
        seriesData.push(periodData);
      });
    }

    return seriesData;
  }

  createChart2() {
    if (this.chart2) {
      this.chart2.destroy();
    }

    this.chart2 = Highcharts.chart('chart2', {
      chart: {
        type: 'column', // Specify column chart
      },
      title: {
        text: 'State Wise Sales',
        align: 'left'
      },
      colors: ['#27C4EA', '#FFA500'],
      xAxis: {
        categories: this.getChart2Categories() as string[], // Ensure categories are strings
        title: {
          text: 'State'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart2Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  getChart3Categories() {
    let categories = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Customers) {
      categories = this.gstReport.summaryCharts.top10Customers.map((element: any) => element.name);
    }
    return categories;
  }

  getChart3Data() {
    let seriesData = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Customers) {
      const map = new Map();
      this.gstReport.summaryCharts.top10Customers.forEach((element: any) => {
        map.set(element.name, element.value);
      });
      const values = this.getChart3Categories().map((element: any) => map.get(element) || 0);
      seriesData.push({
        name: 'Top 10 Customers',
        data: values
      });
    }
    return seriesData;
  }

  createChart3() {
    if (this.chart3) {
      this.chart3.destroy();
    }

    this.chart3 = Highcharts.chart('chart3', {
      chart: {
        type: 'column', // Correct type for column chart
      },
      title: {
        text: 'Top 10 Customers',
        align: 'left'
      },
      colors: ['#27C4EA'],
      xAxis: {
        categories: this.getChart3Categories() as string[], // Ensure categories are strings
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart3Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  getChart4Categories() {
    let categories = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Suppliers) {
      categories = this.gstReport.summaryCharts.top10Suppliers.map((element: any) => element.name);
    }
    return categories;
  }

  getChart4Data() {
    let seriesData = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Suppliers) {
      const map = new Map();
      this.gstReport.summaryCharts.top10Suppliers.forEach((element: any) => {
        map.set(element.name, element.value);
      });
      const values = this.getChart4Categories().map((element: any) => map.get(element) || 0);
      seriesData.push({
        name: 'Top 10 Suppliers',
        data: values
      });
    }
    return seriesData;
  }

  createChart4() {
    if (this.chart4) {
      this.chart4.destroy();
    }

    this.chart4 = Highcharts.chart('chart4', {
      chart: {
        type: 'column', // Specify column chart
      },
      title: {
        text: 'Top 10 Suppliers',
        align: 'left'
      },
      colors: ['#FFA500'], // Specify custom colors for the chart
      xAxis: {
        categories: this.getChart4Categories() as string[], // Ensure categories are strings
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart4Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  get gstCopyUrl() {
    return this.shieldApi.gstBaseUrl + this.gstCopyBaseUrl + Array.from(this.selectedGstArray).map(x => (x as any).gstinId).join(',');
  }

  tabChanged(event: any) {
    //delete it
    // this.bindReportDataInEachTab(this.consolidatedReportResponce.data);
    if (!this.gstReport || this.gstReport.length == 0) {
      this.getConsolidateGstReport();
    }
  }

  getConsolidateGstReport() {
    const obj = {
      pan: this.panNumber
    }
    this.shieldApi.getGstReport(obj).subscribe((res: any) => {
      if (res && res['statusCode'] == 200 && res['data']) {
        this.bindReportDataInEachTab(res['data']);
      }
    }) 
  }

  // these variable  use for internal sorting
  mapTopCustomers: any;
  mapToSupplier: any;

  bindReportDataInEachTab(reportData: any): void {
    this.gstReport = reportData;
    if (this.gstReport && this.gstReport.alerts && Array.isArray(this.gstReport.alerts)) {
      this.gstReport.alerts = this.gstReport.alerts.sort((a: any, b: any) => { return b.status - a.status });
    }
    this.shieldApi.gstUrl = reportData['excelFile'];
    if (this.gstReport && this.gstReport.topCustomers && Array.isArray(this.gstReport.topCustomers)) {
      this.mapTopCustomers = new Map();
      for (let cus of this.gstReport.topCustomers) {
        const customers = this.mapTopCustomers.get(cus.period) || [];
        customers.push(cus);
        this.mapTopCustomers.set(cus.period, customers);
      }

      let keys = Array.from(this.mapTopCustomers.keys());
      for (let period of keys) {
        let customers = this.mapTopCustomers.get(period);
        if (customers && customers.length > 0) {
          customers = customers.sort((a: any, b: any) => {
            return (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase());
          });
          this.mapTopCustomers.set(period, customers);
        }
      }

      keys = keys.sort((a, b) => { return a > b ? 1 : -1 });
      let customers = []
      for (let period of keys) {
        let cus = this.mapTopCustomers.get(period);
        // destructure feature not suppot in this ts version so using this style for concat two arrays
        for (let c of cus) {
          customers.push(c)
        }
      }

      this.gstReport.topCustomers = customers;
      this.mapTopCustomers = null;
    }
    if (this.gstReport && this.gstReport.topSuppliers && Array.isArray(this.gstReport.topSuppliers)) {
      this.mapToSupplier = new Map();
      for (let sup of this.gstReport.topSuppliers) {
        const suppliers = this.mapToSupplier.get(sup.period) || [];
        suppliers.push(sup);
        this.mapToSupplier.set(sup.period, suppliers);
      }
      let keys = Array.from(this.mapToSupplier.keys());
      for (let period of keys) {
        let suppliers = this.mapToSupplier.get(period);
        if (suppliers && suppliers.length > 0) {
          suppliers = suppliers.sort((a: any, b: any) => {
            return (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase());
          });
          this.mapToSupplier.set(period, suppliers);
        }
      }
      keys = keys.sort((a, b) => { return a > b ? 1 : -1 });
      let suppliers = []
      for (let period of keys) {
        let cus = this.mapToSupplier.get(period);
        // destructure feature not suppot in this ts version so using this style for concat two arrays
        for (let c of cus) {
          suppliers.push(c)
        }
      }
      this.gstReport.topSuppliers = suppliers;
      this.mapToSupplier = null;
    }
    if (this.gstReport && this.gstReport.compliance && Array.isArray(this.gstReport.compliance)) {

      this.gstReport.compliance = this.gstReport.compliance.sort((a: any, b: any) => {
        return a.gstin > b.gstin ? 1 : -1
      })
    }
    this.createChart1();
    this.createChart2();
    this.createChart3();
    this.createChart4();
    this.cacheSpan('PAN', (d: any) => d.gstin);
    this.cacheSpanCustomer('FY', (d: any) => d.period);
    this.cacheSpanSupplier('FY', (d: any) => d.period);
    this.createCacheSpanGSTR();
  }

  copyToClipboard(value: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toasterService.success('Copied to clipboard');
  }

  getGSTPeriod(period: any) {
    let fromYear = period.split('-')[0].slice(2);
    let toYear = period.split('-')[1].slice(2);
    return `${fromYear}-${toYear}`
  }

  getFinancialYear(period: any) {
    return period ? period.substring(period.length - 2) : '';
  }

  getFinancialYearDate(dateString: any) {
    // Extract year and month from the input string
    const year = parseInt(dateString.slice(2));
    const month = parseInt(dateString.slice(0, 2));

    // Determine the financial year based on the month
    let financialYear;
    if (month >= 4) {
      // Financial year starts from April, so if the month is April or later, the financial year is the next year
      financialYear = `FY ${year + 1}`;
    } else {
      // Financial year starts from April, so if the month is before April, the financial year is the current year
      financialYear = `FY ${year}`;
    }
    return financialYear;
  }

  map = new Map();

  get getListOfFinancialyear() {
    this.map = new Map();
    let list = new Set();
    if (this.gstReport && this.gstReport.gstr1Vs3B && Array.isArray(this.gstReport.gstr1Vs3B)) {
      for (let element of this.gstReport.gstr1Vs3B) {
        let financialYear = this.getFinancialYearDate(element.period);
        let value = this.map.get(financialYear) || [];
        value.push(element);
        this.map.set(financialYear, value);
        list.add(financialYear);
      }
    }
    return Array.from(list).sort((a: any, b: any) => { return b.localeCompare(a) });
  }
  getGSTR1vs3BByFinancialYear(financialYear: any) {
    return this.map.get(financialYear) || [];
  }

  openSendToUser() {
    const dialogRef = this.dialog.open(SendToUserComponent, {
      width: '800px',
      height: '300px',
      panelClass: 'custom-dialog-container',
      data: { lead: this.laneDetails, url: this.gstCopyUrl }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    console.log('The dialog was closed');
    });
  }

  // if all gst otp is verified then only enable the tabs
  // so it will return false if all gst otp is verified
  get shouldDisbaleTabs() {
    for (let gst of this.gstList) {
      if (!gst.isOtpVerified) {
        return true;
      }
    }
    return false;
  }

  get isTabDisabled() {
    return !(this.isSingleGstReport || !this.shouldDisbaleTabs);
  }

  getGstReportRouteNextTab(gst: any) {
    let obj = {
      gst: gst.gstinId
    }
    this.isLoading = true;
    try {
      this.shieldApi.getSingleGstReport(obj).subscribe((res: any) => {
        if (res) {
          this.isSingleGstReport = true;
          this.selectedTabIndex = 1;
          setTimeout(() => {
            this.bindReportDataInEachTab(res['data']);
            this.isLoading = false;
          }, 1000);
        }
      });
    } catch (error) {
      console.error("error", error);
      this.isLoading = false;
    }
  }
  orgConsolidatedReport = {
    "message": "OK",
    "statusCode": 200,
    "data": {
      "summaryCharts": {
        "turnoverAndPurchase": {
          "netTurnover": [] as any[],
          "netPurchase": [] as any[]
        },
        "stateWiseSales": {
          "undefined": [] as any[]
        },
        "top10Customers": [] as any[],
        "top10Suppliers": [] as any[]
      },
      "entityProfile": {
        "pan": "",
        "vinatge": "",
        "constitution": "",
        "legalName": "",
        "gsts": [
          {
            "businessActivity": ""
          }
        ]
      },
      "annualSummary": [] as any[],
      "alerts": [] as any[],
      "compliance": [] as any[],
      "cyclicTransaction": [] as any[],
      "relatedPartySales": [] as any[],
      "relatedPartyPurchases": [] as any[],
      "gstr1Vs3B": [] as any[],
      "statewiseSales": [] as any[],
      "topCustomers": [] as any[],
      "topSuppliers": [] as any[],
      "excelFile": "",
      "pdfFile": ""
    }
  }
  consolidatedReportResponce = {
    "message": "OK",
    "statusCode": 200,
    "data": {
      "summaryCharts": {
        "turnoverAndPurchase": {
          "netTurnover": [
            {
              "period": "092023",
              "value": 53443693.18
            },
            {
              "period": "102023",
              "value": 36823511.75
            },
            {
              "period": "112023",
              "value": 34280249.03
            },
            {
              "period": "122023",
              "value": 27371211.57
            },
            {
              "period": "012024",
              "value": 37190885.57
            },
            {
              "period": "022024",
              "value": 37913685.870000005
            },
            {
              "period": "032024",
              "value": 75455816.72999999
            },
            {
              "period": "042024",
              "value": 44785912.59
            },
            {
              "period": "052024",
              "value": 40191298.39
            },
            {
              "period": "062024",
              "value": 82636962.73
            },
            {
              "period": "072024",
              "value": 45851751.49
            },
            {
              "period": "082024",
              "value": 53357710.8
            }
          ],
          "netPurchase": [
            {
              "period": "092023",
              "value": 34671892.99000001
            },
            {
              "period": "102023",
              "value": 29727174.919999994
            },
            {
              "period": "112023",
              "value": 24397233.439999998
            },
            {
              "period": "122023",
              "value": 19077007.62
            },
            {
              "period": "012024",
              "value": 19353307.28
            },
            {
              "period": "022024",
              "value": 24026771.669999998
            },
            {
              "period": "032024",
              "value": 71556092.08999999
            },
            {
              "period": "042024",
              "value": 22996048.479999997
            },
            {
              "period": "052024",
              "value": 69817183.37999998
            },
            {
              "period": "062024",
              "value": 44781629.760000005
            },
            {
              "period": "072024",
              "value": 50230553.809999995
            },
            {
              "period": "082024",
              "value": 35849906.089999996
            },
            {
              "period": "092022",
              "value": 41204737.33
            },
            {
              "period": "092022",
              "value": 35602188.19
            },
            {
              "period": "102022",
              "value": 41289449.86
            },
            {
              "period": "102022",
              "value": 38189156.99
            },
            {
              "period": "112022",
              "value": 49451199.029999994
            },
            {
              "period": "112022",
              "value": 23457709.37
            },
            {
              "period": "122022",
              "value": 41023545.62
            },
            {
              "period": "122022",
              "value": 24883072.66
            },
            {
              "period": "012023",
              "value": 49372844.4
            },
            {
              "period": "012023",
              "value": 26535397.519999996
            },
            {
              "period": "022023",
              "value": 37250072.43
            },
            {
              "period": "022023",
              "value": 87612530.63
            },
            {
              "period": "032023",
              "value": 67157997.16
            },
            {
              "period": "032023",
              "value": 50830004.42
            },
            {
              "period": "042023",
              "value": 43828891.77
            },
            {
              "period": "042023",
              "value": 37286842.379999995
            },
            {
              "period": "052023",
              "value": 42963843.46
            },
            {
              "period": "052023",
              "value": 42870796.49999999
            },
            {
              "period": "062023",
              "value": 42616344.56
            },
            {
              "period": "062023",
              "value": 18208589.009999998
            },
            {
              "period": "072023",
              "value": 48749751.510000005
            },
            {
              "period": "072023",
              "value": 37302193.89
            },
            {
              "period": "082023",
              "value": 37024576.96
            },
            {
              "period": "082023",
              "value": 47115537.27
            }
          ]
        },
        "stateWiseSales": {
          "092023-082024": [
            {
              "stateName": "Punjab",
              "value": 81561826.4,
              "stateCode": "03"
            },
            {
              "stateName": "Haryana",
              "value": 56757233,
              "stateCode": "06"
            },
            {
              "stateName": "Chandigarh",
              "value": 22520190,
              "stateCode": "04"
            },
            {
              "stateName": "Maharashtra",
              "value": 9605985,
              "stateCode": "27"
            },
            {
              "stateName": "Delhi",
              "value": 2972619,
              "stateCode": "07"
            }
          ],
          "092022-082023": [
            {
              "stateName": "Punjab",
              "value": 50425574.35999999,
              "stateCode": "03"
            },
            {
              "stateName": "Haryana",
              "value": 32864641.76,
              "stateCode": "06"
            },
            {
              "stateName": "Delhi",
              "value": 32454586.01,
              "stateCode": "07"
            },
            {
              "stateName": "Maharashtra",
              "value": 19845095,
              "stateCode": "27"
            },
            {
              "stateName": "Uttar Pradesh",
              "value": 722663,
              "stateCode": "09"
            },
            {
              "stateName": "Gujarat",
              "value": 94160,
              "stateCode": "24"
            },
            {
              "stateName": "Bihar",
              "value": 37233.01,
              "stateCode": "10"
            },
            {
              "stateName": "Dadra and Nagar Haveli",
              "value": 31658,
              "stateCode": "26"
            }
          ]
        },
        "top10Customers": [
          {
            "name": "YERVIK GLOBAL PRIVATE LIMITED",
            "value": 48498429.5
          },
          {
            "name": "YERVIK GLOBAL SOURCING INC",
            "value": 45298100
          },
          {
            "name": "JAAP ENTERPRISES",
            "value": 22520190
          },
          {
            "name": "T.C.TERRYTEX LIMITED",
            "value": 11082923
          },
          {
            "name": "HIM TOWELS STORE",
            "value": 10019890.9
          },
          {
            "name": "BOX AND BAG RETAIL PRIVATE LIMITED",
            "value": 8674471
          },
          {
            "name": "FEM EXPORTS",
            "value": 7878840
          },
          {
            "name": "M.D.DECOR",
            "value": 2687643
          },
          {
            "name": "HEINSTON PRODUCTS",
            "value": 2033983
          },
          {
            "name": "VASU AGENCIES",
            "value": 1741244
          }
        ],
        "top10Suppliers": [
          {
            "name": "KK SPINNERS PVT. LTD.",
            "value": 106798076
          },
          {
            "name": "YERVIK GLOBAL SOURCING INC",
            "value": 91783737.47000001
          },
          {
            "name": "YERVIK GLOBAL PRIVATE LIMITED",
            "value": 61175397.379999995
          },
          {
            "name": "ANANT KRIPA INDUSTRIES",
            "value": 50937732.370000005
          },
          {
            "name": "A.N. YARNS PVT LTD",
            "value": 33349699.35
          },
          {
            "name": "AGGARWAL FIBRES PRIVATE LIMITED",
            "value": 16181496.77
          },
          {
            "name": "ECO CHEM CORPORATION",
            "value": 9998692
          },
          {
            "name": "CREATIVE TEXTILES",
            "value": 6907817.34
          },
          {
            "name": "WSL AUTOMOBILES PRIVATE LIMITED",
            "value": 6750000
          },
          {
            "name": "T.C.TERRYTEX LIMITED",
            "value": 5268788.32
          }
        ]
      },
      "entityProfile": {
        "pan": "03AABFH7001B1ZD",
        "vinatge": "",
        "constitution": "Partnership",
        "legalName": "YERVIK HINDUSTAN EXPORTS INC.",
        "gsts": [
          {
            "gstin": "03AABFH7001B1ZD",
            "status": "Active",
            "state": "PUNJAB",
            "businessActivity": "Export, Supplier of Services, Recipient of Goods or Services, Warehouse / Depot, Office / Sale Office, Retail Business, Factory / Manufacturing, Import, Wholesale Business",
            "authrosiedRepresntative": "",
            "address": "VILLAGE KURANWALA, BARWALA ROAD, DERABASSI, SAS Nagar, Punjab, pin: 140507"
          }
        ]
      },
      "annualSummary": [
        {
          "annualSummary": "092023-082024",
          "grossTurnover": 632413660.46,
          "netTurnover": 569302689.6999999,
          "totalInvoice": 306,
          "grossPurchase": 488012087.56,
          "netPurchase": 446484801.53,
          "itcAvailable": 41527286.03000004,
          "taxLiability": 37560183.01,
          "grossPurchasePercentage": 77.1665949159026,
          "netPurchasePercentage": 78.42661023879579
        },
        {
          "annualSummary": "092022-082023",
          "grossTurnover": 603706333.82,
          "netTurnover": 541933254.0899999,
          "totalInvoice": 293,
          "grossPurchase": 510439734.30999994,
          "netPurchase": 469894018.8299999,
          "itcAvailable": 40545715.479999974,
          "taxLiability": 28841367.009999998,
          "grossPurchasePercentage": 84.55099867515912,
          "netPurchasePercentage": 86.70699118086665
        }
      ],
      "alerts": [
        {
          "alert": "Address  Quality  Poor_",
          "dateOfLastUpdation": null as any,
          "description": "",
          "severity": "low",
          "status": false
        },
        {
          "alert": "Gst  Cancelled",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Gst  Inactive",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Gst  Provisional",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Gst  Under  Cancellation",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Gst  Transaction  Default",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Gst  Transaction  Delay",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": true
        },
        {
          "alert": "Email  Invalid_",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Email  Disposable_",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Domain  Invalid_",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Iec  Cancelled",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Iec  Suspended",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Iec  Black  Listed",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Iec  In  Denied  Entity  List",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Tan  Inactive",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Tan  Transaction  Default",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Tan  Transaction  Delay",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Epf  Closed",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Epf  Under  Liquidation",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Epf  Transaction  Default",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": true
        },
        {
          "alert": "Epf  Transaction  Delay",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": true
        },
        {
          "alert": "Bse  Penal  Suspended",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bse  Disciplinary  Action",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bse  Member  Inactive",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "low",
          "status": false
        },
        {
          "alert": "Bse  Defaulter",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bse  Expelled",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Nse  Defaulter",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Nse  Expelled",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Nse  Suspended",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Credit  Rating  Downgraded",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Credit  Rating  Suspended",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Credit  Rating  Withdrawn",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "low",
          "status": false
        },
        {
          "alert": "Rated  Entity  Non  Cooperative",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Credit  Rating  Outlook  Negative",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Credit  Watch  With  Developing  Implication",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "low",
          "status": false
        },
        {
          "alert": "Credit  Watch  With  Negative  Implication",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Pan  Inactive",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "medium",
          "status": false
        },
        {
          "alert": "Iec  Dgft  Penalty",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "low",
          "status": null
        },
        {
          "alert": "Sebi  Debarred",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "severe",
          "status": false
        },
        {
          "alert": "Hawala",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "severe",
          "status": false
        },
        {
          "alert": "Offshore  Leak",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "severe",
          "status": false
        },
        {
          "alert": "Gst  Fraud",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bank  Auction",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Sfio  Proclaimed  Offender",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Sfio  Convicted  Director",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bank  Defaulter  Suit  Filed",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Wilful  Bank  Defaulter",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "severe",
          "status": false
        },
        {
          "alert": "Sick  Unit",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        },
        {
          "alert": "Bank  Defaulter  Non  Suit  Filed",
          "dateOfLastUpdation": null,
          "description": "",
          "severity": "high",
          "status": false
        }
      ],
      "compliance": [
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "092023",
          "dateOfFiling": "21-10-2023",
          "dueDate": "2023-10-20",
          "delayDays": 1
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "102023",
          "dateOfFiling": "21-11-2023",
          "dueDate": "2023-11-20",
          "delayDays": 1
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "112023",
          "dateOfFiling": "18-12-2023",
          "dueDate": "2023-12-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "122023",
          "dateOfFiling": "19-01-2024",
          "dueDate": "2024-01-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "012024",
          "dateOfFiling": "19-02-2024",
          "dueDate": "2024-02-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "022024",
          "dateOfFiling": "21-03-2024",
          "dueDate": "2024-03-20",
          "delayDays": 1
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "032024",
          "dateOfFiling": "16-04-2024",
          "dueDate": "2024-04-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "042024",
          "dateOfFiling": "14-05-2024",
          "dueDate": "2024-05-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "052024",
          "dateOfFiling": "18-06-2024",
          "dueDate": "2024-06-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "062024",
          "dateOfFiling": "16-07-2024",
          "dueDate": "2024-07-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "072024",
          "dateOfFiling": "20-08-2024",
          "dueDate": "2024-08-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "082024",
          "dateOfFiling": "19-09-2024",
          "dueDate": "2024-09-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "092022",
          "dateOfFiling": "22-10-2022",
          "dueDate": "2022-10-20",
          "delayDays": 2
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "102022",
          "dateOfFiling": "20-11-2022",
          "dueDate": "2022-11-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "112022",
          "dateOfFiling": "20-12-2022",
          "dueDate": "2022-12-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "122022",
          "dateOfFiling": "20-01-2023",
          "dueDate": "2023-01-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "012023",
          "dateOfFiling": "20-02-2023",
          "dueDate": "2023-02-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "022023",
          "dateOfFiling": "20-03-2023",
          "dueDate": "2023-03-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "032023",
          "dateOfFiling": "24-04-2023",
          "dueDate": "2023-04-20",
          "delayDays": 4
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "042023",
          "dateOfFiling": "23-05-2023",
          "dueDate": "2023-05-20",
          "delayDays": 3
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "052023",
          "dateOfFiling": "29-06-2023",
          "dueDate": "2023-06-20",
          "delayDays": 9
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "062023",
          "dateOfFiling": "20-07-2023",
          "dueDate": "2023-07-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "072023",
          "dateOfFiling": "20-08-2023",
          "dueDate": "2023-08-20",
          "delayDays": 0
        },
        {
          "gstin": "03AABFH7001B1ZD",
          "month": "082023",
          "dateOfFiling": "21-09-2023",
          "dueDate": "2023-09-20",
          "delayDays": 1
        }
      ],
      "cyclicTransaction": [
        {
          "period": "092023-082024",
          "name": "T.C.TERRYTEX LIMITED",
          "pan": "AACCT3244L",
          "totalSalesVal": 11082923,
          "totalPurchasesVal": 5268788.32
        },
        {
          "period": "092023-082024",
          "name": "YERVIK GLOBAL PRIVATE LIMITED",
          "pan": "AABCY2885K",
          "totalSalesVal": 48498429.5,
          "totalPurchasesVal": 61175397.379999995
        },
        {
          "period": "092023-082024",
          "name": "MAHALUXMI PROCESSING HOUSE PVT LTD",
          "pan": "AAFCM7812A",
          "totalSalesVal": 937046,
          "totalPurchasesVal": 1673156.9600000002
        },
        {
          "period": "092023-082024",
          "name": "YERVIK GLOBAL SOURCING INC",
          "pan": "BPYPS8197A",
          "totalSalesVal": 45298100,
          "totalPurchasesVal": 91783737.47000001
        },
        {
          "period": "092022-082023",
          "name": "YERVIK GLOBAL PRIVATE LIMITED",
          "pan": "AABCY2885K",
          "totalSalesVal": 39514621.379999995,
          "totalPurchasesVal": 108804270.19999999
        },
        {
          "period": "092022-082023",
          "name": "SHREE BALAJI INDUSTRIES",
          "pan": "BZNPD8905Q",
          "totalSalesVal": 84581,
          "totalPurchasesVal": 64900
        },
        {
          "period": "092022-082023",
          "name": "T.C.TERRYTEX LIMITED",
          "pan": "AACCT3244L",
          "totalSalesVal": 414566,
          "totalPurchasesVal": 1086073.06
        }
      ],
      "relatedPartySales": [] as any[],
      "relatedPartyPurchases": [] as any[],
      "gstr1Vs3B": [
        {
          "period": "092023",
          "gst1": {
            "invoiceValue": 56119232,
            "taxableValue": 53443693.18
          },
          "gst3b": {
            "invoiceValue": 56118954.19,
            "taxableValue": 53443693.18
          }
        },
        {
          "period": "102023",
          "gst1": {
            "invoiceValue": 38664689,
            "taxableValue": 36823511.75
          },
          "gst3b": {
            "invoiceValue": 38664677.54,
            "taxableValue": 36823511.75
          }
        },
        {
          "period": "112023",
          "gst1": {
            "invoiceValue": 36033022,
            "taxableValue": 34280249.03
          },
          "gst3b": {
            "invoiceValue": 35994261.5,
            "taxableValue": 34280249.03
          }
        },
        {
          "period": "122023",
          "gst1": {
            "invoiceValue": 28739773,
            "taxableValue": 27371211.57
          },
          "gst3b": {
            "invoiceValue": 28739772.21,
            "taxableValue": 27371211.57
          }
        },
        {
          "period": "012024",
          "gst1": {
            "invoiceValue": 57757270,
            "taxableValue": 37190885.57
          },
          "gst3b": {
            "invoiceValue": 57757269.59,
            "taxableValue": 55482722.57
          }
        },
        {
          "period": "022024",
          "gst1": {
            "invoiceValue": 42048323,
            "taxableValue": 37913685.870000005
          },
          "gst3b": {
            "invoiceValue": 42048322.2,
            "taxableValue": 40152637.870000005
          }
        },
        {
          "period": "032024",
          "gst1": {
            "invoiceValue": 82551668.4,
            "taxableValue": 75455816.72999999
          },
          "gst3b": {
            "invoiceValue": 82536996.02,
            "taxableValue": 77508886.72999999
          }
        },
        {
          "period": "042024",
          "gst1": {
            "invoiceValue": 50718106,
            "taxableValue": 44785912.59
          },
          "gst3b": {
            "invoiceValue": 50718104.49,
            "taxableValue": 47798143.589999996
          }
        },
        {
          "period": "052024",
          "gst1": {
            "invoiceValue": 43729321,
            "taxableValue": 40191298.39
          },
          "gst3b": {
            "invoiceValue": 43729320.59,
            "taxableValue": 40191298.39
          }
        },
        {
          "period": "062024",
          "gst1": {
            "invoiceValue": 89554675,
            "taxableValue": 82636962.73
          },
          "gst3b": {
            "invoiceValue": 89554677.42,
            "taxableValue": 82636962.73
          }
        },
        {
          "period": "072024",
          "gst1": {
            "invoiceValue": 48623207,
            "taxableValue": 45851751.49
          },
          "gst3b": {
            "invoiceValue": 48623204.58,
            "taxableValue": 45851751.49
          }
        },
        {
          "period": "082024",
          "gst1": {
            "invoiceValue": 57874374.06,
            "taxableValue": 53357710.8
          },
          "gst3b": {
            "invoiceValue": 57874379.18,
            "taxableValue": 53357710.8
          }
        },
        {
          "period": "092022",
          "gst1": {
            "invoiceValue": 43274675.89,
            "taxableValue": 41204737.33
          },
          "gst3b": {
            "invoiceValue": 43273866,
            "taxableValue": 41204737
          }
        },
        {
          "period": "102022",
          "gst1": {
            "invoiceValue": 43353910.239999995,
            "taxableValue": 41289449.86
          },
          "gst3b": {
            "invoiceValue": 43149161.25,
            "taxableValue": 41094449.72
          }
        },
        {
          "period": "112022",
          "gst1": {
            "invoiceValue": 51956164.6,
            "taxableValue": 49451199.029999994
          },
          "gst3b": {
            "invoiceValue": 52128477.919999994,
            "taxableValue": 49646159.03
          }
        },
        {
          "period": "122022",
          "gst1": {
            "invoiceValue": 43237947.849999994,
            "taxableValue": 41023545.62
          },
          "gst3b": {
            "invoiceValue": 43237947.86,
            "taxableValue": 41044795.62
          }
        },
        {
          "period": "012023",
          "gst1": {
            "invoiceValue": 74308816.9,
            "taxableValue": 49372844.4
          },
          "gst3b": {
            "invoiceValue": 74308815.80000001,
            "taxableValue": 71693597.4
          }
        },
        {
          "period": "022023",
          "gst1": {
            "invoiceValue": 39360597.13,
            "taxableValue": 37250072.43
          },
          "gst3b": {
            "invoiceValue": 39346798.37,
            "taxableValue": 37290072.43
          }
        },
        {
          "period": "032023",
          "gst1": {
            "invoiceValue": 70898566.24,
            "taxableValue": 67157997.16
          },
          "gst3b": {
            "invoiceValue": 71175033.83999999,
            "taxableValue": 67421300.32
          }
        },
        {
          "period": "042023",
          "gst1": {
            "invoiceValue": 48154082.53,
            "taxableValue": 43828891.77
          },
          "gst3b": {
            "invoiceValue": 48154081.88,
            "taxableValue": 45784333.77
          }
        },
        {
          "period": "052023",
          "gst1": {
            "invoiceValue": 45163384.37,
            "taxableValue": 42963843.46
          },
          "gst3b": {
            "invoiceValue": 45163431.19,
            "taxableValue": 42963843.46
          }
        },
        {
          "period": "062023",
          "gst1": {
            "invoiceValue": 53731509,
            "taxableValue": 42616344.56
          },
          "gst3b": {
            "invoiceValue": 53717166.9,
            "taxableValue": 51511446.56
          }
        },
        {
          "period": "072023",
          "gst1": {
            "invoiceValue": 51349874,
            "taxableValue": 48749751.510000005
          },
          "gst3b": {
            "invoiceValue": 51349874.24,
            "taxableValue": 48749751.510000005
          }
        },
        {
          "period": "082023",
          "gst1": {
            "invoiceValue": 38916805.07,
            "taxableValue": 37024576.96
          },
          "gst3b": {
            "invoiceValue": 38916805.809999995,
            "taxableValue": 37065576.96
          }
        }
      ],
      "statewiseSales": [
        {
          "stateCode": "03",
          "state": "Punjab",
          "invoices": 84,
          "taxableValue": 77669924.97,
          "tax": 81561826.4,
          "invoiceValue": 81561826.4
        },
        {
          "stateCode": "06",
          "state": "Haryana",
          "invoices": 57,
          "taxableValue": 54054507.660000004,
          "tax": 56757233,
          "invoiceValue": 56757233
        },
        {
          "stateCode": "04",
          "state": "Chandigarh",
          "invoices": 5,
          "taxableValue": 21447800,
          "tax": 22520190,
          "invoiceValue": 22520190
        },
        {
          "stateCode": "27",
          "state": "Maharashtra",
          "invoices": 5,
          "taxableValue": 9108888.28,
          "tax": 9605985,
          "invoiceValue": 9605985
        },
        {
          "stateCode": "07",
          "state": "Delhi",
          "invoices": 3,
          "taxableValue": 2831065.2,
          "tax": 2972619,
          "invoiceValue": 2972619
        },
        {
          "stateCode": "03",
          "state": "Punjab",
          "invoices": 61,
          "taxableValue": 47670685.11,
          "tax": 50425574.35999999,
          "invoiceValue": 50425574.35999999
        },
        {
          "stateCode": "06",
          "state": "Haryana",
          "invoices": 37,
          "taxableValue": 31299655.730000004,
          "tax": 32864641.76,
          "invoiceValue": 32864641.76
        },
        {
          "stateCode": "07",
          "state": "Delhi",
          "invoices": 34,
          "taxableValue": 30909129.42,
          "tax": 32454586.01,
          "invoiceValue": 32454586.01
        },
        {
          "stateCode": "27",
          "state": "Maharashtra",
          "invoices": 18,
          "taxableValue": 18514814.54,
          "tax": 19845095,
          "invoiceValue": 19845095
        },
        {
          "stateCode": "09",
          "state": "Uttar Pradesh",
          "invoices": 3,
          "taxableValue": 679375.5,
          "tax": 722663,
          "invoiceValue": 722663
        },
        {
          "stateCode": "24",
          "state": "Gujarat",
          "invoices": 1,
          "taxableValue": 89676,
          "tax": 94160,
          "invoiceValue": 94160
        },
        {
          "stateCode": "10",
          "state": "Bihar",
          "invoices": 1,
          "taxableValue": 35460,
          "tax": 37233.01,
          "invoiceValue": 37233.01
        },
        {
          "stateCode": "26",
          "state": "Dadra and Nagar Haveli",
          "invoices": 1,
          "taxableValue": 30150,
          "tax": 31658,
          "invoiceValue": 31658
        }
      ],
      "topCustomers": [
        {
          "period": "092023-082024",
          "pan": "AABCY2885K",
          "name": "YERVIK GLOBAL PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 46188980,
          "invoiceValue": 48498429.5,
          "totalInvoice": 12,
          "share": 0.27966226400078364
        },
        {
          "period": "092023-082024",
          "pan": "BPYPS8197A",
          "name": "YERVIK GLOBAL SOURCING INC",
          "alerts": 53,
          "taxableValue": 43141048,
          "invoiceValue": 45298100,
          "totalInvoice": 12,
          "share": 0.2612078232540272
        },
        {
          "period": "092023-082024",
          "pan": "ADDPG9300K",
          "name": "JAAP ENTERPRISES",
          "alerts": 53,
          "taxableValue": 21447800,
          "invoiceValue": 22520190,
          "totalInvoice": 5,
          "share": 0.12986085087822913
        },
        {
          "period": "092023-082024",
          "pan": "AACCT3244L",
          "name": "T.C.TERRYTEX LIMITED",
          "alerts": 94,
          "taxableValue": 10555166.389999999,
          "invoiceValue": 11082923,
          "totalInvoice": 27,
          "share": 0.06390877745693511
        },
        {
          "period": "092023-082024",
          "pan": "ADFPY9881R",
          "name": "HIM TOWELS STORE",
          "alerts": 52,
          "taxableValue": 9542753.64,
          "invoiceValue": 10019890.9,
          "totalInvoice": 20,
          "share": 0.05777888898721659
        },
        {
          "period": "092023-082024",
          "pan": "AALCB0115F",
          "name": "BOX AND BAG RETAIL PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 8256591.08,
          "invoiceValue": 8674471,
          "totalInvoice": 13,
          "share": 0.05002063415000153
        },
        {
          "period": "092023-082024",
          "pan": "ALNPB2402K",
          "name": "FEM EXPORTS",
          "alerts": 53,
          "taxableValue": 7463988.279999999,
          "invoiceValue": 7878840,
          "totalInvoice": 4,
          "share": 0.04543269245656572
        },
        {
          "period": "092023-082024",
          "pan": "AILPG2589D",
          "name": "M.D.DECOR",
          "alerts": 52,
          "taxableValue": 2559660.6,
          "invoiceValue": 2687643,
          "totalInvoice": 5,
          "share": 0.015498075586259102
        },
        {
          "period": "092023-082024",
          "pan": "BCHPK4697K",
          "name": "HEINSTON PRODUCTS",
          "alerts": 52,
          "taxableValue": 1937126.56,
          "invoiceValue": 2033983,
          "totalInvoice": 5,
          "share": 0.011728798160754998
        },
        {
          "period": "092023-082024",
          "pan": "ABCPB3951G",
          "name": "VASU AGENCIES",
          "alerts": 52,
          "taxableValue": 1658327.52,
          "invoiceValue": 1741244,
          "totalInvoice": 2,
          "share": 0.010040742437191302
        },
        {
          "period": "092022-082023",
          "pan": "AABCY2885K",
          "name": "YERVIK GLOBAL PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 37310042.8,
          "invoiceValue": 39514621.379999995,
          "totalInvoice": 16,
          "share": 0.28988960654665785
        },
        {
          "period": "092022-082023",
          "pan": "BPYPS8197A",
          "name": "YERVIK GLOBAL SOURCING INC",
          "alerts": 53,
          "taxableValue": 25170762.439999998,
          "invoiceValue": 26429301,
          "totalInvoice": 8,
          "share": 0.19389227077526894
        },
        {
          "period": "092022-082023",
          "pan": "AAQFS7560M",
          "name": "SAHNI AGENCIES",
          "alerts": 48,
          "taxableValue": 20753712.88,
          "invoiceValue": 21791398.58,
          "totalInvoice": 21,
          "share": 0.1598674045161153
        },
        {
          "period": "092022-082023",
          "pan": "ALNPB2402K",
          "name": "FEM EXPORTS",
          "alerts": 53,
          "taxableValue": 13064342.83,
          "invoiceValue": 14122099,
          "totalInvoice": 11,
          "share": 0.10360341513470805
        },
        {
          "period": "092022-082023",
          "pan": "AALCB0115F",
          "name": "BOX AND BAG RETAIL PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 6430958.8,
          "invoiceValue": 6752507,
          "totalInvoice": 5,
          "share": 0.0495381590173686
        },
        {
          "period": "092022-082023",
          "pan": "AASPD7164N",
          "name": "HOUSE 2 HOME INC.",
          "alerts": 53,
          "taxableValue": 6046148.9399999995,
          "invoiceValue": 6348457.43,
          "totalInvoice": 9,
          "share": 0.04657394560010603
        },
        {
          "period": "092022-082023",
          "pan": "AAJCA7308P",
          "name": "ANAPPADIKKAL TRADING COMPANY PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 4563516,
          "invoiceValue": 4791692,
          "totalInvoice": 4,
          "share": 0.03515310687693519
        },
        {
          "period": "092022-082023",
          "pan": "AAKPJ4268P",
          "name": "AVINASH & SONS",
          "alerts": 53,
          "taxableValue": 3860103.6,
          "invoiceValue": 4053108,
          "totalInvoice": 3,
          "share": 0.02973466130706252
        },
        {
          "period": "092022-082023",
          "pan": "ADFPY9881R",
          "name": "HIM TOWELS STORE",
          "alerts": 52,
          "taxableValue": 3207147.46,
          "invoiceValue": 3367504.99,
          "totalInvoice": 32,
          "share": 0.024704898149147017
        },
        {
          "period": "092022-082023",
          "pan": "AWRPS4294D",
          "name": "CREATIVE TEXTILES",
          "alerts": 53,
          "taxableValue": 2665777.76,
          "invoiceValue": 2799066,
          "totalInvoice": 5,
          "share": 0.02053468091304605
        }
      ],
      "topSuppliers": [
        {
          "period": "092023-082024",
          "pan": "AAACK4924E",
          "name": "KK SPINNERS PVT. LTD.",
          "alerts": 94,
          "taxableValue": 101530689,
          "invoiceValue": 106798076,
          "totalInvoice": 119,
          "share": 0.21884309573965105
        },
        {
          "period": "092023-082024",
          "pan": "BPYPS8197A",
          "name": "YERVIK GLOBAL SOURCING INC",
          "alerts": 53,
          "taxableValue": 84365471.84,
          "invoiceValue": 91783737.47000001,
          "totalInvoice": 30,
          "share": 0.18807677065727485
        },
        {
          "period": "092023-082024",
          "pan": "AABCY2885K",
          "name": "YERVIK GLOBAL PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 58181677.2,
          "invoiceValue": 61175397.379999995,
          "totalInvoice": 26,
          "share": 0.12535631583608806
        },
        {
          "period": "092023-082024",
          "pan": "AIGPK5383G",
          "name": "ANANT KRIPA INDUSTRIES",
          "alerts": 52,
          "taxableValue": 43075808.65,
          "invoiceValue": 50937732.370000005,
          "totalInvoice": 21,
          "share": 0.10437801371823059
        },
        {
          "period": "092023-082024",
          "pan": "AAFCA5405K",
          "name": "A.N. YARNS PVT LTD",
          "alerts": 93,
          "taxableValue": 31457634.28,
          "invoiceValue": 33349699.35,
          "totalInvoice": 42,
          "share": 0.068337855147696
        },
        {
          "period": "092023-082024",
          "pan": "AAOCA2978C",
          "name": "AGGARWAL FIBRES PRIVATE LIMITED",
          "alerts": 94,
          "taxableValue": 15404250.440000001,
          "invoiceValue": 16181496.77,
          "totalInvoice": 18,
          "share": 0.033157983546894275
        },
        {
          "period": "092023-082024",
          "pan": "ANQPS4354N",
          "name": "ECO CHEM CORPORATION",
          "alerts": 52,
          "taxableValue": 8475540,
          "invoiceValue": 9998692,
          "totalInvoice": 77,
          "share": 0.020488615456211806
        },
        {
          "period": "092023-082024",
          "pan": "AWRPS4294D",
          "name": "CREATIVE TEXTILES",
          "alerts": 53,
          "taxableValue": 6578873.65,
          "invoiceValue": 6907817.34,
          "totalInvoice": 15,
          "share": 0.014155012787773832
        },
        {
          "period": "092023-082024",
          "pan": "AABCW3717G",
          "name": "WSL AUTOMOBILES PRIVATE LIMITED",
          "alerts": 93,
          "taxableValue": 4500000,
          "invoiceValue": 6750000,
          "totalInvoice": 1,
          "share": 0.013831624609441883
        },
        {
          "period": "092023-082024",
          "pan": "AACCT3244L",
          "name": "T.C.TERRYTEX LIMITED",
          "alerts": 94,
          "taxableValue": 5016346,
          "invoiceValue": 5268788.32,
          "totalInvoice": 7,
          "share": 0.010796429953903994
        },
        {
          "period": "092022-082023",
          "pan": "AABCY2885K",
          "name": "Yervik Global Private Limited",
          "alerts": 94,
          "taxableValue": 103556707.57999997,
          "invoiceValue": 108804270.19999999,
          "totalInvoice": 73,
          "share": 0.21315791637396522
        },
        {
          "period": "092022-082023",
          "pan": "AIGPK5383G",
          "name": "ANANT KRIPA INDUSTRIES",
          "alerts": 52,
          "taxableValue": 47773092,
          "invoiceValue": 56549741.260000005,
          "totalInvoice": 27,
          "share": 0.11078632296610408
        },
        {
          "period": "092022-082023",
          "pan": "AAECA7351B",
          "name": "AMAR MARKETING & SERVICES (P) LTD.,",
          "alerts": 94,
          "taxableValue": 45575898.81,
          "invoiceValue": 47900311.79,
          "totalInvoice": 38,
          "share": 0.09384126777424662
        },
        {
          "period": "092022-082023",
          "pan": "AAACK4924E",
          "name": "KK SPINNERS PVT. LTD.",
          "alerts": 94,
          "taxableValue": 40090748,
          "invoiceValue": 42128376.8,
          "totalInvoice": 52,
          "share": 0.08253349801803364
        },
        {
          "period": "092022-082023",
          "pan": "ADCFS8262R",
          "name": "SUNRISE SPINNERS",
          "alerts": 49,
          "taxableValue": 26773587.04,
          "invoiceValue": 28123207.02,
          "totalInvoice": 16,
          "share": 0.05509603804260315
        },
        {
          "period": "092022-082023",
          "pan": "AWRPS4294D",
          "name": "CREATIVE TEXTILES",
          "alerts": 53,
          "taxableValue": 21991507.900000002,
          "invoiceValue": 23106219.96,
          "totalInvoice": 53,
          "share": 0.04526728310293953
        },
        {
          "period": "092022-082023",
          "pan": "ADHFS5992D",
          "name": "SAI SHARADHA INTERNATIONAL INC",
          "alerts": 49,
          "taxableValue": 17481758,
          "invoiceValue": 18355845.2,
          "totalInvoice": 21,
          "share": 0.03596084702303395
        },
        {
          "period": "092022-082023",
          "pan": "AAFCH9400D",
          "name": "H R YARNS PRIVATE LIMITED",
          "alerts": 93,
          "taxableValue": 16220053.2,
          "invoiceValue": 17047578.740000002,
          "totalInvoice": 15,
          "share": 0.03339782856647026
        },
        {
          "period": "092022-082023",
          "pan": "AAFCA5405K",
          "name": "A.N. YARNS PVT LTD",
          "alerts": 93,
          "taxableValue": 10369876.98,
          "invoiceValue": 10888370.83,
          "totalInvoice": 9,
          "share": 0.02133135431691782
        },
        {
          "period": "092022-082023",
          "pan": "AAPCM2798F",
          "name": "MITAKSHI TEXTILES PRIVATE LIMITED",
          "alerts": 93,
          "taxableValue": 8594218.7,
          "invoiceValue": 9023929.64,
          "totalInvoice": 7,
          "share": 0.017678736652816284
        }
      ],
      "excelFile": "https://nuphi-productions.s3.amazonaws.com/credlix_gst_reports/1726742930669_03AABFH7001B1ZD.xlsx",
      "pdfFile": "https://nuphi-productions.s3.amazonaws.com/credlix_gst_reports/1726742930669_03AABFH7001B1ZD.pdf"
    }
  }
}
