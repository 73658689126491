<section class="finalCamWraper">

    <section class="finalCamBlock">

        <h1>
            <span>GST Details</span>
            <button class="saveCta mr-15" (click)="onSave()">
                <i class="ri-save-2-fill mr-5"></i>Save
            </button>
            <button class="saveCta mr-15" (click)="onSave()">
                <i class="ri-save-2-fill mr-5"></i>Submit Summary of Borrower
            </button>
        </h1>
        <section class="borrowerTxtAreaWrap">
            <span class="lbl">GST Summary</span>
            <textarea [(ngModel)]="gstSummary"></textarea>
        </section>
    </section>

    <section class="finalCamBlock">
        <h1>GST Turnover from Apr - 23 to Aug - 24 in INR Crores</h1>
        <highcharts-chart [Highcharts]="Highcharts" [options]="barChartOptions"
            style="width: 100%; height: 400px; display: block;">
        </highcharts-chart>
    </section>

</section>